import React, { Component } from 'react';
import { Button, Dropdown, Header, Input, Modal } from 'semantic-ui-react';

const appliedCutoffValues = [
    { key: "EVERY_CUTOFF", text: "Every cutoff", value: "EVERY_CUTOFF" },
    { key: "LAST_CUTOFF", text: "Last cutoff", value: "LAST_CUTOFF" },
    { key: "FIRST_CUTOFF", text: "First cutoff", value: "FIRST_CUTOFF" },
    { key: "SECOND_CUTOFF", text: "Second cutoff", value: "SECOND_CUTOFF" },
    { key: "THIRD_CUTOFF", text: "Third cutoff", value: "THIRD_CUTOFF" },
    { key: "FOURTH_CUTOFF", text: "Fourth cutoff", value: "FOURTH_CUTOFF" },
    { key: "FIFTH_CUTOFF", text: "Fifth cutoff", value: "FIFTH_CUTOFF" }
]

const salaryRateValues = [
    { key: "MONTHLY", text: "Monthly", value: "MONTHLY" },
    { key: "DAILY", text: "Daily", value: "DAILY" },
    { key: "HOURLY", text: "Hourly", value: "HOURLY" },
]

export default class AllowanceProfileFormModal extends Component {
    state = {
        id: null,
        allowanceType: null,
        appliedCutoff: appliedCutoffValues[0],
        rateType: salaryRateValues[0],
        amount: 0.00
    }

    constructor(props, context) {
        super(props, context)
        this.state.allowanceType = this.props.allowanceTypes?  this.props.allowanceTypes.map(x => this.mapAllowanceToSelection(x))[0]: null
    }

    handleDropdownChange = (event, data) => {
        this.setState({ [data.name]: data.options.find(item => item.value === data.value) });
    }

    handleAmountChange = (event, data) => {
        this.setState({ [data.name]: data.value });
    }

    mapAllowanceToSelection = (item) => {
        return {
            key: item._id,
            text: item.Description,
            value: item._id
        }
    }

    render() {
        const allowanceTypeOptions = this.props.allowanceTypes.map(x => this.mapAllowanceToSelection(x))
        const appliedCutoffs = appliedCutoffValues.filter(x => this.state.appliedCutoff.value !== x.value)
        const salaryRates = salaryRateValues.filter(x => this.state.rateType.value !== x.value)

        return (<Modal size="mini" open={this.props.open} onClose={this.props.onClose}>
            <Header content="Select Allowance Type" />
            <Modal.Content>
                <div class="ui grid">
                    <div class="left floated left aligned sixteen wide column">
                        <label>Select</label>
                        <Dropdown fluid selection options={allowanceTypeOptions}
                            placeholder="Allowance Type"
                            name="allowanceType"
                            value={this.state.allowanceType?.value}
                            onChange={this.handleDropdownChange.bind(this)} />
                    </div>
                </div>
                <div class="ui grid">
                    <div class="left floated left aligned sixteen wide column">
                        <label>Applied Cutoff</label>
                        <Dropdown fluid selection options={appliedCutoffValues}
                            placeholder="Applied Cutoff"
                            name="appliedCutoff"
                            value={this.state.appliedCutoff.value}
                            onChange={this.handleDropdownChange.bind(this)} />
                    </div>
                </div>
                <div class="ui grid">
                    <div class="left floated left aligned sixteen wide column">
                        <label>Rate Type</label>
                        <Dropdown fluid selection options={salaryRateValues}
                            placeholder="Salary Rate"
                            name="rateType"
                            value={this.state.rateType.value}
                            onChange={this.handleDropdownChange.bind(this)} />
                    </div>
                    <div class="left floated left aligned sixteen wide column">
                        <label>Amount</label>
                        <Input fluid type="number"
                            name="amount"
                            value={this.state.amount}
                            onChange={this.handleAmountChange.bind(this)} />
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button basic icon="check" content="Yes" onClick={this.props.onConfirmAllowanceType} />
                <Button basic content="No" onClick={this.props.onCloseAllowanceType} />
            </Modal.Actions>
        </Modal>)
    }

}