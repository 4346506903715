import React, { Component } from 'react';
import { Button, Input, Table, Header, Modal, Loader, Menu, Message } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { ajaxPost } from '../../ajax';
import { empty_id } from '../../utils';
import './allowance_profile.css';
import AllowanceProfileFormModal from './AllowanceProfileFormModal';
import AllowanceProfilePropertyModal from './AllowanceProfilePropertyModal';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';

class AllowanceProfileForm extends Component {
    state = {
        ItemData: {
            _id: empty_id,
            Description: "",
            Code: "",
            AllowanceTypes: []
        },
        saving: false,
        dialog: false,
        dialogContent: null,
        dialogTitle: '',
        conflict: [],
        showConflict: false,
        showRequiredField: false,
        allowanceTypes: [],
        selectedAllowanceType: empty_id,
        selectedIndex: -1,
        addAllowanceTypeDialog: false,
        loadingAllowance: false,
        detailDialog: false,
        detailAllowanceId: empty_id,
        showInvalidAction: false,
    }
    constructor(props, context) {
        super(props, context);
        this.state.ItemData = props.selectedItem == null ? this.state.ItemData : props.selectedItem;
        this.loadAllowanceType()
        this.allowanceProfileModal = React.createRef();
    }

    loadAllowanceType = () => {
        this.setState({ loadingAllowance: true });
        ajaxPost({
            url: 'api/AllowanceType/search',
            data: {
                "queryString": '',
                "includeDeleted": false,
                "startIndex": 0,
                "itemCount": 9999
            },
            onSuccess: data => {
                this.setState({
                    loadingAllowance: false,
                    allowanceTypes: data.content
                });
            },
            onError: function (err) {
                alert(JSON.stringify(err));
            },
            finally: () => { this.setState({ saving: false }); }
        })
    }

    save = (callback, model) => {
        ajaxPost({
            url: 'api/AllowanceProfile/save',
            data: model,
            onSuccess: data => {
                callback(data);
            },
            onError: function (err) {
                alert(JSON.stringify(err));
            },
            finally: () => { this.setState({ saving: false }); }
        })
    }
    handleSave = (e, data) => {
        this.setState({ saving: true });
        var model = this.state.ItemData;
        var isSaving = true;
        if (model.Description === undefined || model.Description === null || model.Description.trim() === "") {
            this.setState({
                showRequiredField: true,
                saving: false, showConflict: true,
                conflict: ["Invalid description"]
            });
            isSaving = false;
        }
        if (model.Code === undefined || model.Code === null || model.Code.trim() === "") {
            this.setState({
                showRequiredField: true,
                saving: false, showConflict: true,
                conflict: ["Invalid code"]
            });
            isSaving = false;
        }

        if (isSaving) {

            this.save(data => {
                this.setState({ saving: false });
                this.setState({ showConflict: false });
                if (data.content != null) {
                    model = data.content;

                    this.setState({ ItemData: model });
                    this.dialogOnSuccess();
                }
                if (data.errors != null) {
                    if (data.errors.length > 0) {
                        this.setState({ showConflict: true });
                        this.setState({ conflict: data.errors });
                    }
                }

            }, model);
        }
    }
    handleChange = name => event => {
        var current = this.state.ItemData;
        current[name] = event.target.value;
        this.setState({ ItemData: current });
    };
    onCloseDialog = () => {
        this.setState({ dialog: false });
        this.props.onUpdateComplete(this.state.ItemData);
    }
    dialogOnSuccess = () => {
        this.setState({ dialogTitle: "Save" });
        this.setState({
            dialogContent: (
                <div>
                    <p>Allowance Profile has been updated successfully</p>
                </div>
            )
        });
        this.setState({ dialog: true });
    }

    onAddAllowanceType = () => {
        this.setState({
            addAllowanceTypeDialog: true,
            selectedAllowanceTypeId: empty_id,
            selectedIndex: -1,
        })
    }

    onEditAllowanceType = () => {
        if (this.state.selectedIndex < 0) {
            this.setState({showInvalidAction: true})
            return;
        }

        this.setState({ addAllowanceTypeDialog: true }, () => {
            const selectedAllowance = this.state.ItemData.AllowanceTypes[this.state.selectedIndex]
            this.allowanceProfileModal.current.state.id = selectedAllowance.Id
            this.allowanceProfileModal.current.state.amount = selectedAllowance.Amount
            this.allowanceProfileModal.current.state.allowanceType = { text: selectedAllowance.AllowanceType, value: selectedAllowance.AllowanceTypeId }
            this.allowanceProfileModal.current.state.appliedCutoff = { text: selectedAllowance.AppliedCutoff, value: selectedAllowance.AppliedCutoffValue }
            this.allowanceProfileModal.current.state.rateType = { text: selectedAllowance.RateType, value: selectedAllowance.RateTypeValue }
        })
    }

    onDeleteAllowanceType = () => {
        if (this.state.selectedIndex < 0) {
            this.setState({showInvalidAction: true})
            return;
        }
        const updatedAllowanceTypes = this.state.ItemData.AllowanceTypes.filter((id, index) => this.state.selectedIndex !== index)
        this.setState({
            selectedIndex: -1,
            ItemData: { ...this.state.ItemData, AllowanceTypes: updatedAllowanceTypes },
        })

    }
    onConfirmAllowanceType = () => {
        const allowance = this.allowanceProfileModal;
        const allowanceTypes = [...this.state.ItemData.AllowanceTypes];
        if (allowance.current.state.id === null) {
            allowanceTypes.push({
                Id: this.generateGuid(),
                AllowanceTypeId: allowance.current.state.allowanceType.value,
                AllowanceType: allowance.current.state.allowanceType.text,
                AppliedCutoff: allowance.current.state.appliedCutoff.text,
                AppliedCutoffValue: allowance.current.state.appliedCutoff.value,
                RateType: allowance.current.state.rateType.text,
                RateTypeValue: allowance.current.state.rateType.value,
                Amount: allowance.current.state.amount
            })
        }
        else {
            allowanceTypes[this.state.selectedIndex] = {
                Id: allowance.current.state.id,
                AllowanceTypeId: allowance.current.state.allowanceType.value,
                AllowanceType: allowance.current.state.allowanceType.text,
                AppliedCutoff: allowance.current.state.appliedCutoff.text,
                AppliedCutoffValue: allowance.current.state.appliedCutoff.value,
                RateType: allowance.current.state.rateType.text,
                RateTypeValue: allowance.current.state.rateType.value,
                Amount: allowance.current.state.amount
            }
        }
        this.setState({ ItemData: { ...this.state.ItemData, AllowanceTypes: allowanceTypes }, addAllowanceTypeDialog: false })

    }

    generateGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    onCloseAllowanceType = () => {
        this.setState({ addAllowanceTypeDialog: false });
    }

    mapSelectedTypes = (item, index) => {
        return <Table.Row style={this.state.selectedIndex === index ? { backgroundColor: '#E3F2FD' } : {}} id={item.Id} onClick={this.onTableRowClick}>
            <Table.Cell>{item.AllowanceType}</Table.Cell>
            <Table.Cell><Button onClick={() => this.onDetailClick(item.AllowanceTypeId)}>View details</Button></Table.Cell>
            <Table.Cell>{item.AppliedCutoff}</Table.Cell>
            <Table.Cell>{item.RateType}</Table.Cell>
            <Table.Cell>{item.Amount}</Table.Cell>
        </Table.Row>
    }

    onDetailClick = (id) => {
        this.setState({detailDialog: true, detailAllowanceId: id})
    }

    onDetailClose = () => {
        this.setState({detailDialog: false})
    }

    onTableRowClick = (tableRow) => {
        let selectedIndex = -1;
        this.state.ItemData.AllowanceTypes.forEach((item, index) => {
            if (item.Id === tableRow.currentTarget.id) {
                selectedIndex = index;
            }
        })
        this.setState({ selectedAllowanceType: tableRow.currentTarget.id, selectedIndex })
    }

    render() {
        return (
            <div>
                <div style={{ overflow: "hidden" }}>
                    <h2 style={{ color: "#606060", marginTop: '1%', float: "left" }}>Allowance Profile Details</h2>
                    <Menu size="mini" secondary stackable>
                        <Menu.Menu position="right" >
                            <Button.Group>
                                <Button content="Save" size="mini" icon="save"
                                    onClick={this.handleSave.bind(this)} style={{ margin: "0px 3px 0px 0px" }} />
                                <Button content="Cancel" size="mini" icon="cancel"
                                    onClick={this.props.onCloseForm.bind(this)} />
                            </Button.Group>
                        </Menu.Menu>
                    </Menu>
                </div>
                <Loader active={this.state.saving || this.state.loadingAllowance} />
                {this.state.showConflict &&
                    <Message negative>
                        <Message.Header>Conflict !</Message.Header>
                        <Message.Content>{this.state.conflict.map(n => {
                            return (<p>{n}</p>)
                        })}</Message.Content>
                    </Message>}
                <Table style={{ 'border': '0px !important' }}>
                    <Table.Row>
                        <Table.Cell colSpan='2' width='6'>
                            <label>Description</label>
                            <Input fluid placeholder='Allowance Name'
                                value={this.state.ItemData.Description}
                                maxLength="100"
                                onChange={this.handleChange("Description").bind(this)} />
                        </Table.Cell>
                        <Table.Cell width='1' textAlign='right' />
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colSpan='2' width='6'>
                            <label>Code</label>
                            <Input fluid placeholder='Code'
                                maxLength="100"
                                value={this.state.ItemData.Code}
                                onChange={this.handleChange("Code").bind(this)} />
                        </Table.Cell>
                        <Table.Cell width='1' textAlign='right' />
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width='2'>
                            <label>Details</label>
                        </Table.Cell>
                        <Table.Cell width='1' textAlign='right'>
                            <Button.Group>
                                <Button size="mini" icon="add"
                                    onClick={this.onAddAllowanceType.bind(this)} disabled={this.state.loadingAllowance} />
                                <Button size="mini" icon="edit"
                                    onClick={this.onEditAllowanceType.bind(this)} disabled={this.state.loadingAllowance} />
                                <Button size="mini" icon="delete"
                                    onClick={this.onDeleteAllowanceType.bind(this)} disabled={this.state.loadingAllowance} />
                            </Button.Group>
                        </Table.Cell>
                        <Table.Cell width='4' textAlign='right' />
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colSpan='2' width='2'>
                            <Table basic stackable fluid>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell width={2}>Allowance Type</Table.HeaderCell>
                                        <Table.HeaderCell width={1}>Allowance Properties</Table.HeaderCell>
                                        <Table.HeaderCell width={1}>Applied Cutoff</Table.HeaderCell>
                                        <Table.HeaderCell width={1}>Rate Type</Table.HeaderCell>
                                        <Table.HeaderCell width={1}>Amount</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.ItemData.AllowanceTypes.map(this.mapSelectedTypes)}
                                </Table.Body>
                            </Table>
                        </Table.Cell>
                    </Table.Row>
                </Table>
                {this.state.addAllowanceTypeDialog &&
                    <AllowanceProfileFormModal
                        ref={this.allowanceProfileModal}
                        allowanceTypes={this.state.allowanceTypes}
                        open={this.state.addAllowanceTypeDialog}
                        onClose={this.onCloseAllowanceType}
                        onConfirmAllowanceType={this.onConfirmAllowanceType}
                        onCloseAllowanceType={this.onCloseAllowanceType}
                    />
                }
                {this.state.detailDialog &&
                    <AllowanceProfilePropertyModal
                        open={this.state.detailDialog}
                        allowanceType={this.state.allowanceTypes.find(item => item._id === this.state.detailAllowanceId)}
                        onClose={this.onDetailClose}
                    />
                }
                {
                    <Modal size="mini" open={this.state.dialog} onClose={this.close} >
                        <Header content={this.state.dialogTitle} />
                        <Modal.Content>
                            {this.state.dialogContent}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button basic icon="check" content="Yes"
                                onClick={this.onCloseDialog.bind(this)} />
                        </Modal.Actions>
                    </Modal>
                }
                {this.state.showInvalidAction &&
                    <MessageBoxOkOnly 
                        onClick={() => this.setState({showInvalidAction: false})}
                        title="Invalid Action"
                        caption="Please select an item first."
                    />
                }
            </div >
        );
    }
}
export default AllowanceProfileForm