import React, { Component } from 'react';
import { Accordion, Button, Checkbox, Input, Dropdown, Grid, Table, Card, Header, Modal, Loader, Menu, TextArea, Message, Divider, Tab } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { ajaxPost } from '../../ajax';
import { empty_id, checkInt32 } from '../../utils';

import modStyles from './PayrollProfileForm.module.css'

class PayrollProfileForm extends Component {
    state = {
        ItemData: {
            _id: empty_id,
            ProfileName: "",
            WorkingDays: 26.0833,
            PayrollFrequency: "",
            HoursPerDay: 8,
            MinimumWage: 12000,
            NoWorkNoPayOnHoliday: false,
            NoWorkNoPayOnSpecialHoliday: false,
            SpecialGSISMember: false,
            PaidHolidayOnRestday: false,
            Notes: "",
            Transactions: []
        },
        AppliedCutoffs: [],
        saving: false,
        dialog: false,
        dialogContent: null,
        dialogTitle: '',
        conflict: [],
        showConflict: false,
        showRequiredField: false
    }
    constructor(props, context) {
        super(props, context);
        this.state.ItemData = props.selectedItem == null ? this.state.ItemData : props.selectedItem;
        this.updateCutoffNumber(this.state.ItemData.PayrollFrequency,"");
        this.loadTransactions();

    }
    loadTransactions = () => {
        let list = [];
        var ItemData = this.state.ItemData;
        var transactionList = ["BASIC_PAY", "COLA","ALLOWANCE", "OVERTIME", "ABSENT", "LATE", "UNDERTIME",
            "PAGIBIG_EE", "PAGIBIG_ER", "SSS_EE", "SSS_ER", "SSS_EC", "GSIS_EE", "GSIS_ER", "UNION_DUES", "PHILHEALTH_EE", "PHILHEALTH_ER", "TAX", "HAZARD_PAY","PAGIBIG_MP2"];
        transactionList.map(x => {
            var item = ItemData.Transactions.filter((e) => e.TransactionCode == x);
            if (item.length == 0) {
                return (
                    list.push({
                        TransactionCode: x,
                        Description: x.replace("_", " "),
                        AppliedCutoff: "EVERY_CUTOFF",
                        Compute: x.includes("GSIS") ? false : true
                    }
                    )
                )
            } else { list.push(item[0]); }
        });
        ItemData.Transactions = list;
        this.setState({ ItemData: ItemData });

    }
    save = (callback, model) => {
        ajaxPost({
            url: 'api/PayrollProfile/save',
            data: {
                "model": JSON.stringify(model),
                "includeDeleted": false,
                "startingIndex": 0,
                "itemCount": 1
            },
            onSuccess: data => {
                callback(data);
            },
            onError: function (err) {
                alert(JSON.stringify(err));
            },
            finally: () => { this.setState({ saving: false }); }
        })
    }
    handleSave = (e, data) => {
        this.setState({ saving: true });
        var model = this.state.ItemData;
        var IsSaving = true;
        if (!checkInt32(model.MinimumWage).Result) {
            this.setState({ showRequiredField: true });
            IsSaving = false;
        }
        if (!checkInt32(model.WorkingDays).Result) {
            this.setState({ showRequiredField: true });
            IsSaving = false;
        }
        if (!checkInt32(model.HoursPerDay).Result) {
            this.setState({ showRequiredField: true });
            IsSaving = false;
        }
        var computeCount = model.Transactions.filter(x => {
            return x.Compute == true;
        });
        if (computeCount == 0) {
            this.setState({
                showRequiredField: true,
                saving: false, showConflict: true,
                conflict: ["Please Include transaction/s"]
            });
            IsSaving = false;
        }

        if (IsSaving) {

            this.save(data => {
                this.setState({ saving: false });
                this.setState({ showConflict: false });
                if (data.content != null) {
                    model = data.content;

                    this.setState({ ItemData: model });
                    this.dialogOnSuccess();
                }
                if (data.errors != null) {
                    if (data.errors.length > 0) {
                        this.setState({ showConflict: true });
                        this.setState({ conflict: data.errors });
                    }
                }

            }, model);
        }
    }
    handleChange = name => event => {
        var current = this.state.ItemData;
        current[name] = event.target.value;
        this.setState({ ItemData: current });
    };
    handleCheckChange = name => (trans, e) => {
        var current = this.state.ItemData;
        if (current[name] == undefined) {
            var transactions = current.Transactions;
            var index = transactions.indexOf(trans);
            var transaction = transactions[index];
            transaction[name] = transaction[name] === false ? true : false;
            if(transaction.TransactionCode == 'BASIC_PAY' && transaction.Compute){
                current.PieceRateMember = false;
            }
            transactions[index] = transaction;
            current.Transactions = transactions;
        } else {
            current[name] = current[name] === false ? true : false;
        }
        this.setState({ ItemData: current });
    }
    handlePieceRateChange = name => (trans, e) =>{
        var current = this.state.ItemData;
        current[name] =  e.checked;
        if(e.checked){
            var basicPay = current.Transactions.filter(x=>x.TransactionCode == 'BASIC_PAY');
            basicPay[0].Compute = false;
        }
        this.setState({ItemData : current});
    }


    handleComboChange = (trans, name, val) => {
        var current = this.state.ItemData;
        if (current[val.name] == undefined) {
            var transactions = current.Transactions;
            var index = transactions.indexOf(trans);
            var transaction = transactions[index];
            transaction[val.name] = val.value;
            transactions[index] = transaction;
            current.Transactions = transactions;
        } else {
            current[val.name] = val.value;
        }
        if (val.name === "PayrollFrequency") {
            this.updateCutoffNumber(val.value,"");
        }
        this.setState({ ItemData: current });
    }
    updateCutoffNumber = (frequency, transcode) => {
        let AppliedCutoffs = []; 
        const tradiness = ["ABSENT", "LATE", "UNDERTIME"];

        if (frequency === 'MONTHLY') {
            AppliedCutoffs = [{ key: 1, text: 'EVERY CUTOFF', value: 'EVERY_CUTOFF' }];
        }
        else if (frequency === 'SEMIMONTHLY') {
            AppliedCutoffs = [{ key: 1, text: 'EVERY CUTOFF', value: 'EVERY_CUTOFF' },
            { key: 2, text: 'LAST CUTOFF', value: 'LAST_CUTOFF' },
            { key: 3, text: 'FIRST CUTOFF', value: 'FIRST_CUTOFF' }];
        } else {
            if(tradiness.includes(transcode)){
                AppliedCutoffs = [
                    { key: 1, text: 'EVERY CUTOFF', value: 'EVERY_CUTOFF' }]
            }else{

                AppliedCutoffs = [
                    { key: 1, text: 'EVERY CUTOFF', value: 'EVERY_CUTOFF' },
                    { key: 2, text: 'LAST CUTOFF', value: 'LAST_CUTOFF' },
                    { key: 3, text: 'FIRST CUTOFF', value: 'FIRST_CUTOFF' },
                    { key: 4, text: 'SECOND CUTOFF', value: 'SECOND_CUTOFF' },
                    { key: 5, text: 'THIRD CUTOFF', value: 'THIRD_CUTOFF' },
                { key: 6, text: 'FOURTH CUTOFF', value: 'FOURTH_CUTOFF' },
                { key: 7, text: 'FIFTH CUTOFF', value: 'FIFTH_CUTOFF' }
            ];
          }
        } 
        return AppliedCutoffs;
    } 
    onCloseDialog = () => {
        this.setState({ dialog: false });
        this.props.onUpdateComplete(this.state.ItemData);
    }
    dialogOnSuccess = () => {
        this.setState({ dialogTitle: "Save" });
        this.setState({
            dialogContent: (
                <div>
                    <p>Payroll Profile has been updated successfully</p>
                </div>
            )
        });
        this.setState({ dialog: true });
    }
    render() {
        const PayrollFrequencies = [
            { key: 1, text: 'MONTHLY', value: 'MONTHLY' },
            { key: 2, text: 'SEMI-MONTHLY', value: 'SEMIMONTHLY' },
            { key: 3, text: 'WEEKLY', value: 'WEEKLY' },
        ]
        return <>
            <div className={modStyles.module}>
                <div >
                    <h2 style={{ color: "#606060", marginTop: '1%', float: "left" }}>Payroll Profile Details</h2>
                    <Menu size="mini" secondary stackable>
                        <Menu.Menu position="right" >
                            <Button.Group>
                                <Button content="Save" size="mini" icon="save"
                                    onClick={this.handleSave.bind(this)} style={{ margin: "0px 3px 0px 0px" }} />
                                <Button content="Cancel" size="mini" icon="cancel"
                                    onClick={this.props.onCloseForm.bind(this)} />
                            </Button.Group>
                        </Menu.Menu>
                    </Menu>
                </div>
                <div>
                    <Loader active={this.state.saving} />
                    {this.state.showConflict &&
                        <Message negative>
                            <Message.Header>Conflict !</Message.Header>
                            <Message.Content>{this.state.conflict.map(n => {
                                return (<p>{n}</p>)
                            })}</Message.Content>
                        </Message>}
                    <Table>
                        <Table.Row>
                            <Table.Cell width='5'>
                                <label>Description</label>
                            </Table.Cell>
                            <Table.Cell width='3'>
                                <Input fluid placeholder='Profile Name'
                                    value={this.state.ItemData.ProfileName}
                                    onChange={this.handleChange("ProfileName").bind(this)} />
                            </Table.Cell>
                            <Table.Cell width='5'>
                                <label>Day Per Month *</label>
                            </Table.Cell>
                            <Table.Cell width='3'>
                                <Input fluid placeholder='WorkingDays'
                                    value={this.state.ItemData.WorkingDays}
                                    error={this.state.showRequiredField && !checkInt32(this.state.ItemData.WorkingDays).Result || this.state.ItemData.WorkingDays == ""}
                                    onChange={this.handleChange("WorkingDays").bind(this)} />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell width='5'>
                                <label>Payroll Frequency</label>
                            </Table.Cell>
                            <Table.Cell width='3'>
                                <Dropdown fluid selection options={PayrollFrequencies} placeholder="Select Schedule Type"
                                    value={this.state.ItemData.PayrollFrequency} name="PayrollFrequency"
                                    onChange={this.handleComboChange.bind(this, "")}
                                />
                            </Table.Cell>
                            <Table.Cell width='5'>
                                <label>Hours Per Day *</label>
                            </Table.Cell>
                            <Table.Cell width='3'>
                                <Input fluid placeholder='Hours Per Day'
                                    value={this.state.ItemData.HoursPerDay}
                                    error={this.state.showRequiredField && !checkInt32(this.state.ItemData.HoursPerDay).Result || this.state.ItemData.HoursPerDay == ""}
                                    onChange={this.handleChange("HoursPerDay").bind(this)} />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell width='5'>
                                <label>Minimum Wage Per Day *</label>
                            </Table.Cell>
                            <Table.Cell width='3'>
                                <Input fluid placeholder='Minimum Wage'
                                    value={this.state.ItemData.MinimumWage}
                                    error={this.state.showRequiredField && !checkInt32(this.state.ItemData.MinimumWage).Result || this.state.ItemData.MinimumWage == ""}
                                    onChange={this.handleChange("MinimumWage").bind(this)} />
                            </Table.Cell>
                            <Table.Cell width='5'>
                                <label>No Work No Pay On Holiday</label>
                            </Table.Cell>
                            <Table.Cell width='3'>
                                <Checkbox toggle checked={this.state.ItemData.NoWorkNoPayOnHoliday}
                                    onClick={this.handleCheckChange("NoWorkNoPayOnHoliday").bind(this)} />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell width='5'>
                                <label>Payslip Notes</label>
                            </Table.Cell>
                            <Table.Cell width='3'>
                                <div>
                                    <TextArea fluid style={{ width: '100%' }}
                                        value={this.state.ItemData.Notes}
                                        onChange={this.handleChange("Notes").bind(this)} /></div>
                            </Table.Cell>
                            <Table.Cell>
                                <label>No Work No Pay On Special Holiday</label>
                            </Table.Cell>
                            <Table.Cell>
                                <Checkbox toggle checked={this.state.ItemData.NoWorkNoPayOnSpecialHoliday}
                                    onClick={this.handleCheckChange("NoWorkNoPayOnSpecialHoliday").bind(this)} />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell width='5'>
                                <label>Special GSIS Member</label>
                            </Table.Cell>
                            <Table.Cell>
                                <Checkbox toggle checked={this.state.ItemData.SpecialGSISMember}
                                    onClick={this.handleCheckChange("SpecialGSISMember").bind(this)} />
                            </Table.Cell>
                            <Table.Cell width='5'>
                                <label>Holiday Pay For Holiday Falls on Rest Day</label>
                            </Table.Cell>
                            <Table.Cell>
                                <Checkbox toggle checked={this.state.ItemData.PaidHolidayOnRestday}
                                    onClick={this.handleCheckChange("PaidHolidayOnRestday").bind(this)} />
                            </Table.Cell>
                        </Table.Row>
                    </Table>
                    <Header disabled>Payroll Transactions</Header>
                    <div>
                        <Table stackable fluid>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Include</Table.HeaderCell>
                                    <Table.HeaderCell>Transaction Type</Table.HeaderCell>
                                    <Table.HeaderCell>Payroll Cutoff</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.ItemData.Transactions != null &&
                                    this.state.ItemData.Transactions.map(n => {
                                        return (
                                            <Table.Row>
                                                <Table.Cell>
                                                    <Checkbox toggle checked={n.Compute}
                                                        onClick={this.handleCheckChange("Compute").bind(this, n)} />
                                                </Table.Cell>
                                                <Table.Cell>{n.Description}</Table.Cell>
                                                <Table.Cell>
                                                    <Dropdown fluid selection options={this.updateCutoffNumber(this.state.ItemData.PayrollFrequency,n.TransactionCode)}
                                                        value={n.AppliedCutoff} name="AppliedCutoff"
                                                        onChange={this.handleComboChange.bind(this, n)} />
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                }

                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
            {/* Modal Form */}
            {
                <Modal size="mini" open={this.state.dialog} onClose={this.close} >
                    <Header content={this.state.dialogTitle} />
                    <Modal.Content>
                        {this.state.dialogContent}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic icon="check" content="Yes"
                            onClick={this.onCloseDialog.bind(this)} />
                    </Modal.Actions>
                </Modal>
            }
        </>
    }
}
export default PayrollProfileForm