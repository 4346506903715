import React, { Component } from "react";
import Select from "react-select";

import {
    Input,
    Tab,
} from "semantic-ui-react";

import {
    isTaskAvailable,
} from "../../utils";

import {
    selectionList
} from './EmployeeCommon'

var {
    handleNumericOnKeyPress,
    handleNumericOnKeyDown,
    customStyles,
    empty_id,
} = require("../../utils");

const separationTypeList = [
    { text: 'AWOL', value: 'AWOL' },
    { text: 'End of Contract', value: 'EOC' },
    { text: 'Floating', value: 'FLOATING' },
    { text: 'Resignation', value: 'RESIGNATION' },
    { text: 'Retirement', value: 'RETIREMENT' },
    { text: 'Retrenchment', value: 'RETRENCHMENT' },
    { text: 'Termination', value: 'TERMINATION' },
];


const SalaryTypes = [
    { label: "Monthly", value: "MONTHLY" },
    { label: "Daily", value: "DAILY" },
    { label: "Hourly", value: "HOURLY" },
    // { label: "Piece Rate", value: "PIECERATE" },
];
const BankTypes = [
    { label: "None", value: "NONE" },
    { label: "Savings", value: "SAVINGS" },
    { label: "Current", value: "CURRENT" },
];
const GovernmentBasedTypes = [
    { label: "Amount based on Gross", value: "GROSS" },
    { label: "Amount based on Basic", value: "BASIC" },
    { label: "Fixed Amount", value: "FIX_AMOUNT" },
];

export function renderEmployeePayroll(this2) {
    const {
        atcProfileList,
        payrollRateProfiles,
        banks,
        payrollProfiles,
        allowanceProfiles,
    } = this2.state;


    return <Tab.Pane style={{ border: "none" }}>
        <label
            style={{
                fontWeight: "bold",
                fontSize: "1.3em",
                color: "#606060",
            }}
        >
            Payroll Information
        </label>
        <hr style={{ color: "#606060", "border-width": "0.8px" }} />
        <div className="w3-row" style={{ width: "100%" }}>
            <div className="w3-col m12 l8">
                <div className="w3-row" style={{ paddingTop: "10px" }}>
                    <div className="w3-col s12 m5 l5">
                        <label> Payroll Profile </label>
                    </div>
                    <div className="w3-col s12 m7 l7">
                        <Select
                            isClearable
                            size="mini"
                            style={{
                                minWidth: "50px",
                                maxWidth: "200px",
                                width: "100%",
                            }}
                            placeholder="Payroll Profile"
                            value={selectionList(
                                payrollProfiles,
                                ["ProfileName"],
                                "id"
                            ).find(
                                (x) =>
                                    x.value == this2.state.payrollInfo.PayrollProfileId
                            )}
                            options={selectionList(
                                payrollProfiles,
                                ["ProfileName"],
                                "id"
                            )}
                            onChange={this2.handleValueChange.bind(
                                this2,
                                "PayrollProfileId",
                                ""
                            )}
                        />
                        {isTaskAvailable("PAYROLL_PROFILE_CREATE") && (
                            <a
                                as="a"
                                href="#"
                                onClick={this2.onCreateNewItemClick.bind(
                                    this2,
                                    "PAYROLLPROFILE",
                                    false
                                )}
                            >
                                Create New
                            </a>
                        )}
                    </div>
                </div>
                <div className="w3-row" style={{ paddingTop: "10px" }}>
                    <div className="w3-col s12 m5 l5">
                        <label> Payroll Rate Profile </label>
                    </div>
                    <div className="w3-col s12 m7 l7">
                        <Select
                            isClearable
                            size="mini"
                            style={{
                                minWidth: "50px",
                                maxWidth: "200px",
                                width: "100%",
                            }}
                            placeholder="Payroll Rate Profile"
                            value={selectionList(
                                payrollRateProfiles,
                                ["ProfileName"],
                                "id"
                            ).find(
                                (x) => x.value == this2.state.payrollInfo.RateProfileId
                            )}
                            options={selectionList(
                                payrollRateProfiles,
                                ["ProfileName"],
                                "id"
                            )}
                            onChange={this2.handleValueChange.bind(
                                this2,
                                "RateProfileId",
                                ""
                            )}
                        />
                        {isTaskAvailable("PAYROLL_RATE_PROFILE_CREATE") && (
                            <a
                                as="a"
                                href="#"
                                onClick={this2.onCreateNewItemClick.bind(
                                    this2,
                                    "PAYROLLRATEPROFILE",
                                    false
                                )}
                            >
                                Create New
                            </a>
                        )}
                    </div>
                </div>
                <div className="w3-row" style={{ paddingTop: "10px" }}>
                    <div className="w3-col s12 m5 l5">
                        <label> ATC Profile </label>
                    </div>
                    <div className="w3-col s12 m7 l7">
                        <Select
                            isClearable
                            styles={customStyles}
                            placeholder="ATC Profile"
                            value={selectionList(
                                atcProfileList,
                                ["label"],
                                "value"
                            ).find(
                                (x) =>
                                    x.value ===
                                    this2.state.payrollInfo.AlphanumericTaxCodeProfileId
                            )}
                            options={selectionList(
                                atcProfileList,
                                ["label"],
                                "value"
                            )}
                            onChange={this2.handleATCProfileChange.bind(this2)}
                            isLoading={this2.state.isATCProfileLoading}
                            onInputChange={this2.handleATCProfileInputKeyDown.bind(
                                this2
                            )}
                        />
                        {isTaskAvailable("ATCPROFILE_ADD") && (
                            <a
                                as="a"
                                href="#"
                                onClick={this2.onCreateNewItemClick.bind(
                                    this2,
                                    "ATCPROFILE",
                                    false
                                )}
                            >
                                Create New
                            </a>
                        )}
                    </div>
                </div>
                <div className="w3-row" style={{ paddingTop: "10px" }}>
                    <div className="w3-col s12 m5 l5">
                        <label> Salary Type</label>
                    </div>
                    <div className="w3-col s12 m7 l7">
                        <Select
                            isClearable
                            size="mini"
                            style={{
                                minWidth: "50px",
                                maxWidth: "200px",
                                width: "100%",
                            }}
                            placeholder="Salary Type"
                            value={SalaryTypes.find(
                                (x) => x.value == this2.state.payrollInfo.SalaryType
                            )}
                            options={SalaryTypes}
                            onChange={this2.handleDropDownPayrollInfoChange(
                                "SalaryType"
                            ).bind(this2)}
                        />
                    </div>
                </div>
                {
                    this2.state.payrollInfo.SalaryType != "PIECERATE" &&
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                    <div className="w3-col s12 m5 l5">
                        <label>Basic Salary</label>
                    </div>
                    <div className="w3-col s12 m7 l7">
                        <Input
                            placeholder="Basic Pay"
                            style={{ minWidth: "50px", maxWidth: "100%" }}
                            value={this2.state.payrollInfo.BasicPay}
                            onChange={this2.handlePayrollProfileChange(
                                "BasicPay"
                            ).bind(this2)}
                            error={this2.state.isBasicError == true}
                            onKeyPress={(e) => {
                                handleNumericOnKeyPress(e);
                            }}
                            onKeyDown={(e) => {
                                handleNumericOnKeyDown(e);
                            }}
                        />
                        <label>{this2.state.PerRate}</label>
                    </div>
                </div>
                }
                <div className="w3-row" style={{ paddingTop: "10px" }}>
                    <div className="w3-col s12 m5 l5">
                        <label>Cola</label>
                    </div>
                    <div className="w3-col s12 m7 l7">
                        <Input
                            placeholder="Cola"
                            style={{ minWidth: "50px", maxWidth: "100%" }}
                            value={this2.state.payrollInfo.Cola}
                            onChange={this2.handlePayrollProfileChange("Cola").bind(
                                this2
                            )}
                            error={this2.state.isColaError == true}
                            onKeyPress={(e) => {
                                handleNumericOnKeyPress(e);
                            }}
                            onKeyDown={(e) => {
                                handleNumericOnKeyDown(e);
                            }}
                        />
                        <label>{this2.state.PerRate}</label>
                    </div>
                </div>
                <div className="w3-row" style={{ paddingTop: "10px" }}>
                    <div className="w3-col s12 m5 l5">
                        <label>Allowance</label>
                    </div>
                    <div className="w3-col s12 m7 l7">
                        <Input
                            placeholder="Allowance"
                            style={{ minWidth: "50px", maxWidth: "100%" }}
                            value={this2.state.payrollInfo.Allowance}
                            onChange={this2.handlePayrollProfileChange(
                                "Allowance"
                            ).bind(this2)}
                            error={this2.state.isAllowanceError == true}
                            ref={(input) => (this2.Allowance = input)}
                            onKeyPress={(e) => {
                                handleNumericOnKeyPress(e);
                            }}
                            onKeyDown={(e) => {
                                handleNumericOnKeyDown(e);
                            }}
                        />
                        <label>{this2.state.PerRate}</label>
                    </div>
                </div>
                <div className="w3-row" style={{ paddingTop: "10px" }}>
                    <div className="w3-col s12 m5 l5">
                        <label>De minimis</label>
                    </div>
                    <div className="w3-col s12 m7 l7">
                        <Input
                            placeholder="Deminimis"
                            style={{ minWidth: "50px", maxWidth: "100%" }}
                            value={this2.state.payrollInfo.Deminimis}
                            onChange={this2.handlePayrollProfileChange(
                                "Deminimis"
                            ).bind(this2)}
                            error={this2.state.isDeminimisError == true}
                            onKeyPress={(e) => {
                                handleNumericOnKeyPress(e);
                            }}
                            onKeyDown={(e) => {
                                handleNumericOnKeyDown(e);
                            }}
                        />
                        <label>{this2.state.PerRate}</label>
                    </div>
                </div>
                <div className="w3-row" style={{ paddingTop: "10px" }}>
                    <div className="w3-col s12 m5 l5">
                        <label> Allowance Profile </label>
                    </div>
                    <div className="w3-col s12 m7 l7">
                        <Select
                            isClearable
                            size="mini"
                            style={{
                                minWidth: "50px",
                                maxWidth: "200px",
                                width: "100%",
                            }}
                            placeholder="Allowance Profile"
                            value={selectionList(
                                allowanceProfiles,
                                ["Description"],
                                "id"
                            ).find(
                                (x) =>
                                    x.value == this2.state.payrollInfo.AllowanceProfileId
                            )}
                            options={selectionList(
                                allowanceProfiles,
                                ["Description"],
                                "id"
                            )}
                            onChange={this2.handleValueChange.bind(
                                this2,
                                "AllowanceProfileId",
                                ""
                            )}
                        />
                        {isTaskAvailable("ALLOWANCE_PROFILE_CREATE") && (
                            <a
                                as="a"
                                href="#"
                                onClick={this2.onCreateNewItemClick.bind(
                                    this2,
                                    "ALLOWANCEPROFILE",
                                    false
                                )}
                            >
                                Create New
                            </a>
                        )}
                    </div>
                </div>
                <div className="w3-row" style={{ paddingTop: "10px" }}>
                    <div className="w3-col s12 m5 l5">
                        <label>Hazard Pay</label>
                    </div>
                    <div className="w3-col s12 m7 l7">
                        <Input
                            placeholder="Hazard Pay"
                            style={{ minWidth: "50px", maxWidth: "100%" }}
                            value={this2.state.payrollInfo.HazardPay}
                            onChange={this2.handlePayrollProfileChange(
                                "HazardPay"
                            ).bind(this2)}
                            error={this2.state.isHazardPayError == true}
                            onKeyPress={(e) => {
                                handleNumericOnKeyPress(e);
                            }}
                            onKeyDown={(e) => {
                                handleNumericOnKeyDown(e);
                            }}
                        /> 
                    </div>
                </div>
                <div className="w3-row" style={{ paddingTop: "10px" }}>
                    <div className="w3-col s12 m5 l5">
                        <label>Pagibig MP2</label>
                    </div>
                    <div className="w3-col s12 m7 l7">
                        <Input
                            placeholder="Pagibig MP2"
                            style={{ minWidth: "50px", maxWidth: "100%" }}
                            value={this2.state.payrollInfo.PagibigMP2}
                            onChange={this2.handlePayrollProfileChange(
                                "PagibigMP2"
                            ).bind(this2)}
                            error={this2.state.isPagibigMP2Error == true}
                            ref={(input) => (this2.PagibigMP2 = input)}
                            onKeyPress={(e) => {
                                handleNumericOnKeyPress(e);
                            }}
                            onKeyDown={(e) => {
                                handleNumericOnKeyDown(e);
                            }}
                        /> 
                    </div>
                </div>
                <div className="w3-row" style={{ paddingTop: "10px" }}>
                    <div className="w3-col s12 m5 l5">
                        <label>Bank</label>
                    </div>
                    <div className="w3-col s12 m7 l7">
                        <Select
                            isClearable
                            size="mini"
                            style={{
                                minWidth: "50px",
                                maxWidth: "200px",
                                width: "100%",
                            }}
                            placeholder="Bank"
                            value={selectionList(banks, ["Description"], "id").find(
                                (x) => x.value == this2.state.payrollInfo.BankId
                            )}
                            options={selectionList(banks, ["Description"], "id")}
                            onChange={this2.handleValueChange.bind(this2, "BankId", "")}
                        />
                    </div>
                </div>
                <div className="w3-row" style={{ paddingTop: "10px" }}>
                    <div className="w3-col s12 m5 l5">
                        <label>Bank Account</label>
                    </div>
                    <div className="w3-col s12 m7 l7">
                        <Input
                            placeholder="Bank Account Number"
                            style={{ minWidth: "50px", maxWidth: "100%" }}
                            disabled={
                                this2.state.payrollInfo.BankId == empty_id ? true : false
                            }
                            value={this2.state.payrollInfo.BankAccountNumber}
                            onChange={this2.handlePayrollProfileChange(
                                "BankAccountNumber"
                            ).bind(this2)}
                        />
                    </div>
                </div>
                <div className="w3-row" style={{ paddingTop: "10px" }}>
                    <div className="w3-col s12 m5 l5">
                        <label>Bank Account Type</label>
                    </div>
                    <div className="w3-col s12 m7 l7">
                        <Select
                            isClearable
                            size="mini"
                            style={{
                                minWidth: "50px",
                                maxWidth: "200px",
                                width: "100%",
                            }}
                            placeholder="Bank Account Type"
                            isDisabled={
                                this2.state.payrollInfo.BankId == empty_id ? true : false
                            }
                            value={BankTypes.find(
                                (x) => x.value == this2.state.payrollInfo.BankAccountType
                            )}
                            options={BankTypes}
                            onChange={this2.handleDropDownPayrollInfoChange(
                                "BankAccountType"
                            ).bind(this2)}
                        />
                    </div>
                </div>
                <div className="w3-row" style={{ paddingTop: "10px" }}>
                    <div className="w3-col s12 m5 l5">
                        <label>Pagibig Amount Based</label>
                    </div>
                    <div className="w3-col s12 m7 l7">
                        <Select
                            isClearable
                            size="mini"
                            style={{
                                minWidth: "50px",
                                maxWidth: "200px",
                                width: "100%",
                            }}
                            value={GovernmentBasedTypes.find(
                                (x) => x.value == this2.state.payrollInfo.PagibigBased
                            )}
                            options={GovernmentBasedTypes}
                            onChange={this2.handleDropDownPayrollInfoChange(
                                "PagibigBased"
                            ).bind(this2)}
                        />
                    </div>
                </div>
                {this2.state.showPagibigAmount && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div className="w3-col s12 m5 l5">
                            <label>Pagibig EE</label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Input
                                placeholder="Pagibig EE Amount"
                                style={{ minWidth: "50px", maxWidth: "100%" }}
                                value={this2.state.payrollInfo.PagibigEE}
                                onChange={this2.handlePayrollProfileChange(
                                    "PagibigEE"
                                ).bind(this2)}
                            />
                        </div>
                    </div>
                )}
                {this2.state.showPagibigAmount && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div className="w3-col s12 m5 l5">
                            <label>Pagibig ER</label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Input
                                placeholder="Pagibig ER Amount"
                                style={{ minWidth: "50px", maxWidth: "100%" }}
                                value={this2.state.payrollInfo.PagibigER}
                                onChange={this2.handlePayrollProfileChange(
                                    "PagibigER"
                                ).bind(this2)}
                            />
                        </div>
                    </div>
                )}
                <div className="w3-row" style={{ paddingTop: "10px" }}>
                    <div className="w3-col s12 m5 l5">
                        <label>Philhealth Amount Based</label>
                    </div>
                    <div className="w3-col s12 m7 l7">
                        <Select
                            isClearable
                            size="mini"
                            style={{
                                minWidth: "50px",
                                maxWidth: "200px",
                                width: "100%",
                            }}
                            value={GovernmentBasedTypes.find(
                                (x) => x.value == this2.state.payrollInfo.PhilHealthBased
                            )}
                            options={GovernmentBasedTypes}
                            onChange={this2.handleDropDownPayrollInfoChange(
                                "PhilHealthBased"
                            ).bind(this2)}
                        />
                    </div>
                </div>
                {this2.state.showPhilhealthAmount && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div className="w3-col s12 m5 l5">
                            <label>PhilHealth EE</label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Input
                                placeholder="PhilHealth Amount"
                                style={{ minWidth: "50px", maxWidth: "100%" }}
                                value={this2.state.payrollInfo.PhilHealthEE}
                                onChange={this2.handlePayrollProfileChange(
                                    "PhilHealthEE"
                                ).bind(this2)}
                            />
                        </div>
                    </div>
                )}
                {this2.state.showPhilhealthAmount && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div className="w3-col s12 m5 l5">
                            <label>PhilHealth ER</label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Input
                                placeholder="PhilHealth Amount"
                                style={{ minWidth: "50px", maxWidth: "100%" }}
                                value={this2.state.payrollInfo.PhilHealthER}
                                onChange={this2.handlePayrollProfileChange(
                                    "PhilHealthER"
                                ).bind(this2)}
                            />
                        </div>
                    </div>
                )}
                <div className="w3-row" style={{ paddingTop: "10px" }}>
                    <div className="w3-col s12 m5 l5">
                        <label>SSS Amount Based</label>
                    </div>
                    <div className="w3-col s12 m7 l7">
                        <Select
                            isClearable
                            size="mini"
                            style={{
                                minWidth: "50px",
                                maxWidth: "200px",
                                width: "100%",
                            }}
                            value={GovernmentBasedTypes.find(
                                (x) => x.value == this2.state.payrollInfo.SSSBased
                            )}
                            options={GovernmentBasedTypes}
                            onChange={this2.handleDropDownPayrollInfoChange(
                                "SSSBased"
                            ).bind(this2)}
                        />
                    </div>
                </div>
                {this2.state.showSssAmount && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div className="w3-col s12 m5 l5">
                            <label>SSS EE</label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Input
                                placeholder="SSS Amount"
                                style={{ minWidth: "50px", maxWidth: "100%" }}
                                value={this2.state.payrollInfo.SSSEE}
                                onChange={this2.handlePayrollProfileChange("SSSEE").bind(
                                    this2
                                )}
                            />
                        </div>
                    </div>
                )}
                {this2.state.showSssAmount && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div className="w3-col s12 m5 l5">
                            <label>SSS ER</label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Input
                                placeholder="SSS Amount"
                                style={{ minWidth: "50px", maxWidth: "100%" }}
                                value={this2.state.payrollInfo.SSSER}
                                onChange={this2.handlePayrollProfileChange("SSSER").bind(
                                    this2
                                )}
                            />
                        </div>
                    </div>
                )}
                {this2.state.showSssAmount && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div className="w3-col s12 m5 l5">
                            <label>SSS EC</label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Input
                                placeholder="SSS Amount"
                                style={{ minWidth: "50px", maxWidth: "100%" }}
                                value={this2.state.payrollInfo.SSSEC}
                                onChange={this2.handlePayrollProfileChange("SSSEC").bind(
                                    this2
                                )}
                            />
                        </div>
                    </div>
                )}
                <div className="w3-row" style={{ paddingTop: "10px" }}>
                    <div className="w3-col s12 m5 l5">
                        <label>GSIS Amount Based</label>
                    </div>
                    <div className="w3-col s12 m7 l7">
                        <Select
                            isClearable
                            size="mini"
                            style={{
                                minWidth: "50px",
                                maxWidth: "200px",
                                width: "100%",
                            }}
                            value={GovernmentBasedTypes.find(
                                (x) => x.value == this2.state.payrollInfo.GSISBased
                            )}
                            options={GovernmentBasedTypes}
                            onChange={this2.handleDropDownPayrollInfoChange(
                                "GSISBased"
                            ).bind(this2)}
                        />
                    </div>
                </div>
                {this2.state.showGSISAmount && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div className="w3-col s12 m5 l5">
                            <label>GSIS EE</label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Input
                                placeholder="GSIS Amount"
                                style={{ minWidth: "50px", maxWidth: "100%" }}
                                value={this2.state.payrollInfo.GSISEE}
                                onChange={this2.handlePayrollProfileChange(
                                    "GSISEE"
                                ).bind(this2)}
                            />
                        </div>
                    </div>
                )}
                {this2.state.showGSISAmount && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div className="w3-col s12 m5 l5">
                            <label>GSIS ER</label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Input
                                placeholder="GSIS Amount"
                                style={{ minWidth: "50px", maxWidth: "100%" }}
                                value={this2.state.payrollInfo.GSISER}
                                onChange={this2.handlePayrollProfileChange(
                                    "GSISER"
                                ).bind(this2)}
                            />
                        </div>
                    </div>
                )}
                <div className="w3-row" style={{ paddingTop: "10px" }}>
                    <div className="w3-col s12 m5 l5">
                        <label>Tax Amount Based</label>
                    </div>
                    <div className="w3-col s12 m7 l7">
                        <Select
                            isClearable
                            size="mini"
                            style={{
                                minWidth: "50px",
                                maxWidth: "200px",
                                width: "100%",
                            }}
                            value={GovernmentBasedTypes.find(
                                (x) => x.value == this2.state.payrollInfo.TaxBased
                            )}
                            options={GovernmentBasedTypes}
                            onChange={this2.handleDropDownPayrollInfoChange(
                                "TaxBased"
                            ).bind(this2)}
                        />
                    </div>
                </div>
                {this2.state.showTaxAmount && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div className="w3-col s12 m5 l5">
                            <label>Tax</label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Input
                                placeholder="Tax Amount"
                                style={{ minWidth: "50px", maxWidth: "100%" }}
                                value={this2.state.payrollInfo.Tax}
                                onChange={this2.handlePayrollProfileChange("Tax").bind(
                                    this2
                                )}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    </Tab.Pane>

}

