import React, { Component } from "react";
import "semantic-ui-css/semantic.min.css";
import {
  Dropdown,
  Icon,
  Image,
  Menu,
  Sticky,
  Button,
  Label,
  Popup,
  Container,
  Modal,
} from "semantic-ui-react";
import { ajaxPost } from "../ajax";
import Notifications from "./Notifications";
import MessageBoxOkOnly from "../Commons/MessageBoxOkOnly";
import Dashboard from "../Dashboard/Dashboard";
import Licensing from "../Commons/Licensing";
import {
  ChangePasswordForm,
  ModuleMenu,
  NotificationPopupControl,
  processCaption,
} from "./MasterPageControls";

import AboutDialog from "./AboutDialog";
import RemoteClockDialog from "../Commons/RemoteClockDialog";
import EmployeeLoad from "../HRIS/Employee/EmployeeLoad";

import { TimeDisplayFormat, empState } from "../utils";
import {
  _modulesList,
  _payrollTransactions,
  _payrollSettings,
  _employeeModules,
  _transactions,
  _deviceModules,
  _reportModules,
  _adminModules,
  _payrollModules,
} from "./ModuleList";

import WhatsNewDialog from "../Commons/WhatsNewDialog";
import NotificationBell from "./NotificationBell";
import NotificationPreview from "./NotificationPreview";
import modStyle from './MasterPage3.module.css';

import { StartSignalR } from "./NotificationService";
import { StatusPanel } from "./StatusPanel.js";

import { v4 as uuidv4 } from 'uuid';
import SentryIntegration from "../TR/Integration/SentryIntegration";

export const empty_id = "00000000-0000-0000-0000-000000000000";

require("dotenv").config({ path: "./.env" });


var isSubscribed = "YES";

class MasterPage3 extends Component {
  constructor(props) {
    super(props);
    this.FunctionCollection = [];
    this.modulesList = [];

    _modulesList.forEach((grp) => {
      var grp2 = { GroupName: grp.GroupName, Modules: [] };
      grp2.Modules = grp.Modules.map((x) => {
        return {
          Module: x,
          IsFavorite: false,
        };
      });
      this.modulesList.push(grp2);

      grp.Modules.forEach((x) => {
        if ("init" in x) x.init(this);
      });
    });

    // isSubscribed = process.env.REACT_APP_CLIENT_SUBSCRIBED;
  }
  styles = {
    container: {
      padding: "10px",
      overflow: "hidden"
    },
    topMenu: {
      height: "60px",
      margin: 0,
      borderRadius: "0"
    },
    logo: {
      height: "30px",
      width: "120px",
      display: "inline-block",
      marginLeft: "10px",
      marginTop: "5px",
      cursor: "pointer",
    },
    topButton: {
      "box-shadow": "none!important",
    },
    popupNotification: {
      right: "20px",
      top: "50px",
      position: "fixed",
    },
    popupUser: {
      right: "20px",
      top: "50px",
      position: "fixed",
    },
    userMenu: {
      borderStyle: "none",
      boxShadow: "none",
    },
    avatarIcons: {
      width: "24px",
      height: "24px",
    },
    lblUser: {
      ":hover": {
        background: "#202020",
      },
      display: "inline-block",
      color: "white",
      cursor: "pointer",
      padding: "7px",
    },
    maintenanceMsg: {
      width: "100%",
      textAlign: "center",
    },
  };

  state = {
    toolbar: null,
    activeModules: [],
    selectedModule: null,
    modalOpen: false,
    notificationOpen: false,
    userOpen: false,
    saveStates: [],
    notifications: [],
    unread: 0,
    external: null,
    userName: "Loading...",
    isMobileView: false,
    isBrowserRecommended: true,
    showAboutDialog: false,
    showWhatsNew: false,
    maintenanceMsg: "NO_MAINTENANCE",
    hasMaintenanceMsg: false,
    hide: 500,
    show: 500,
    activeItem: "Dashboard",
    loadEmp: false,
    params: null,
    showStatus: false,
    notificationActive: false,
    showRemoteClockDownload: false,
    showAPIIntegration: false,
  };

  componentDidMount = () => {
    var token = localStorage.getItem("Token");
    if (token == null) {
      window.location.href = "/login";
      return;
    }

    this.checkBrowser();
    //this.getMaintenanceMessage();

    if (global.userId !== empty_id) {
      //user id for superadmin
      //load favorites
      ajaxPost({
        url: "api/User/listFavorites",
        data: null,
        onSuccess: (data) => {
          var a = data;
          let flat = this.modulesList
            .reduce((a, b) => {
              return a.concat(b.Modules);
            }, [])
            .filter((x) => {
              return data.some((y) => {
                return y === x.Module.getViewID();
              });
            });
          flat.forEach((x) => {
            this.onModuleSelect(x.Module, null, null, true, true);
          });
        },
        finally: () => {
          this.setState({ selectedModule: null });
        },
      });

      ajaxPost({
        url: "api/Employee/loadEmployeeBasicInfoById",
        data: global.userId,
        onSuccess: (data) => {
          var emp = data.content;
          this.setState({ userName: emp.FirstName + " " + emp.LastName });
        },
        finally: () => { },
      });

    } else {
      this.setState({ userName: "superadmin" });
    }

    var settingParameter = {
      data: null,
      url: "api/Setting/search",
      onError: (error) => {
        alert("error\r\n" + JSON.stringify(error));
      },
      onSuccess: (data, sender) => {
        global.DateDisplayFormat = data.content.DateDisplayFormat;
        global.TimeDisplayFormat = data.content.TimeDisplayFormat;
        global.MinPasswordLenght = data.content.MinPasswordLenght;
        global.MaxLoginTries = data.content.MaxLoginTries;
      },
      finally: () => { },
    };
    ajaxPost(settingParameter);

    setTimeout(this.checkNotifications, 10000);
    window.addEventListener("click", this.onPageClick);

    StartSignalR();

  };

  componentWillUnmount = () => {
    window.removeEventListener("click", this.onPageClick);
  };

  openLicensingPage = () => {
    this.onModuleSelect(Licensing, null, null);
    this.setState({ showAboutDialog: false });
  };


  handleUpdateEmployeeClick = (params) => {
    this.setState({ params: params });
    this.setState({ loadEmp: true });
  };

  handlePinChange = (itemClass, e) => {
    var item = this.state.activeModules.find(
      (x) => x.Module.ID === itemClass.ID
    );
    var index = this.state.activeModules.indexOf(item);
    if (item != null) {
      item.Module.IsPin = !item.IsPin;
      item.IsPin = !item.IsPin;
      this.favoriteClick(itemClass, item.IsPin);
    }
    this.state.activeModules.splice(index, 1, item);
  };

  favoriteClick = (obj, checked, e) => {
    ajaxPost({
      url: "api/User/setFavorite",
      data: {
        ModuleViewId: obj.ID,
        IsFavorite: checked,
      },
      onSuccess: (data) => { },
      finally: () => { },
    });

    this.forceUpdate();
  };


  closeMaintenanceBanner = () => {
    this.setState({ hasMaintenanceMsg: false });
  };


  getMaintenanceMessage = () => {
    ajaxPost({
      url: "api/Setting/getMaintenanceMessage",
      credentials: "include",
      data: {
        SessionKey: "HRIS_SessionId",
      },
      onError: () => { },
      onSuccess: (data) => {
        this.setState({
          maintenanceMsg: data,
          hasMaintenanceMsg: data != "NO_MAINTENANCE",
        });
        setTimeout(this.closeMaintenanceBanner, 120000);
      },
      finally: () => {
        this.setState({ validating: false });
      },
    });
    setTimeout(this.getMaintenanceMessage, 120000);
  };

  handleOpen = (e) => {
    this.setState({
      modalOpen: true,
      notificationOpen: false,
      userOpen: false,
    });
    e.stopPropagation();
  };

  handleClose = () => this.setState({ modalOpen: false });

  notificationClick = (e) => {
    var isOpen = this.state.notificationOpen;
    this.setState({
      notificationOpen: !isOpen,
      modalOpen: false,
      userOpen: false,
    });
    e.stopPropagation();
  };

  userClick = (e) => {
    this.setState({
      userOpen: !this.state.userOpen,
      modalOpen: false,
      notificationOpen: false,
    });
    e.stopPropagation();
  };

  onMenuSelect = (itemModule, e, menuItem) => {
    this.setState({ modalOpen: false });
    this.setState({ selectedModule: itemModule });
  };

  onMenuClose = (itemModule, e, menuItem) => {
    var newList = this.state.activeModules.filter(
      (x) => x.Module !== itemModule
    );
    var nofavList = this.state.activeModules.filter(
      (x) => (x.Module !== itemModule) & (x.IsPin == false)
    );

    if (itemModule.IsPin) {
      alert("Cannot unpin pinned tab module");
      return;
    }

    if (
      this.state.selectedModule != null &&
      this.state.selectedModule.Module === itemModule
    ) {
      var index = this.state.activeModules.findIndex((x) => x.Module);
      this.setState({ selectedModule: newList[index] });
    }

    if (nofavList.length === 0) {
      this.setState({ selectedModule: null });
    }

    this.setState({ activeModules: newList });
    e.stopPropagation();
  };

  notificationItemClick = (item, e, menuItem) => {
    this.setState({ notificationOpen: false });
    setTimeout(() => {
      this.FunctionCollection[item.data.CallbackFunction](
        this,
        item.action,
        item.data,
        (msg, actionTaken) => {
          item.data.ActionTaken = actionTaken;
          item.data.IsActionable = false;
        }
      );
    }, 100);
  };

  checkBrowser = () => {
    let isFirefox = typeof InstallTrigger !== "undefined";
    this.setState({ isBrowserRecommended: isFirefox });
  };

  handleViewAllNotif = () => {
    this.setState({ notificationOpen: false });
    this.onModuleSelect(Notifications, null, null);
  };

  onModuleSelect = (
    itemClass,
    e,
    menuItem,
    isPin,
    isFirstLoad,
    startDate,
    endDate,
    replace
  ) => {
    var modules = this.state.activeModules;

    var index = modules.findIndex(x => x.Module.ID === itemClass.getViewID());

    if (isPin == undefined) isPin = false;

    const newModule = () => {
      return {
        Module: {
          ID: itemClass.getViewID(),
          Class: itemClass,
          Icon: ModuleMenu.processIcon(itemClass),
          Caption: processCaption(itemClass),
          IsPin: isPin,
        },
        IsPin: isPin,
        startDate,
        endDate,
        instance: uuidv4()
      };
    }

    var selected = null;

    if (index === -1) {
      var selected = newModule();
      modules.push(selected);
    }
    else {
      selected = modules[index];
    }

    var saveStates = this.state.saveStates;
    if (replace && index !== -1) {
      selected = newModule();
      var id = itemClass.getViewID();
      delete saveStates[id];

      modules.splice(index, 1, selected);
    }

    this.setState({
      activeModules: [...modules],
      selectedModule: selected,
      modalOpen: false,
      saveStates
    });
  };

  onModuleUnmount = (saveState, viewID) => {
    var tmp = this.state.saveStates;
    tmp[viewID] = saveState;
    this.setState({ tmp: saveState });
  };

  onPageClick = () => {
    this.setState({
      modalOpen: false,
      notificationOpen: false,
      userOpen: false,
    });
  };

  handleChangePassword = (e) => {
    setTimeout(() => {
      this.setState({
        external: (
          <ChangePasswordForm
            open={true}
            onSuccess={() => {
              this.setState({
                external: (
                  <MessageBoxOkOnly
                    onClick={() => this.setState({ external: null })}
                    title="Change Password"
                    caption="Your password has been succesfully changed"
                  />
                ),
              });
            }}
            onClose={() => {
              this.setState({ external: null });
            }}
          />
        ),
        userOpen: false,
      });
    }, 100);
  };
  getMaxEmployeeCapacity = () => {
    if (isSubscribed === "YES") {
      return;
    }
    ajaxPost({
      url: "api/License/maxEmployeeCapacity",
      data: {},
      onSuccess: (data) => {
        this.setState({ isMaxEmployeeCapacity: data });
      },
      finally: () => { },
    });
  };

  onCreateEmployeeClick = () => {
    var params = empState;
    this.setState({ params: params });
    this.setState({ loadEmp: true });

    var a = this.state.selectedModule;

    var favs = this.state.activeModules.filter(
      (x) => x.Module.ID !== a.Module.ID
    );

    this.setState({ activeModules: favs, selectedModule: null });
  };

  onNotificationClicked = () => {
    this.setState({ showStatus: !this.state.showStatus })
  }
  onSidePanelBlur = () => {
    setTimeout(() => this.setState({ showStatus: false }), 100);
  }
  onChangeNotificationStatus = (status) => {
    this.setState({ notificationActive: status })
  }

  onExternalChangeModule = (moduleName) => {
    var model = this.state.selectedModule;
    var actives = this.state.activeModules.filter(
      (item) => item.Module.ID != model.Module.ID
    );
    this.setState({ activeModules: actives }, () => {
      switch (moduleName) {
        case "LEAVEPROFILE":
          var module = _adminModules.find(
            (item) => item.ModuleName == "Leave Profile"
          );
          this.onModuleSelect(module.Module, this, this, false, false);
          break;
        case "USERROLE":
          var module = _adminModules.find(
            (item) => item.ModuleName == "User Rights"
          );
          this.onModuleSelect(module.Module, this, this, false, false);
          break;
        case "PAYROLLPROFILE":
          var module = _payrollSettings.find(
            (item) => item.ModuleName == "Payroll Profile"
          );
          this.onModuleSelect(module.Module, this, this, false, false);
          break;
        case "PAYROLLRATEPROFILE":
          var module = _payrollSettings.find(
            (item) => item.ModuleName == "Payroll Rate Profile"
          );
          this.onModuleSelect(module.Module, this, this, false, false);
          break;
        case "ALLOWANCEPROFILE":
          var module = _payrollSettings.find(
            (item) => item.ModuleName == "Allowance Profile"
          );
          this.onModuleSelect(module.Module, this, this, false, false);
          break;
        case "ATCPROFILE":
          var module = _payrollSettings.find(
            (item) => item.ModuleName == "Tax Code Profile"
          );
          this.onModuleSelect(module.Module, this, this, false, false);
          break;
        case "SCHEDPROFILE":
          var module = _adminModules.find(
            (item) => item.ModuleName == "Schedule Profile"
          );
          this.onModuleSelect(module.Module, this, this, false, false);
          break;
      }
    });
  };

  returnData(
    selectedEmployee,
    selectedZKUserInfo,
    selectedPayrollInfo,
    action,
    objState
  ) {
    this.setState({ loadEmp: false });

    switch (action) {
      case "Dashboard":
        if (this.state.showPopUp) {
          this.setState({
            external: (
              <MessageBoxOkOnly
                onClick={() => this.setState({ external: null })}
                title={this.state.popUpTitle}
                caption={this.state.popUpContent}
              />
            ),
          });
        }

        this.setState({ action: "List" });
        this.setState({ selectedModule: null });
        this.setState({ loadEmp: false });
        this.setState({ prevState: undefined });
        break;
      case "LEAVEPROFILE":
        var module = _adminModules.find(
          (item) => item.ModuleName == "Leave Profile"
        );
        this.onModuleSelect(module.Module, this, this, false, false);
        break;
      case "USERROLE":
        var module = _adminModules.find(
          (item) => item.ModuleName == "User Rights"
        );
        this.onModuleSelect(module.Module, this, this, false, false);
        break;
      case "PAYROLLPROFILE":
        var module = _payrollSettings.find(
          (item) => item.ModuleName == "Payroll Profile"
        );
        this.onModuleSelect(module.Module, this, this, false, false);
        break;
      case "PAYROLLRATEPROFILE":
        var module = _payrollSettings.find(
          (item) => item.ModuleName == "Payroll Rate Profile"
        );
        this.onModuleSelect(module.Module, this, this, false, false);
        break;
      case "ALLOWANCEPROFILE":
        var module = _payrollSettings.find(
          (item) => item.ModuleName == "Allowance Profile"
        );
        this.onModuleSelect(module.Module, this, this, false, false);
        break;
      case "ATCPROFILE":
        var module = _payrollSettings.find(
          (item) => item.ModuleName == "Tax Code Profile"
        );
        this.onModuleSelect(module.Module, this, this, false, false);
      case "SCHEDULEPROFILE":
        var module = _adminModules.find(
          (item) => item.ModuleName == "Schedule Profile"
        );
        this.onModuleSelect(module.Module, this, this, false, false);
        break;
    }
  }

  render() {
    const { styles } = this;
    var modules = [];

    this.modulesList.forEach((y) => {
      var grp = {
        GroupName: y.GroupName,
        Modules: [],
      };

      y.Modules.forEach((x) => {
        var add = true;
        if (
          "getViewAccessKey" in x.Module &&
          x.Module.getViewAccessKey() === false
        )
          add = false;

        if (add) grp.Modules.push(x);
      });

      if (grp.Modules.length > 0) modules.push(grp);
    });

    var dic = {};

    dic["Employees"] = [];
    _employeeModules.forEach((i) => {
      var add = true;
      if (
        "getViewAccessKey" in i.Module &&
        i.Module.getViewAccessKey() === false
      )
        add = false;

      if (add) dic["Employees"].push(i);
    });

    dic["Payroll"] = [];
    _payrollModules.forEach((i) => {
      var add = true;
      if (
        "getViewAccessKey" in i.Module &&
        i.Module.getViewAccessKey() === false
      )
        add = false;

      if (add) dic["Payroll"].push(i);
    });

    dic["PayrollTransaction"] = [];
    _payrollTransactions.forEach((i) => {
      var add = true;
      if (
        "getViewAccessKey" in i.Module &&
        i.Module.getViewAccessKey() === false
      )
        add = false;

      if (add) dic["PayrollTransaction"].push(i);
    });

    dic["PayrollSetting"] = [];
    _payrollSettings.forEach((i) => {
      var add = true;
      if (
        "getViewAccessKey" in i.Module &&
        i.Module.getViewAccessKey() === false
      )
        add = false;

      if (add) dic["PayrollSetting"].push(i);
    });

    dic["Transaction"] = [];
    _transactions.forEach((i) => {
      var add = true;
      if (
        "getViewAccessKey" in i.Module &&
        i.Module.getViewAccessKey() === false
      )
        add = false;

      if (add) dic["Transaction"].push(i);
    });


    dic["Device"] = [];
    _deviceModules.forEach((i) => {
      var add = true;
      if (
        "getViewAccessKey" in i.Module &&
        i.Module.getViewAccessKey() === false
      )
        add = false;

      if (add) dic["Device"].push(i);
    });

    dic["Report"] = [];
    _reportModules.forEach((i) => {
      var add = true;
      if (
        "getViewAccessKey" in i.Module &&
        i.Module.getViewAccessKey() === false
      )
        add = false;

      if (add) dic["Report"].push(i);
    });

    dic["Admin"] = [];
    _adminModules.forEach((i) => {
      var add = true;
      if (
        "getViewAccessKey" in i.Module &&
        i.Module.getViewAccessKey() === false
      )
        add = false;

      if (add) dic["Admin"].push(i);
    });

    var createModuleInstance = (itemModule, startDate, endDate) => {
      if (itemModule == null) return null;

      return React.createElement(
        itemModule,
        {
          moduleSelectCallback: this.onExternalChangeModule.bind(this),
          createEmpCallback: this.onCreateEmployeeClick,
          unmountCallback: this.onModuleUnmount,
          prevState:
            this.state.params !== null &&
              this.state.params.referer === "Dashboard"
              ? undefined
              : this.state.saveStates[itemModule.getViewID()],
          parent: this,
          startDate: startDate,
          endDate: endDate,
        },
        null
      );
    };

    return (
      <>
        <div>{this.state.external}</div>

        <div className={modStyle.main}>
          <Menu
            borderless
            inverted
            pointing
            style={styles.topMenu}
          >
            <Menu.Item header as="a">
              <Image
                size="mini"
                src={global.light_logo_url}
                style={{ width: "120px", height: "40px" }}
              />
            </Menu.Item>
            <Menu.Item
              as="a"
              onClick={() => {
                this.setState({ loadEmp: false });
                this.setState({ selectedModule: null });
              }}
            >
              Dashboard
            </Menu.Item>
            {dic["Employees"].length !== 0 && (
              <Dropdown text="Employees" closeOnBlur item>
                <Dropdown.Menu>
                  {dic["Employees"].map((itemModule) => {
                    return (
                      <Dropdown.Item
                        key={`${itemModule.ModuleName}`}
                        onClick={this.onModuleSelect.bind(
                          this,
                          itemModule.Module
                        )}
                      >
                        {itemModule.ModuleName}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )}

            {(dic["Payroll"].length !== 0 ||
              dic["PayrollSetting"].length !== 0 ||
              dic["PayrollTransaction"].length !== 0) && (
                <Dropdown text="Payroll" item closeOnBlur>
                  <Dropdown.Menu>
                    {dic["Payroll"].map((itemModule) => {
                      return (
                        <Dropdown.Item
                          key={`${itemModule.ModuleName}`}
                          onClick={this.onModuleSelect.bind(
                            this,
                            itemModule.Module
                          )}
                        >
                          {itemModule.ModuleName}
                        </Dropdown.Item>
                      );
                    })}
                    {dic["PayrollTransaction"].length != 0 && (
                      <Dropdown item text="Transactions"  >
                        <Dropdown.Menu>
                          {dic["PayrollTransaction"].map((itemModule) => {
                            return (
                              <Dropdown.Item
                                key={`${itemModule.ModuleName}`}
                                onClick={this.onModuleSelect.bind(
                                  this,
                                  itemModule.Module
                                )}
                              >
                                {itemModule.ModuleName}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                    {dic["PayrollSetting"].length !== 0 && (
                      <Dropdown item text="Settings" >
                        <Dropdown.Menu>
                          {dic["PayrollSetting"].map((itemModule) => {
                            return (
                              <Dropdown.Item
                                key={`${itemModule.ModuleName}`}
                                onClick={this.onModuleSelect.bind(
                                  this,
                                  itemModule.Module
                                )}
                              >
                                {itemModule.ModuleName}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}

            {dic["Transaction"].length !== 0 && (
              <Dropdown text="Transactions" item closeOnBlur >
                <Dropdown.Menu>
                  {dic["Transaction"].map((itemModule) => {
                    return (
                      <Dropdown.Item
                        key={`${itemModule.ModuleName}`}
                        onClick={this.onModuleSelect.bind(
                          this,
                          itemModule.Module
                        )}
                      >
                        {itemModule.ModuleName}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )}

            {dic["Device"].length !== 0 && (
              <Dropdown text="Device Management" item closeOnBlur>
                <Dropdown.Menu>
                  {dic["Device"].map((itemModule) => {
                    return (
                      <Dropdown.Item
                        key={`${itemModule.ModuleName}`}
                        onClick={this.onModuleSelect.bind(
                          this,
                          itemModule.Module
                        )}
                      >
                        {itemModule.ModuleName}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )}

            {dic["Report"].length !== 0 && (
              <Dropdown text="Report" item closeOnBlur>
                <Dropdown.Menu>
                  {dic["Report"].map((itemModule) => {
                    return (
                      <Dropdown.Item
                        key={`${itemModule.ModuleName}`}
                        onClick={this.onModuleSelect.bind(
                          this,
                          itemModule.Module
                        )}
                      >
                        {itemModule.ModuleName}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )}
            {dic["Admin"].length !== 0 && (
              <Dropdown text="Administration" item closeOnBlur>
                <Dropdown.Menu>
                  {dic["Admin"].map((itemModule) => {
                    return (
                      <Dropdown.Item
                        key={`${itemModule.ModuleName}`}
                        onClick={this.onModuleSelect.bind(
                          this,
                          itemModule.Module
                        )}
                      >
                        {itemModule.ModuleName}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )}
            <Menu.Menu position="right">
              <Popup
                position="bottom right"
                trigger={<NotificationBell
                  onClick={this.notificationClick.bind(this)}
                />
                }
              >
                <NotificationPreview
                  notificationItemClick={this.notificationItemClick}
                  parent={this}
                  handleViewAllNotif={this.handleViewAllNotif.bind(this)}
                />
              </Popup>
              <div tabIndex="0" onClick={this.onNotificationClicked} className={modStyle.notificationButton} data-active={this.state.showStatus ? "true" : "false"} >
                <Icon name={this.state.notificationActive ? "redo" : "sitemap"} loading={this.state.notificationActive} inverted={!this.state.showStatus} />
              </div>
              {/*<Button circular icon="redo alternate" inverted size="large" style={{boxShadow:"0px 0px !important"}} id={modStyle.statusIcon} />*/}
              <Dropdown className={modStyle.menuUser} text={this.state.userName} item icon="user" onClick={this.userClick.bind(this)}>
                <Dropdown.Menu>
                  {global.userId !== empty_id && (
                    <Dropdown.Item
                      text="Change Password"
                      as="a"
                      icon="asterisk"
                      onClick={this.handleChangePassword.bind(this)}
                    />
                  )}
                  <Dropdown.Item
                    text="About"
                    as="a"
                    icon="info"
                    onClick={() => {
                      this.setState({
                        showAboutDialog: !this.state.showAboutDialog,
                      });
                    }}
                  ></Dropdown.Item>
                  <Dropdown.Item
                    text="What's New?"
                    as="a"
                    icon="question"
                    onClick={() => {
                      window.open("https://zkph.canny.io/changelog");
                    }}
                  ></Dropdown.Item>
                  <Dropdown.Item
                    text="Download Remote Clock"
                    as="a"
                    onClick={() => { this.setState({ showRemoteClockDownload: !this.state.showRemoteClockDownload }) }}
                    icon="r circle"
                  >
                  </Dropdown.Item>
                  <Dropdown.Item
                    text="Sentry API Integration"
                    as="a"
                    onClick={() => { this.setState({ showAPIIntegration: !this.state.showAPIIntegration }) }}
                    icon="adn"
                  >
                  </Dropdown.Item>
                  <Dropdown.Item
                    text="Help"
                    as="a"
                    icon="life ring outline"
                    onClick={() => {
                      window.open("https://zkteco.ph/Support");
                    }}>
                  </Dropdown.Item>
                  <Dropdown.Item
                    text="Logout"
                    as="a"
                    icon="user"
                    onClick={() => {
                      window.location = "logout";
                    }}
                  />
                </Dropdown.Menu>
              </Dropdown>

            </Menu.Menu>
          </Menu>

          <div>
            <Menu inverted vertical className={modStyle.sideMenu}>
              {this.state.activeModules.map((itemModule) => {
                return (
                  <Menu.Item
                    as="a"
                    onClick={this.onMenuSelect.bind(this, itemModule)}
                    active={itemModule.Module === this.state.selectedModule}
                    className={modStyle.menuItem}
                  >
                    <table>
                      <tr>
                        <td style={{ "min-width": "24px" }}>
                          {itemModule.Module.Icon}
                        </td>
                        <td>
                          <div style={{ width: "150px", overflow: "hidden" }}>
                            {itemModule.Module.Caption}
                          </div>
                        </td>
                        <td style={{ width: "10px" }}>
                          <Icon
                            name="pin"
                            color={itemModule.IsPin === true && "green"}
                            size="small"
                            onClick={this.handlePinChange.bind(
                              this,
                              itemModule.Module
                            )}
                          />
                        </td>
                        {!itemModule.IsPin && (
                          <td style={{ width: "10px" }}>
                            <Icon
                              name="close"
                              size="small"
                              className="closeModule"
                              onClick={this.onMenuClose.bind(
                                this,
                                itemModule.Module
                              )}
                            />
                          </td>
                        )}
                      </tr>
                    </table>
                  </Menu.Item>
                );
              })}
            </Menu>

            <div style={styles.container}>
              {this.state.selectedModule != null &&
                this.state.selectedModule.Module != null ? (
                createModuleInstance(
                  this.state.selectedModule.Module.Class,
                  this.state.selectedModule.startDate,
                  this.state.selectedModule.endDate
                )
              ) : this.state.loadEmp ? (
                <EmployeeLoad
                  params={this.state.params}
                  callback={this}
                  unmountCallback={this.onModuleUnmount}
                ></EmployeeLoad>
              ) : (
                <Dashboard
                  modulesList={modules}
                  onModuleSelect={this.onModuleSelect}
                  OnUpdateEmpClick={this.handleUpdateEmployeeClick}
                />
              )}
            </div>
          </div>
          {this.state.showAboutDialog && (
            <AboutDialog
              onClick={() => {
                this.setState({ showAboutDialog: !this.state.showAboutDialog });
              }}
              onUpgrade={this.openLicensingPage}
            ></AboutDialog>
          )}
          {this.state.showRemoteClockDownload && (
            <RemoteClockDialog
              onClick={() => {
                this.setState({ showRemoteClockDownload: !this.state.showRemoteClockDownload });
              }}>
            </RemoteClockDialog>
          )}
          {this.state.showWhatsNew && (
            <WhatsNewDialog
              onClick={() => {
                this.setState({ showWhatsNew: false });
              }}
            />
          )}
          {this.state.showAPIIntegration && (
            <SentryIntegration
              onClick={() => {
                this.setState({ showAPIIntegration: false });
              }}
            />
          )}

        </div>
        <StatusPanel
          visible={this.state.showStatus}
          onBlur={this.onSidePanelBlur}
          onChangeNotificationStatus={this.onChangeNotificationStatus}
        />
      </>
    );
  }
}

export default MasterPage3;
