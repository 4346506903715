import React, { Component } from 'react';
import { Popup, Image, Input, Button, Icon, Dropdown, Modal, Container } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import './MasterPageControls.css';
import { ajaxPost } from '../ajax';
import { isTaskAvailable } from "../utils";
import EmployeeImage from "../Commons/EmployeeImage";

var moment = require('moment');

class NotificationPopupControl extends Component {

    markAsRead = (x, e) => {
        x.ActionTaken = "READ";
        this.forceUpdate();
        ajaxPost(
            {
                url: "api/Notification/markAsRead",
                data: x._id,
                onSuccess: data => { },
                finally: () => { }
            }
        )
        e.stopPropagation();
    }

    prepareActionField = (x) => {
        if (x.data.ActionTaken === "READ")
            return null;

        if (x.data.IsActionable) {
            return <Dropdown inline >
                <Dropdown.Menu>
                    {x.data.ActionSelection.map(y =>
                        <Dropdown.Item disabled={!isTaskAvailable(y.AccessRight)} onClick={this.props.notificationItemClick.bind(this.props.parent, { data: x.data, action: y })}>{y.Action}</Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        }
        else {
            if (x.data.ActionTaken === "NONE")
                return (
                    <Popup
                        trigger={<Icon name="check circle outline" onClick={this.markAsRead.bind(this, x.data)} />}
                    >
                        Mark as read
                </Popup>)
            else
                return <div style={{ "font-size": "9pt", "margin": "0px 0px 0px 12px" }}>{x.data.ActionTaken}</div>
        }
    }

    render = () => {
        var notifications = this.props.notifications;

        if (notifications.length > 0) {
            return (
                <div style={{ borderRadius: '25px', width: '500px'}}>
                    {
                        notifications.map(x => {
                            return (
                                <div className={(x.data.ActionTaken === "NONE" ? "NotificationPopControl_unread" : "NotificationPopControl_row")}
                                    onClick={this.props.notificationItemClick.bind(this.props.parent, { data: x.data, action: "View" })}>
                                    <div className="NotificationPopControl_avatar NotificationPopControl_cell">
                                        <EmployeeImage
                                            empId={x.data.RequesterID}
                                            fetchFileName={true}
                                            avatar
                                        />
                                    </div>
                                    <div className="NotificationPopControl_desc NotificationPopControl_cell">
                                        <div style={{ fontSize: '14px' }}>{x.data.Description}</div>
                                        <div style={{ fontSize: '10px' }}>{moment(x.data.DateCreated).fromNow()}
                                        </div>
                                    </div>
                                    <div className="NotificationPopControl_cell">
                                        {this.prepareActionField(x)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
        else if (this.props.loading !== true) {
            return (
                <p>There are no notifications yet</p>
            );
        }
        else {
            return <p></p>
        }

    }
}


class ChangePasswordForm extends Component {

    constructor(props) {
        super(props);

        this.state =
        {
            password: "",
            newPassword: "",
            confirmPassword: "",
            errorMessage: "",
            saving: false,
            open: true,
            showPassword: false,
            showNewPassword: false,
            showConfirmPassword: false
        }
    }
    /*
        componentWillReceiveProps = () =>
        {
            this.state.open = this.props.open;
        }
    */

    handleChange = (id, e, input) => {
        this.setState({ [id]: input.value });
    }

    handleClick = () => {
        const capsCharacter = "QWERTYUIOPASDFGHJKLZXCVBNM";
        const regCharacter = "qwertyuiopasdfghjklzxcvbnm";
        //const spclCharacter = "!@#$%^&*()_+-={}[]:;\"\'\\<>,./?|`~";
        const numeric = "1234567890";

        this.setState({ errorMessage: "" });
        if (this.state.newPassword !== this.state.confirmPassword) {
            this.setState({ errorMessage: "Entered passwords did not match" });
            return;
        }

        if (this.state.newPassword.length < 8) {
            this.setState({ errorMessage: "Password must contains atleast 8 characters" });
            return;
        }

        var hasCaps = false;
        var hasRegChar = false;
        //var hasSpclChar = false;
        var hasNumeric = false;

        for (let index = 0; index < this.state.newPassword.length; index++) {
            const element = this.state.newPassword.charAt(index);        
            if (capsCharacter.includes(element))
                hasCaps = true;
            if (regCharacter.includes(element))
                hasRegChar = true;
            // if(spclCharacter.search(element) !== -1)
            //     hasSpclChar = true;
            if (numeric.includes(element))
                hasNumeric = true;
        }

        if (!hasCaps || !hasNumeric || !hasRegChar) {
            this.setState({ errorMessage: "The password must contains small and capital letter and number" });
            return;
        }

        ajaxPost(
            {
                url: "api/Employee/changePassword",
                data:
                {
                    userId: global.userId,
                    oldPassword: this.state.password,
                    newPassword: this.state.newPassword,
                },
                onSuccess: data => {
                    if (data.result === false) {
                        this.setState({ errorMessage: data.errMessage });
                    }
                    else {
                        this.props.onSuccess();
                    }
                    this.setState({ saving: false });
                },
                finally: () => { }
            }
        )

    }

    handleClose = () => {
        this.props.onClose();
    }

    render = () => {
        return <Modal open={this.state.open} onClose={this.handleClose.bind(this)} closeIcon={true} size="tiny" closeOnDimmerClick={false} closeOnEscape={false}>
            <Modal.Header>
                Change Password
            </Modal.Header>
            <Modal.Content>
                <div>
                    <div>
                        <div className="ChangePasswordFormGroupLabel">Current Password</div>
                        <Input type={this.state.showPassword ? "text" : "password"} value={this.state.password} style={{ minWidth: '50px', maxWidth: '300px', width: '100%' }}
                            onChange={this.handleChange.bind(this, "password")}
                            icon={<Icon name='eye' onMouseUp={() => {
                                this.setState({ showPassword: false })
                            }}
                                onMouseDown={() => {
                                    this.setState({ showPassword: true })
                                }}
                                link
                            />}
                        />
                    </div>
                    <br />
                    <div style={{ padding: '0,10,0,0' }}>
                        <div className="ChangePasswordFormGroupLabel">New Password</div>
                        <Input type={this.state.showNewPassword ? "text" : "password"} value={this.state.newPassword} style={{ minWidth: '50px', maxWidth: '300px', width: '100%' }}
                            onChange={this.handleChange.bind(this, "newPassword")}
                            icon={<Icon name='eye' onMouseUp={() => {
                                this.setState({ showNewPassword: false })
                            }}
                                onMouseDown={() => {
                                    this.setState({ showNewPassword: true })
                                }}
                                link
                            />}
                        />
                    </div>
                    <br />
                    <div style={{ padding: '0,10,0,0' }}>
                        <div className="ChangePasswordFormGroupLabel">Confirm Password</div>
                        <Input type={this.state.showConfirmPassword ? "text" : "password"} value={this.state.confirmPassword} style={{ minWidth: '50px', maxWidth: '300px', width: '100%' }}
                            onChange={this.handleChange.bind(this, "confirmPassword")}
                            icon={<Icon name='eye' onMouseUp={() => {
                                this.setState({ showConfirmPassword: false })
                            }}
                                onMouseDown={() => {
                                    this.setState({ showConfirmPassword: true })
                                }}
                                link
                            />}
                        />
                    </div>
                    <br />
                    <div style={{ padding: '0,10,0,0' }}>
                        <div style={{ color: "red" }}>{this.state.errorMessage}</div>
                    </div>
                </div>

                <div style={{ fontWeight: "lighter", fontSize: "12px", verticalAlign: "bottom", padding: "0,0,0,0" }}> Note: </div>
                <div style={{ fontWeight: "lighter", fontSize: "12px", verticalAlign: "bottom", padding: "0,0,0,0" }}> &nbsp; -Password must contain atleast 1 of the ff.(lowercase, uppercase, number)</div>
                <div style={{ fontWeight: "lighter", fontSize: "12px", verticalAlign: "bottom", padding: "0,0,0,0" }}> &nbsp; -Password have atleast 8 charactes</div>

            </Modal.Content>
            <Modal.Actions>
                <Button primary loading={this.state.saving} onClick={this.handleClick.bind(this)} >
                    Proceed <Icon name='chevron right' />
                </Button>
            </Modal.Actions>
        </Modal>
    }

}

export var processCaption = (param) => {
    var obj = param;
    if ("type" in param)
        obj = param.type;

    if ("getCaption" in obj) {
        return obj.getCaption();
    }
    else {
        if ("getViewID" in obj)
            return obj.getViewID()
        else
            return "NO CAPTION";
    }
}



class ModuleMenu extends Component {
    favoriteClick = (obj, checked, e) => {
        ajaxPost(
            {
                url: "api/User/setFavorite",
                data:
                {
                    ModuleViewId: obj.Module.getViewID(),
                    IsFavorite: checked,
                },
                onSuccess: data => { },
                finally: () => { }
            }
        );

        obj.IsFavorite = checked;
        this.forceUpdate();
        e.stopPropagation();
    }


    render = () => {
        return (
            <Popup
                open={true}
                position="top left"
                className="ModuleMenu_popup"
                basic
                style={{ position: 'fixed', left: this.props.position === undefined ? '150px' : this.props.position }}
            >
                <div className="ModuleMenu_viewBox">
                    <div className="ModuleMenu_menu">
                        {(this.props.showClose === undefined ? false : this.props.showClose) &&
                            <a style={{ float: 'right' }} href="#">Close</a>
                        }
                        {
                            this.props.modules.map(item => {
                                return (
                                    <div>
                                        <h5 className="ModuleMenu_header">{item.GroupName}</h5>{
                                            item.Modules.map(x => {
                                                let itemClass = x.Module;
                                                return (
                                                    <div className="ModuleMenu_row">
                                                        <div className="ModuleMenu_cell ModuleMenu_icon"
                                                            onClick={this.props.onModuleSelect.bind(this, itemClass)}
                                                        >
                                                            {ModuleMenu.processIcon(itemClass)}
                                                        </div>
                                                        <div className="ModuleMenu_cell ModuleMenu_desc"
                                                            onClick={this.props.onModuleSelect.bind(this, itemClass)}
                                                        >
                                                            {processCaption(itemClass)}
                                                        </div>
                                                        <div className="ModuleMenu_cell ModuleMenu_fav">
                                                            {x.IsFavorite && <Icon name="star" color="orange"
                                                                onClick={this.favoriteClick.bind(this, x, false)}
                                                            />
                                                            }
                                                            {!x.IsFavorite && <Icon name="star outline" color="grey"
                                                                className="ModuleMenu_starOutline"
                                                                onClick={this.favoriteClick.bind(this, x, true)}
                                                            />
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </Popup>
        )
    }
}

ModuleMenu.processIcon = (param) => {
    var obj = param;
    if ("type" in param)
        obj = param.type;

    if ("getIcon" in obj)
        return obj.getIcon();
    else
        return <Image style={{ "width": "24px", "height": "24px" }} src='/Icons/modules.png' avatar />
}

export var processIconSrc = (param, size) => {
    var obj = param;
    if ("type" in param)
        obj = param.type;

    if ("getIconSrc" in obj)
        return obj.getIconSrc(size);
    else
        return '/Icons/modules.png';

}



export {
    NotificationPopupControl,
    ChangePasswordForm,
    ModuleMenu,
}