import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';
import PayrollProfileList from './PayrollProfileList';
import PayrollProfileForm from './PayrollProfileForm';
import { isTaskAvailable } from '../../utils';

class PayrollProfileModule extends Component {
    state = {
        mode: "search",
        searchState: {
            initialLoad: true,
            selectedItem: null,
            list: [],
            search: '',
            page: 1,
            rowPerPage: 10,
            totalPages: 1,
            total: 0,
        },
        selectedItem: null
    }
    componentWillMount() {
        if (this.props.prevState != null) {
            this.state = this.props.prevState;
            this.state.searchState.initialLoad = false;
        }

    }
    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, "PayrollProfiles");
    }
    onUpdateState = (searchState) => {
        this.setState({ searchState: searchState });
    }
    onSelect = (item) => {
        this.setState({ selectedItem: item });
    }
    onAdd = (searchState) => {
        //set blank model for selectedItem
        this.setState({ selectedItem: null });
        this.setState({ searchState });
        this.setState({ mode: "add" });
    }
    onEdit = (searchState, selectedSchedule) => {
        this.setState({ searchState: searchState });
        this.setState({ selectedItem: selectedSchedule });
        this.setState({ mode: "edit" });
    }
    onCloseForm = (schedule) => {
        this.setState({ mode: "search" });
    }
    onUpdateComplete = (model) => {
        var list = this.state.searchState.list;
        var searchState = this.state.searchState;

        if (this.state.mode === "add") {
            if (list.length > 0) {
                list.push(model);
                searchState.list = list;
            }
            else {
                searchState.list = [model];
            }
            searchState.selectedItem = model;
            searchState.page = 1;
        } else {
            var itemIndex = list.indexOf(this.state.selectedItem);
            if (itemIndex >= 0)
                list[itemIndex] = model;
        }
        this.setState({ searchState: this.state.searchState });
        this.setState({ mode: "search" });
    }
    render() {
        return this.state.mode === "search" ?
            <PayrollProfileList
                searchState={this.state.searchState}
                onSelect={this.onSelect.bind(this)}
                onAdd={this.onAdd.bind(this)}
                onEdit={this.onEdit.bind(this)}
                onUpdateState={this.onUpdateState.bind(this)} />
            :
            <PayrollProfileForm selectedItem={JSON.parse(JSON.stringify(this.state.selectedItem))}
                onCloseForm={this.onCloseForm.bind(this)}
                onUpdateComplete={this.onUpdateComplete.bind(this)} />
    }
}
PayrollProfileModule.getViewID = () => { return "PayrollProfiles" };
PayrollProfileModule.getCaption = () => { return "Payroll Profile" }
PayrollProfileModule.getViewAccessKey = () => {
    //for payroll only
    return isTaskAvailable("PAYROLL_PROFILE_VIEW") || isTaskAvailable("PAYROLL_PROFILE_CREATE")
        || isTaskAvailable("PAYROLL_PROFILE_MODIFY") || isTaskAvailable("PAYROLL_PROFILE_DELETE")
        ? true : false
}
PayrollProfileModule.getIcon = () => {
    return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Payroll_Profiles.png' avatar />);
}
PayrollProfileModule.getIconSrc = (size) => //size values: small (50x50), medium(150x150), large(300x300)
{
    return '/Icons/Payroll_Profiles.png'
}
export default PayrollProfileModule