import React, { Component } from 'react';
import { Image, Button } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import TransactionModuleBase from './TransactionModuleBase2';
import { ajaxPost, authPost } from '../../ajax';
import ViewNotificationDetails from './Commons/ViewNotificationDetails';
import OfficialBusinessForm from './Forms/OfficialBusinessForm';
import { isTaskAvailable, checkDateRange, checkTime, checkInt32, momentDateFormat, fnAsyncSearchEmployee } from '../../utils';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';
import { SelectColumn } from 'react-data-grid';

const ViewID = "OfficialBusiness";
const ViewCaption = "Official Business";

const moment = require('moment');
const updateStatus = "api/OfficialBusiness/updateStatus";
// const { Editors: { DropDownEditor } } = require('react-data-grid');
const applicationStatuses = [
	'PENDING',
	'APPROVED',
	'CANCELLED',
	'REJECTED'
];
const userRightsKeys = {
	view: "OFFICIAL_BUSINESS_VIEW",
	create: "OFFICIAL_BUSINESS_CREATE",
	edit: "OFFICIAL_BUSINESS_EDIT",
	delete: "OFFICIAL_BUSINESS_DELETE",
	restore: "OFFICIAL_BUSINESS_RESTORE",
	approve: "OFFICIAL_BUSINESS_APPROVE",
	reject: "OFFICIAL_BUSINESS_REJECT",
	pend: "OFFICIAL_BUSINESS_PEND",
	cancel: "OFFICIAL_BUSINESS_CANCEL",
	export: "OFFICIAL_BUSINESS_EXPORT_TO_EXCEL",
	import: "OFFICIAL_BUSINESS_IMPORT"
}

class OfficialBusinessList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			viewType: "hr",
			modalVisible: false,
			fromDate: moment().format(props.startDate),
			toDate: moment().format(props.endDate),
		}
		this.modalRef = React.createRef();
		this.parentRef = React.createRef();
	}

	createUrls = () => {
		return {
			getStatusCounts: "api/OfficialBusiness/getStatusCounts",
			search: "api/OfficialBusiness/search",
			setDeleted: "api/OfficialBusiness/setDeleted",
			saveMultiple: "api/OfficialBusiness/saveMultiple",
			load: "api/OfficialBusiness/load",
			searchEmployees: "api/OfficialBusiness/searchEmployees",
			updateStatus: updateStatus,
			exportToExcel: "api/OfficialBusiness/exportToExcel",
			downloadTemplate: "api/OfficialBusiness/downloadTemplate",
			importFromExcel: "api/OfficialBusiness/importFromExcel"
		};
	}

	toRow = (model) => {
		if( model.hasOwnProperty("id")){
			const sTime = moment(model.startTime, "HH:mm")
			const eTime = moment(model.startTime, "HH:mm").add(model.minutes + model.minutesBreak, 'minute')
			return {
				id: model.id,
				employeeId: model.employeeId,
				deleted: model.isDeleted === null ? false : model.isDeleted,
				employee: model.employee,
				startDate: moment(model.startDate).format(momentDateFormat),
				endDate: moment(model.endDate).format(momentDateFormat),
				startTime: sTime.format("hh:mm A") + (model.nextDay ? " Nextday"  : ""),
				endTime: eTime.format("hh:mm A") + (model.nextDay || sTime.format("DD") < eTime.format("DD")? " Nextday": ""),
				hours: (model.minutes / 60).toFixed(2),
				hoursBreak: (model.minutesBreak / 60).toFixed(2),
				location: model.location,
				purpose: model.purpose,
				status: model.status,
				remarks: model.remarks,
				approver: model.approver,
				approverId: model.approverId,
				approverRemarks: model.approverRemarks,
				changeStatusDate: !model.changeStatusDate ? "" : moment(model.changeStatusDate).format(momentDateFormat),
				requestDate: moment(model.requestDate).format(momentDateFormat),
				file:model.AttachmentDetail != null ? model.AttachmentDetail.File : "",
				actions:model.AttachmentDetail != null && model.AttachmentDetail.File != "" && model.AttachmentDetail.File != null ?                                               
					<div style={{display:'flex', width:'100%', height:'100%', justifyContent:'center', alignItems:'center' }}>
						<Button style={{fontSize: '0.8rem'}}
							icon="download"
							circular
							onClick={this.downloadDoc.bind(
							this,
							model.AttachmentDetail.UniqueFilename,
							model.AttachmentDetail.File
							)}	
						/> 
					</div>: "",
				AttachmentDetail: model.AttachmentDetail,
				editable: false,
				idle: false
			};
		}

		const dtStart = moment(model.StartTime)
		const dtEnd = moment(model.StartTime).add(model.Minutes + model.MinutesBreak, 'minute')
		var output = {
			id: model._id,
			employeeId: model.EmployeeId,
			deleted: model.IsDeleted === null ? false : model.IsDeleted,
			employee: model.Employee,
			startDate: moment(model.StartDate).format(momentDateFormat),
			endDate: moment(model.EndDate).format(momentDateFormat),
			startTime: dtStart.format("hh:mm A") + (model.NextDay ? " Nextday"  : ""),
			endTime: dtEnd.format("hh:mm A") + (model.NextDay || dtStart.format("DD") < dtEnd.format("DD")? " Nextday": ""),
			hours: (model.Minutes / 60).toFixed(2),
			hoursBreak: (model.MinutesBreak / 60).toFixed(2),
			location: model.Location,
			purpose: model.Purpose,
			status: model.Status,
			remarks: model.Remarks,
			approver: model.Approver,
			approverId: model.ApproverId,
			approverRemarks: model.ApproverRemarks,
			changeStatusDate: !model.ChangeStatusDate ? "" : moment(model.ChangeStatusDate).format(momentDateFormat),
			requestDate: moment(model.RequestDate).format(momentDateFormat),
			file:model.AttachmentDetail != null ? model.AttachmentDetail.File : "",
			actions:model.AttachmentDetail != null && model.AttachmentDetail.File != "" && model.AttachmentDetail.File != null  ?                                               
					<div style={{display:'flex', width:'100%', height:'100%', justifyContent:'center', alignItems:'center' }}>
						<Button style={{fontSize: '0.8rem'}}
							icon="download"
							circular
							onClick={this.downloadDoc.bind(
							this,
							model.AttachmentDetail.UniqueFilename,
							model.AttachmentDetail.File
							)}	
						/> 
					</div>: "",
			AttachmentDetail: model.AttachmentDetail,
			editable: false,
			idle: false
			//onEdit: model.Status === "PENDING"
		};

		return output;
	};

	toModel = (row) => {
		let minutes = row.hours * 60;
		minutes -= minutes % 1;
		let minutesBreak = row.hoursBreak * 60;
		minutesBreak -= minutesBreak % 1;
		return {
			_id: row.hasOwnProperty("_id") ? row._id : row.id,
			EmployeeId: row.employeeId,
			StartDate: moment(row.startDate).toJSON(),
			EndDate: moment(row.endDate).toJSON(),
			StartTime: moment(moment(row.endDate).format("YYYY-MM-DD") + " " + row.startTime).toJSON(),
			Minutes: minutes,
			MinutesBreak: minutesBreak,
			Location: row.location,
			Purpose: row.purpose,
			Status: row.status,
			Remarks: row.remarks,
			File: row.file,
			AttachmentDetail: row.AttachmentDetail
		};
	};

	createColumns = () => {
		let self = this;
		let hasHrAccess = isTaskAvailable("HR_ROLE");
		let columns = [
			SelectColumn,
			{ key: 'startDate', name: 'Start Date', width: 90, resizable: true},
			{ key: 'endDate', name: 'End Date', width: 90, resizable: true},
			{ key: 'startTime', name: 'Start Time', width: 140, resizable: true},
			{ key: 'endTime', name: 'End Time', width: 140, resizable: true},
			{ key: 'hoursBreak', name: 'Break Hrs.', width: 80, resizable: true},
			{ key: 'location', name: 'Location', width: 120, resizable: true},
			{ key: 'purpose', name: 'Purpose', width: 120, resizable: true},
			{ key: 'status', name: 'Status', width: 90, resizable: true},
			{ key: 'remarks', name: 'Reasons', width: 150, resizable: true},
			{ key: 'changeStatusDate', name: 'Change Status Date', width: 130, resizable: true },
			{ key: 'approver', name: 'Approver', width: 220, resizable: true },
			{ key: 'approverRemarks', name: 'Approver Remarks', width: 150, resizable: true },
			{ key: 'requestDate', name: 'Date Filed', width: 90, resizable: true },
			{ key: 'file', name: 'File', width: 100, resizable: true },
			{ key: 'actions', name: 'Actions', width: 90, resizable: true },
		];
		// if (viewType === "hr") {
		columns.unshift({
			key: 'employee', name: 'Employee', width: 150, resizable: true
		});
		// }
		return columns;
	}

	checkNoDuplicates = async  (data) => {
		var response = await authPost( '/OfficialBusiness/CheckDuplicates', data);
		var result = await response.json();

		if( result.length == 0 ){
			return true;
		}

		var employees = await fnAsyncSearchEmployee( 
			"", 
			0,
			2147483647,
			false,
			null,
			result
		);

		var empString = employees.map( x=> `${x.LastName}, ${x.FirstName}`)

		this.parentRef.current.messageBoxOkOnlyOpen(true, "Duplicate Entries", 
			"Cannot proceed because this action will cause duplicate entries for\n" + empString.join('\n'));

		return false;
	}


	validate = async (data) => {
		var errTitle = "", errCaption = "";
		var checkDateParam = checkDateRange(data.startDate, data.endDate, data.nextDay, data.endNextDay);
		var checkStartTimeParam = checkTime(data.startTime);
		var checkEndTimeParam = checkTime(data.endTime);
		
		var checkInt32MinutesBreakParam = checkInt32(data.minutesBreak, false);
		if (this.isNullOrEmpty(data.employeeIds)
			|| this.isNullOrEmpty(data.location)
			|| this.isNullOrEmpty(data.purpose)
			|| this.isNullOrEmpty(data.remarks)
		) {
			errCaption = "Please fill up all required field/s";
			errTitle = "Invalid";
		}
		else if (!checkDateParam.Result) {
			errCaption = checkDateParam.Message;
			errTitle = checkDateParam.Title;
		}
		else if (!checkStartTimeParam.Result) {
			errCaption = checkStartTimeParam.Message;
			errTitle = checkStartTimeParam.Title;
		}
		else if (!checkEndTimeParam.Result) {
			errCaption = checkEndTimeParam.Message;
			errTitle = checkEndTimeParam.Title;
		}
		else if (data.minutes <= 0) {
			errCaption = "Start time must come before the end time";
			errTitle = "Invalid";
		}
		else if (!checkInt32MinutesBreakParam.Result) {
			errCaption = checkInt32MinutesBreakParam.Message;
			errTitle = checkInt32MinutesBreakParam.Title;
		}
		else if (data.Minutes <= 0) {
			errCaption = "Hours break must be less than the difference in hours between start time and end time";
			errTitle = "Invalid";
		}
		else {
			data.EndTime = undefined;
			this.parentRef.current.setState({ showRequiredField: false });

			return await this.checkNoDuplicates(data);
		}
		this.parentRef.current.setState({ showRequiredField: true, errTitle, errCaption });
		return false;
	};

	downloadDoc(filename, origName) {
        window.location =
            global.ApiHost +
            "api/TransactionsCommon/download?" +
            "fileName=" +
            filename +
            "&" +
            "originalName=" +
            origName;
    }
	update = async( content ) => {
		content.excludedId = content.id;
		var isValid = await this.validate(content);

		if( isValid === false){
			return;
		}

		content.employeeId = content.employeeIds[0];
		await authPost( '/OfficialBusiness/Update', content);

		this.parentRef.current.updateRow( content );
		this.showModal({}, false);		

	}

	saveMultiple = async (content) => {

		var isValid = await this.validate(content);

		if( isValid === false){
			return;
		}

		var response = await authPost( '/OfficialBusiness/CreateMultiple', content);
		var result = await response.json();

		var empIds = result.map( x=> x.employeeId );
		var employees = await fnAsyncSearchEmployee( 
			"", 
			0,
			2147483647,
			false,
			null,
			empIds
		);

		result.forEach( x => {
			var emp = employees.filter( y => y._id == x.employeeId );
			if( emp.length > 0 ){
				x.employee = `${emp[0].LastName}, ${emp[0].FirstName}`;
			}
		})

		this.parentRef.current.addRows( result );
		this.showModal({}, false);		
	}

	onSave = () => {
		let content = this.modalRef.current.getContent();

		if (content.id) {
			this.update(content);
		}
		else {
			this.saveMultiple(content)
		}
	}


	showModal = (data, visible) => {
		visible && this.modalRef.current.setContent(data);
		this.setState({ modalVisible: visible });
	}

	isNullOrEmpty(val) {
		if (val === null || val === undefined || val === "" || val.length === 0) return true;
		else return false;
	}

	render() {
		let self = this;
		return (
			<TransactionModuleBase
				ref={this.parentRef}
				startDate={this.state.fromDate}
				endDate={this.state.toDate}
				viewID={ViewID}
				title={ViewCaption}
				columns={this.createColumns()}
				toModel={this.toModel}
				toRow={this.toRow}
				urls={this.createUrls()}
				validate={this.validate}
				props={this.props}
				showModal={this.showModal.bind(this)}
				userRightsKeys={userRightsKeys}
				hasExportToExcel={true}
			>
				<OfficialBusinessForm
					ref={self.modalRef}
					open={self.state.modalVisible}
					errTitle={self.parentRef.current ? self.parentRef.current.state.errTitle : ""}
					errCaption={self.parentRef.current ? self.parentRef.current.state.errCaption : ""}
					showRequiredField={self.parentRef.current ? self.parentRef.current.state.showRequiredField : ""}
					updateEmployeeIds={(Ids) => { self.parentRef.current.updateEmployeeIds(Ids) }}
					isBusy={self.parentRef.current ? self.parentRef.current.state.isSaving : false}
					onCancel={() => { 
						self.setState({ modalVisible: false }, self.parentRef.current.setState({ showRequiredField: false })); 
					}}
					
					onCreateEmpClick={() => {
						this.setState({ modalVisible: false });
						this.props.createEmpCallback();
					}}
					onSave={this.onSave}
				>
				</OfficialBusinessForm>
			</TransactionModuleBase>
		);
	}

}

OfficialBusinessList.getViewID = () => { return ViewID };
OfficialBusinessList.getCaption = () => { return ViewCaption }
OfficialBusinessList.getIcon = () => { return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Official_Business.png' avatar />); }
OfficialBusinessList.getIconSrc = (size) => { return '/Icons/Official_Business.png' }
OfficialBusinessList.getViewAccessKey = function () {
	var count = Object.keys(userRightsKeys).filter(name => isTaskAvailable(userRightsKeys[name]) === true).length;
	return count > 0 ? true : false;
};
OfficialBusinessList.init = function (parent) {
	var handleOfficialBusinessAction = function (status, data, callback) {
		var message = "";
		var title = data.Title;
		if (status === "CLOSE") 
			parent.setState({ external: null })
		else {
			var items = [];
			items.push(data.Data);
			var dataItems = { items: items, status: status, remarks: data.Data.ApproverRemarks };
			dataItems.fromDate = moment(data.Data.StartDate).toJSON();
			dataItems.toDate = moment(data.Data.EndDate).toJSON();
			var parameter = {
				data: dataItems,
				url: updateStatus,
				onError: function (error) {
					alert("Error occured");
				},
				onSuccess: (data, sender) => {
					if (data[data.length - 1].hasOwnProperty("errMessage")) {
						let lastIndex = data.length - 1;
						let titleSingular = title.toLowerCase() === 'official businesses' ? title.substring(0, title.length - 2) : title.substring(0, title.length - 1);
						title = "Invalid";
						message = status === "APPROVED" ? "Already approved " + titleSingular.toLowerCase() + " request has been found." : data[lastIndex]["errMessage"];
					}
					else {
						message = data[0].Message;
					}
					parent.setState({
						external:
							<MessageBoxOkOnly
								title={title}
								caption={message}
								onClick={() => {
									parent.setState({ external: null });
								}}
							/>
					});
				},
				finally: function () {
					callback(message, status);
				}
			};
			ajaxPost(parameter);
		}
	};
	var notificationAction =
		function (parent, action, data, callback) {
			var status = "";
			switch (action.Action) {
				case "Approve": status = "APPROVED"; break;
				case "Reject": status = "REJECTED"; break;
				case "Cancel": status = "CANCELLED"; break;
				default: status = "View"; break;
			}

			//var actionTaken = data["ActionSelection"];
			if (data["ActionTaken"] === "NONE" && data["IsActionable"] === false) {
				data["ActionTaken"] = "READ";
				ajaxPost(
					{
						url: "api/Notification/markAsRead",
						data: data._id,
						onSuccess: data => { },
						finally: () => { }
					}
				)
			}

			ajaxPost({
				url: "api/Notification/loadDataOnly",
				data: data["_id"],
				onSuccess: x => {
					data["Data"] = x;
				},
				finally: () => {
					data["ActionTaken"] = data["ActionTaken"].toString() === "" ? "NONE" : data["ActionTaken"];

					if (status === "View") {
						const startTime =  moment(new Date(data.Data["StartTime"]),'hh:mm A').isValid() ?
						moment(new Date(data.Data["StartTime"]),'hh:mm A') : moment(data.Data["StartTime"], 'hh:mm A');
						var viewData = {
							"Start Date": moment(data.Data["StartDate"]).format(momentDateFormat),
							"End Date": moment(data.Data["EndDate"]).format(momentDateFormat),
							"Start Time": startTime.format("hh:mm A") ,
                            // "Hours" : (data.Data["Minutes"] / 60).toFixed(2),
                            "End Time": startTime.add(data.Data["Minutes"] + data.Data["MinutesBreak"], 'minute').format("hh:mm A"),
							"Break Hours": (data.Data["MinutesBreak"] / 60).toFixed(2),
							"Location": data.Data["Location"],
							"Purpose": data.Data["Purpose"],
							"Status": data.Data["Status"],
							"Remarks": data.Data["Remarks"],
							"Description": data["Description"],
						};
						if (data.Data["ApproverRemarks"]) {
							viewData["Approver Remarks"] = data.Data["ApproverRemarks"];
						}
						parent.setState({ external: <ViewNotificationDetails Title={data.Title} data={data} viewData={viewData} callback={callback} onAction={this.handleOfficialBusinessAction.bind(this)} /> });
					}
					else {
						data.Data["ApproverRemarks"] = "";
						this.handleOfficialBusinessAction(status, data, callback);
					}
				}
			});
		}
	parent.FunctionCollection["officialBusinessNotification"] = notificationAction;
	parent.FunctionCollection["handleOfficialBusinessAction"] = handleOfficialBusinessAction;
}

export default OfficialBusinessList;