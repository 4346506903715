import React, { Component } from 'react';
import { Button, Dropdown, Header, Input, Modal } from 'semantic-ui-react';

export default class AllowanceProfilePropertyModal extends Component {
    constructor(props, context) {
        super(props, context)
    }

    render() {
        return (<Modal size="mini" open={this.props.open} onClose={this.props.onClose}>
            <Header content="Allowance Properties" />
            <Modal.Content>
                <div class="ui grid">
                    <div class="left floated left aligned eight wide column">
                        <label>Taxable:</label>
                    </div>
                    <div class="left floated left aligned four wide column">
                        <label>{this.props.allowanceType.IsTaxable ? "Yes" : "No"}</label>
                    </div>
                    <div class="left floated left aligned eight wide column">
                        <label>Deminimis:</label>
                    </div>
                    <div class="left floated left aligned four wide column">
                        <label>{this.props.allowanceType.IsDeminimis ? "Yes" : "No"}</label>
                    </div>
                    <div class="left floated left aligned eight wide column">
                        <label>Include on Restday:</label>
                    </div>
                    <div class="left floated left aligned four wide column">
                        <label>{this.props.allowanceType.IncludeRestdayWork ? "Yes" : "No"}</label>
                    </div>
                    <div class="left floated left aligned eight wide column">
                        <label>Include on Late:</label>
                    </div>
                    <div class="left floated left aligned four wide column">
                        <label>{this.props.allowanceType.IncludeLate ? "Yes" : "No"}</label>
                    </div>
                    <div class="left floated left aligned eight wide column">
                        <label>Include on Undertime:</label>
                    </div>
                    <div class="left floated left aligned four wide column">
                        <label>{this.props.allowanceType.IncludeUndertime ? "Yes" : "No"}</label>
                    </div>
                    <div class="left floated left aligned eight wide column">
                        <label>Include on Absent:</label>
                    </div>
                    <div class="left floated left aligned four wide column">
                        <label>{this.props.allowanceType.IncludeAbsent ? "Yes" : "No"}</label>
                    </div>
                    <div class="left floated left aligned eight wide column">
                        <label>Include HD Work:</label>
                    </div>
                    <div class="left floated left aligned four wide column">
                        <label>{this.props.allowanceType.IncludeHDWork ? "Yes" : "No"}</label>
                    </div>
                    <div class="left floated left aligned eight wide column">
                        <label>Include SD Work:</label>
                    </div>
                    <div class="left floated left aligned four wide column">
                        <label>{this.props.allowanceType.IncludeSDWork ? "Yes" : "No"}</label>
                    </div>
                </div>

            </Modal.Content>
            <Modal.Actions>
                <Button basic icon="check" content="Ok" onClick={this.props.onClose} />
            </Modal.Actions>
        </Modal >)
    }

}