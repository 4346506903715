import React, { Component } from 'react';
import { Image, Pagination, Card, Table, List, Menu, Input, Button, Icon, Label, Modal, Select, Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { ajaxPostDownload, checkDateRange } from '../../utils';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';
import { ajaxPost } from '../../ajax.js';
import SelectEmployees from '../../Commons/SelectEmployees';
import PDFLogs from './PDFDeviceLogs';
import PDFPairingLogs from './PDFPairingLogs';
import GroupFilterModal from '../../Commons/GroupFilterModal';
import DataGrid from 'react-data-grid'
import modStyles from './DeviceLogList.module.css';
import * as pdfMakeX from "pdfmake/build/pdfmake.js";
import * as vfsFonts from "pdfmake/build/vfs_fonts";
import ReactSelect from 'react-select';


var { fnSearchDeviceLogs, fnSearchDevice, fnSearchEmployee, isTaskAvailable, customStyles, } = require('../../utils');
var moment = require('moment');
const fileDialog = require('file-dialog');

function mapDevices(deviceList) {
    const deviceOptions = [];
    deviceOptions.push({ label: "ALL" , value: "ALL"});
    if (deviceList !== undefined) {
        deviceList.map((device, index, array) => { 
            deviceOptions.push( { 
            label : device.DeviceName,
            value : device.SerialNumber })} ); 
    } 
    return deviceOptions;
}

class DeviceLogList extends Component {

    dt = moment().format('YYYY-MM-DD');
    state = {
        openCivilSetting: false,
        loading: false,
        startDate: this.dt,
        prevstartDate: this.dt,
        endDate: this.dt,
        prevendDate: this.dt,
        activePage: 0,
        list: [],
        totalCount: 0,
        pageNumber: 0,
        rowsPerPage: 50,
        selectedEmployees: [],
        loading: false,
        importLoading: false,
        exportLoading: false,
        exportPDFLoading: false,
        exportPDFPairingLoading: false,
        allowImport: true,
        allowExport: true,
        allowExportCivilService: true,
        subordinates: [],
        searchDeviceList: [],
        selectDevice: null,
        selectedDeviceName: null, 
        search: '',
        columns: [
            { key: 'EmployeeNo', name: 'Employee Number', width: 150, resizable: true },
            { key: 'Employee', name: 'Employee', width: 300, resizable: true },
            { key: 'RecordDate', name: 'Date', width: 200, resizable: true },
            { key: 'RecordTime', name: 'Time', width: 200, resizable: true },
            { key: 'DisplayLogType', name: 'Type', width: 150, resizable: true },
            { key: 'TransacLocation', name: 'Location', width: 485, resizable: true },
            { key: 'Temperature', name: 'Temperature', width: 150, resizable: true },
        ],
        isFilterActive: false,
        advanceFilter: {
            companyIds: [],
            divisionIds: [],
            departmentIds: [],
            locationIds: [],
            positionIds: []
        },
        csFormSettings: {
            FormTitle: "",
            VerifiedBy: "",
            InCharge: ""
        }
    }

    csFormFieldStyle = {
        display: 'flex',
        marginTop: '5px',
        width: '100%',
    }

    rowGetter = (i) => {
        return this.state.list[i];
    };

    componentWillMount = () => {
        if (this.props.prevState != null)
            this.state = this.props.prevState;
    }

    componentDidMount = () => {
        this.setState({ allowImport: isTaskAvailable("DEVICELOG_IMPORT") });
        this.setState({ allowExport: isTaskAvailable("DEVICELOG_EXPORT") });
        this.setState({ allowExportCivilService: isTaskAvailable("DEVICELOG_CIVILSERVICE_EXPORT") });
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, "DeviceLogModule");
    }


    //DROPDOWN OPTIONS
    onDeviceSelected = (value) => {
        const selectedDeviceName = value ? value.value : null;
            this.setState({ selectedDeviceName, search: "", selectDevice: value  },
            () => {
                if (selectedDeviceName === "ALL") {
                    this.setState({ selectedDeviceName: null });
                }
                this.handleClick();
                this.onSearchLoad();
            }
        );
    };
            
    // Updated onDeviceInput function to search for DeviceName
    onDeviceInput = (text) => {
        if (this.state.search !== text) {
          clearTimeout(this.searchTimer);
          this.setState({ search: text });
          this.searchTimer = setTimeout(() => this.onSearchLoad(text), 200);
        }
      };
    
      onSearchLoad = (str) => {
        this.setState({ loading: true }); 
    
        var { selectedDevice } = this.state;
        var excludedIds = selectedDevice != null ? selectedDevice.map((x) => x.value) : null;
        var this2 = this;
    
        fnSearchDevice(
          str,
          (result) => {
            this2.setState({ searchDeviceList: result, loading: false });
          },
          0,
          50,
          false,
          null,
          excludedIds
        );
      };
    

    handleClick = () => {
        var res = checkDateRange(this.state.startDate, this.state.endDate);
        if (!res.Result) {
            this.setState({
                activePage: 1,
                isFromDateError: false,
                isToDateError: false,
                isError: !res.Result,
                loading: false,
                startDate: this.state.prevstartDate,
                endDate: this.state.prevendDate,
            },
            () => {
                this.getLogs(data => {
                    let filteredData = data.content;
                    this.mapData(filteredData);
                    this.setState({ totalCount: data.count });
                    this.setState({ loading: false });
                });
            });
        } else {
            this.setState({
                activePage: 1,
                loading: true,
            });
            this.getLogs(data => {
                let filteredData = data.content;
                this.mapData(filteredData);
                this.setState({ totalCount: data.count });
                this.setState({ loading: false });
            });
        }
    };
    
    getLogs = (callback) => {
        var activePage = this.state.activePage;
        var rowsPerPage = this.state.rowsPerPage;
        
        const selectedDevice = this.state.selectDevice == null || this.state.selectDevice.value === "ALL" ? null : this.state.selectDevice;
        var sns = selectedDevice != null ? [selectedDevice.value] : [];
        // var accessNumbers = this.state.selectedEmployees.length > 0 ? this.state.selectedEmployees.map(x => { return x.AccessNumber }) : this.state.subordinates.map(x => { return x.AccessNumber });
        var selectedEmployees = this.state.selectedEmployees;
        var advanceFilter = this.state.advanceFilter;
        fnSearchDeviceLogs(this.state.startDate, this.state.endDate, sns, selectedEmployees,
            data => {
                var res = data.count / rowsPerPage;
                var count = parseInt(res);

                if (res % count > 0)
                    count += 1;

                if (count == 0)
                    count += 1;

                callback(data);
                this.setState({ totalCount: data.count });
                this.setState({ pageNumber: count });
            }, (activePage - 1) * rowsPerPage, rowsPerPage, advanceFilter);
    }

    onExport = () => {
        const selectedDevice = this.state.selectDevice == null || this.state.selectDevice.value === "ALL" ? null : this.state.selectDevice;
        var sns = selectedDevice != null ? [selectedDevice.value] : [];
        this.setState({ exportLoading: true });
        var advanceFilter = this.state.advanceFilter;
        window.location = global.ApiHost + "api/DeviceLog/ExportToExcel?" +
            "startDate=" + this.state.startDate + "&" +
            "endDate=" + this.state.endDate + "&" +
            "totalCount=" + this.state.totalCount + "&" +
            "serialNumbers=" + sns.join(",") + "&" +
            "userId=" + global.userId + "&" +
            "employeeIds=" + this.state.selectedEmployees.join(",") + "&" +
            "AdvanceFilter=" + JSON.stringify(advanceFilter);
        // "accessNumbers=" + this.state.selectedEmployees.map(x => { return x.AccessNumber }).join(",")
        this.setState({ exportLoading: false });
    }

    exportToPDF = () => {
        const selectedDevice = this.state.selectDevice == null || this.state.selectDevice.value === "ALL" ? null : this.state.selectDevice;
        var sns = selectedDevice != null ? [selectedDevice.value] : [];
        var advanceFilter = this.state.advanceFilter;
        this.setState({ exportPDFLoading: true })
        ajaxPost({
            url: "api/Devicelog/search",
            data: {
                "SerialNumbers": sns,
                "EmployeeIds": this.state.selectedEmployees,
                "StartIndex": 0,
                "MaxData": this.state.totalCount,
                "StartDate": this.state.startDate,
                "EndDate": this.state.endDate,
                "AdvanceFilter": JSON.stringify(advanceFilter)
            },
            onSuccess: data => {
                data.StartDate = this.state.startDate;
                data.EndDate = this.state.endDate;
                PDFLogs(data);
            },
            finally: () => {
                this.setState({ exportPDFLoading: false })
            }
        })
    }

    exportPairingToPDF = () => {
        const selectedDevice = this.state.selectDevice == null || this.state.selectDevice.value === "ALL" ? null : this.state.selectDevice;
        var sns = selectedDevice != null ? [selectedDevice.value] : [];
        var advanceFilter = this.state.advanceFilter;
        this.setState({ exportPDFPairingLoading: true })
        ajaxPost({
            url: "api/Devicelog/exportLogsSummaryToPDF",
            data: {
                "serialNumbers": sns,
                "employeeIds": this.state.selectedEmployees,
                "fromDate": this.state.startDate,
                "toDate": this.state.endDate,
                "AdvanceFilter": JSON.stringify(advanceFilter),
                "userId": global.userId 
            },
            onSuccess: data => {
                PDFPairingLogs(data);
            },
            finally: () => {
                this.setState({ exportPDFPairingLoading: false })
            }
        })
    }

    onExportLogsSummary = (event, data) => {
        var exportType = data.exportType;
        var advanceFilter = this.state.advanceFilter;
        const selectedDevice = this.state.selectDevice == null || this.state.selectDevice.value === "ALL" ? null : this.state.selectDevice;
        var serialNumbers = selectedDevice != null ? [selectedDevice.value] : [];
        this.setState({ exportLoading: false }, () => {
            let parameter = {
                employeeIds: this.state.selectedEmployees,
                fromDate: moment(this.state.startDate).toJSON(),
                toDate: moment(this.state.endDate).toJSON(),
                serialNumbers,
                requestorId: this.state.userId,
                AdvanceFilter: JSON.stringify(advanceFilter),
                userId: global.userId 
            }

            ajaxPostDownload(
                "api/Devicelog/exportLogsSummaryTo" + exportType,
                parameter,
                "Pairing DTR Report." + (exportType === "excel" ? "xlsx" : "pdf"),
                () => {
                    //this.messageBoxOkOnlyOpen(true, "Success", "Export successful");
                },
                (error) => {
                    // var errorMessage = "An error has occurred";
                    // if (error) {
                    //     let errorData = JSON.stringify(error);
                    //     errorMessage += errorData !== "{}" ? "\r\n" + errorData : "";
                    // }
                    // this.messageBoxOkOnlyOpen(true, "Error", errorMessage);
                },
                () => {
                    this.setState({ exportLoading: false });
                }
            )
        });


    }

    onImport = () => {
        fileDialog().then(file => {
            const data = new FormData();
            data.append('file', file[0]);
            data.append('RequestClass', JSON.stringify({
                SessionKey: 'HRIS_SessionId',
                SessionId: global.sessionId
            }
            )
            );
            this.setState({ importLoading: true });
            fetch(global.ApiHost + 'api/DeviceLog/importTimeEntries', {
                method: 'POST',
                credentials: 'include',
                body: data
            }).then((resp) => {
                this.setState({ importLoading: false });
                return resp.json();
            }).then((json) => {
                var success = json.JsonData;
                if (success == 'true') {
                    alert("Imported time entries successfully completed");
                } else {
                    alert("Failed to import time entries");
                }
                this.setState({ importLoading: false });
            })

        }
        );
    }

    advanceFilterCallBack = (advanceFilter, includeDeleted) => {

        var AdvanceFilter = this.state.advanceFilter;
        AdvanceFilter = advanceFilter;
        this.setState({
            advanceFilter: AdvanceFilter,
            isFilterActive: false,
        });

    }

    handlePaginationChange = (e, { activePage }) => {

        if (isNaN(activePage) || activePage === 0) {
            activePage = 1;
        }
        this.setState({ activePage },
            () => { this.handleClick(); }
        );
    }
    handleChange = (value) => {
        this.setState({ selectedEmployees: value });
    }

    handleFilterClick(isOpen) {
        this.setState({ isFilterActive: isOpen });
    }
    getData = (input, callback) => {
        fnSearchEmployee(input, data => {
            var emps = [];
            data.content.map(item => {
                emps.push({
                    key: item._id,
                    FullName: item.LastName + ', ' + item.FirstName + ' ' + item.MiddleName + " (" + item.EmployeeNo + ")", EmployeeNo: item.EmployeeNo, AccessNumber: item.AccessNumber
                });
            })
            this.setState({ subordinates: data.content });
            callback(null, {
                options: emps
            });
        }, 0, 20, false);
    }

    onDateChange = (name, errName, evt) => {

        if (evt.target.value === "" || evt.target.value === undefined)
            this.setState({ [errName]: true })
        else
            this.setState({ [errName]: false })

        var startDate = name === "startDate" ? evt.target.value : this.state.startDate;
        var endDate = name === "endDate" ? evt.target.value : this.state.endDate;
        if (checkDateRange(startDate, endDate).Result)
            this.setState({ prevstartDate: JSON.parse(JSON.stringify(startDate)), prevendDate: JSON.parse(JSON.stringify(endDate)) });

        this.setState({ [name]: evt.target.value });
    }

    mapData = (deviceLogs) => {
        var deviceLogsViews = []
        var deviceFilter = []
        
        if (deviceLogs === undefined)
            return;
        // var rowCount = 0;
        deviceLogs.forEach(det => {
            var deviceLogsView =
            {
                EmployeeNo: det.EmployeeNo,
                Employee: det.Employee,
                RecordDate: moment(moment(det.RecordDate).toDate()).format("MM-DD-YYYY, ddd"),
                RecordTime: moment(moment(det.RecordTime).toDate(), ['h:m a', 'H:m']).format("hh:mm A"),
                DisplayLogType: det.DisplayLogType,
                TransacLocation: det.TransacLocation,
                Temperature: det.Temperature,
            }

            deviceLogsViews.push(deviceLogsView);
            deviceFilter.push({ DeviceName: det.DeviceName }); 


        });
        // this.setState({ list: deviceLogsViews });
        this.setState({ list: deviceLogsViews, deviceFilter });
    }

    csFormActionOk = () => {
        this.setState({ openCivilSetting: false });
        this.onExportCSForm48StandardFormat();
    }

    onCSFormTextChange = (name, data) => {
        const { csFormSettings } = this.state;
        csFormSettings[data.name] = data.value;
        this.setState({ csFormSettings });
    }

    onExportCSForm48StandardFormat = () => {
        ajaxPost({
            url: "api/DeviceLog/exportCSForm48",
            data: {
                "EmployeeIds": this.state.selectedEmployees,
                "StartIndex": 0,
                "MaxData": this.state.totalCount,
                "StartDate": this.state.startDate,
                "EndDate": this.state.endDate,
            },
            onSuccess: data => {
                const daysInMonth = moment(this.state.startDate, "YYYY-MM").daysInMonth();
                const month =
                    moment(this.state.startDate).format("MMMM") +
                    " 1-" +
                    daysInMonth +
                    ", " +
                    moment(this.state.startDate).format("YYYY");
                const { vfs } = vfsFonts.pdfMake;
                pdfMakeX.vfs = vfs;

                function buildDTRTable(civlService) {
                    const detailbody = [
                        [
                            { rowSpan: 2, style: { fontSize: 6 }, text: "Day" },
                            {
                                alignment: "center",
                                bold: true,
                                colSpan: 2,
                                style: { fontSize: 6 },
                                text: "A.M.",
                            },
                            {},
                            {
                                alignment: "center",
                                bold: true,
                                colSpan: 2,
                                style: { fontSize: 6 },
                                text: "P.M.",
                            },
                            {},
                            {
                                alignment: "center",
                                bold: true,
                                colSpan: 2,
                                style: { fontSize: 6 },
                                text: "Undertime",
                            },
                            {},
                        ],
                        [
                            {},
                            {
                                alignment: "center",
                                style: { fontSize: 6 },
                                text: "Arrival",
                            },
                            {
                                alignment: "center",
                                style: { fontSize: 6 },
                                text: "Departure",
                            },
                            {
                                alignment: "center",
                                style: { fontSize: 6 },
                                text: "Arrival",
                            },
                            {
                                alignment: "center",
                                style: { fontSize: 6 },
                                text: "Departure",
                            },
                            {
                                alignment: "center",
                                style: { fontSize: 6 },
                                text: "Hours",
                            },
                            {
                                alignment: "center",
                                style: { fontSize: 6 },
                                text: "Minutes",
                            },
                        ],
                    ];
                    civlService.DTRDetails.map((det) => {
                        const row = new Array();
                        row.push({ style: { fontSize: 6 }, text: det.Day });
                        row.push({ style: { fontSize: 6, color: (det.IsAmInCorrection ? 'red' : 'black') }, text: det.AMInString });
                        row.push({ style: { fontSize: 6, color: (det.IsAmOutCorrection ? 'red' : 'black')  }, text: det.AMOutString });
                        row.push({ style: { fontSize: 6, color: (det.IsPmInCorrection ? 'red' : 'black')  }, text: det.PMInString });
                        row.push({ style: { fontSize: 6, color: (det.IsPmOutCorrection ? 'red' : 'black')  }, text: det.PMOutString });
                        row.push({ alignment: "center", style: { fontSize: 6 }, text: det.Undertime != null && det.Undertime != "" && det.Undertime.search(":") != -1 ? det.Undertime.split(":")[0] : ""});
                        row.push({ alignment: "center", style: { fontSize: 6 }, text: det.Undertime != null && det.Undertime != "" ? det.Undertime.search(":") != -1 ? det.Undertime.split(":")[1] : det.Undertime : "" });
                        detailbody.push(row);
                    });

                    const totalrow = new Array();
                    totalrow.push({
                        alignment: "right",
                        bold: true,
                        colSpan: 6,
                        style: { fontSize: 10 },
                        text: "TOTAL",
                    });
                    totalrow.push({});
                    totalrow.push({});
                    totalrow.push({});
                    totalrow.push({});
                    totalrow.push({});
                    totalrow.push({});
                    detailbody.push(totalrow);

                    return detailbody;
                }

                const dd = {
                    content: [
                        data.map((x) => {
                            return [
                                {
                                    columns: [
                                        [
                                            {
                                                italics: true,
                                                style: { fontSize: 6 },
                                                text: "Civil Service Form No. 48",
                                            },
                                            {
                                                alignment: "center",
                                                margin: [0, 10],
                                                text: this.state.csFormSettings.FormTitle,
                                            },
                                            {
                                                alignment: "center",
                                                text: x.FullName,
                                            },
                                            {
                                                layout: "headerLineOnly",
                                                table: {
                                                    body: [[""], [""]],
                                                    headerRows: 1,
                                                    widths: [250],
                                                },
                                            },
                                            {
                                                alignment: "center",
                                                style: { fontSize: 8 },
                                                text: "(Name)",
                                            },
                                            {
                                                layout: "noBorders",
                                                margin: [0, 5],
                                                table: {
                                                    body: [
                                                        [
                                                            {
                                                                bold: true,
                                                                style: { fontSize: 10 },
                                                                text: "For the month of",
                                                            },
                                                            {
                                                                style: { decoration: "underline", fontSize: 10 },
                                                                text: month,
                                                            },
                                                        ],
                                                        [
                                                            {
                                                                style: { fontSize: 8 },
                                                                text: "Official Hours for Regular Days:",
                                                            },
                                                            {
                                                                style: { fontSize: 8, decoration: "underline" },
                                                                text: "__________8 hours____________",
                                                            },
                                                        ],
                                                        [
                                                            {
                                                                style: { fontSize: 8 },
                                                                text: "Arrival and Departure Saturday:",
                                                            },
                                                            {
                                                                style: { fontSize: 8, decoration: "underline" },
                                                                text: "_____________________________",
                                                            },
                                                        ],
                                                        
                                                    ],
                                                },
                                            },
                                            {
                                                headerRow: 2,
                                                margin: [0, 0, 10, 0],
                                                table: {
                                                    body: buildDTRTable(x),
                                                    widths: ["auto", "*", "auto", "*", "auto", "*", "*"],
                                                },
                                            },
                                            {
                                                italics: true,
                                                margin: [0, 5],
                                                style: { fontSize: 8 },
                                                text:
                                                    "I hereby certify that the above is a true and correct report of the hours of work performed record of which was made daily at the time of arrival and departure from office.",
                                            },
                                            {
                                                alignment: "center",
                                                margin: [0, 10, 0, 0],
                                                text: this.state.csFormSettings.VerifiedBy,
                                            },
                                            {
                                                layout: "headerLineOnly",
                                                table: {
                                                    body: [[""], [""]],
                                                    headerRows: 1,
                                                    widths: [250],
                                                },
                                            },
                                            {
                                                alignment: "center",
                                                style: { fontSize: 8 },
                                                text: "Verified as to pescribed office hours",
                                            },
                                            {
                                                alignment: "center",
                                                margin: [0, 10, 0, 0],
                                                text: this.state.csFormSettings.InCharge,
                                            },
                                            {
                                                layout: "headerLineOnly",
                                                table: {
                                                    body: [[""], [""]],
                                                    headerRows: 1,
                                                    widths: [250],
                                                },
                                            },
                                            {
                                                alignment: "center",
                                                pageBreak: x.ItemIndex === data.length ? "" : "after",
                                                style: { fontSize: 8 },
                                                text: "In-Charge",
                                            },
                                        ],
                                        // New Column
                                        [
                                            {
                                                italics: true,
                                                style: { fontSize: 6 },
                                                text: "Civil Service Form No. 48",
                                            },
                                            {
                                                alignment: "center",
                                                margin: [0, 10],
                                                text: this.state.csFormSettings.FormTitle,
                                            },
                                            {
                                                alignment: "center",
                                                text: x.FullName,
                                            },
                                            {
                                                layout: "headerLineOnly",
                                                table: {
                                                    body: [[""], [""]],
                                                    headerRows: 1,
                                                    widths: [250],
                                                },
                                            },
                                            {
                                                alignment: "center",
                                                style: { fontSize: 8 },
                                                text: "(Name)",
                                            },
                                            {
                                                layout: "noBorders",
                                                margin: [0, 5],
                                                table: {
                                                    body: [
                                                        [
                                                            {
                                                                bold: true,
                                                                style: { fontSize: 10 },
                                                                text: "For the month of",
                                                            },
                                                            {
                                                                style: { decoration: "underline", fontSize: 10 },
                                                                text: month,
                                                            },
                                                        ],
                                                        [
                                                            {
                                                                style: { fontSize: 8 },
                                                                text: "Official Hours for Regular Days:",
                                                            },
                                                            {
                                                                style: { fontSize: 8, decoration: "underline" },
                                                                text: "__________8 hours____________",
                                                            },
                                                        ],
                                                        [
                                                            {
                                                                style: { fontSize: 8 },
                                                                text: "Arrival and Departure Saturday:",
                                                            },
                                                            {
                                                                style: { fontSize: 8, decoration: "underline" },
                                                                text: "_____________________________",
                                                            },
                                                        ],
                                                    ],
                                                },
                                            },
                                            {
                                                headerRow: 2,
                                                margin: [0, 0, 10, 0],
                                                table: {
                                                    body: buildDTRTable(x),
                                                    widths: ["auto", "*", "auto", "*", "auto", "*", "*"],
                                                },
                                            },
                                            {
                                                italics: true,
                                                margin: [0, 5],
                                                style: { fontSize: 8 },
                                                text:
                                                    "I hereby certify that the above is a true and correct report of the hours of work performed record of which was made daily at the time of arrival and departure from office.",
                                            },
                                            {
                                                alignment: "center",
                                                margin: [0, 10, 0, 0],
                                                text: this.state.csFormSettings.VerifiedBy,
                                            },
                                            {
                                                layout: "headerLineOnly",
                                                table: {
                                                    body: [[""], [""]],
                                                    headerRows: 1,
                                                    widths: [250],
                                                },
                                            },
                                            {
                                                alignment: "center",
                                                style: { fontSize: 8 },
                                                text: "Verified as to pescribed office hours",
                                            },
                                            {
                                                alignment: "center",
                                                margin: [0, 10, 0, 0],
                                                text: this.state.csFormSettings.InCharge,
                                            },
                                            {
                                                layout: "headerLineOnly",
                                                table: {
                                                    body: [[""], [""]],
                                                    headerRows: 1,
                                                    widths: [250],
                                                },
                                            },
                                            {
                                                alignment: "center",
                                                pageBreak: x.ItemIndex === data.length ? "" : "after",
                                                style: { fontSize: 8 },
                                                text: "In-Charge",
                                            },
                                        ],
                                    ],
                                },
                            ];
                        }),
                    ],
                    pageSize: "A4",
                };
                pdfMakeX.createPdf(dd).open();
            },
            finally: () => {
                this.setState({ exportPDFLoading: false })
            }
        })
    };

    render() {
        return <>
            <div className={modStyles.container}>
                <div style={{ color: "#606060", marginTop: '1%' }}>
                    <h2>Time Entries</h2>
                </div>
                <br />
                <br />
                <div>
                    <Menu secondary size='mini' stackable>
                        <Button size='mini' disabled={!(this.state.allowImport) || this.state.importLoading} loading={this.state.importLoading}
                            onClick={this.onImport.bind(this)} style={{ maxHeight: '31px' }}>
                            <Icon name="upload" />Import</Button>
                        <Button size='mini' disabled={!(this.state.allowExport && this.state.list.length > 0) || this.state.exportLoading} loading={this.state.exportLoading}
                            onClick={this.onExport.bind(this)} style={{ maxHeight: '31px' }}>
                            <Icon name="file online excel" />Export to Excel</Button>
                        <Button size='mini' disabled={!(this.state.allowExport && this.state.list.length > 0) || this.state.exportPDFLoading} loading={this.state.exportPDFLoading}
                            onClick={this.exportToPDF.bind(this)} style={{ maxHeight: '31px' }}>
                            <Icon name="file online excel" />Export to PDF</Button>
                        <Button size='mini' disabled={!(this.state.allowExport && this.state.list.length > 0) || this.state.exportLoading} loading={this.state.exportLoading}
                            exportType="excel" onClick={this.onExportLogsSummary.bind(this)} style={{ maxHeight: '31px' }}>
                            <Icon name="file online excel" />Export Pairing DTR to Excel</Button>
                        <Button size='mini' disabled={!(this.state.allowExport && this.state.list.length > 0) || this.state.exportPDFPairingLoading} loading={this.state.exportPDFPairingLoading}
                            exportType="pdf" onClick={this.exportPairingToPDF.bind(this)} style={{ maxHeight: '31px' }}>
                            <Icon name="file online excel" />Export Pairing DTR to PDF</Button>
                        <Button size='mini' disabled={!(this.state.list.length > 0 && this.state.allowExportCivilService)} loading={this.state.exportPDFLoading}
                            exportType="pdf" onClick={() => { this.setState({ openCivilSetting: true }) }} style={{ maxHeight: '31px' }}>
                            <Icon name="file online excel" />Export Civil Service 48</Button>


                        <Menu.Item className='w3-hide-medium w3-hide-small' position='right'>
                            {
                                this.state.totalCount > 0 &&
                                <Pagination size='mini'
                                    fluid
                                    pointing
                                    defaultActivePage={1}
                                    secondary
                                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                    siblingRange={2}
                                    boundaryRange={0}
                                    totalPages={this.state.pageNumber}
                                    onPageChange={this.handlePaginationChange} />
                            }
                        </Menu.Item>
                    </Menu>
                    <div className='w3-hide-large'>
                        {
                            this.state.totalCount > 0 &&
                            <Pagination size='tiny'
                                pointing
                                floated='right'
                                defaultActivePage={1}
                                secondary
                                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                siblingRange={2}
                                boundaryRange={0}
                                totalPages={this.state.pageNumber}
                                onPageChange={this.handlePaginationChange} />
                        }
                    </div>
                    <div className='w3-row' style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                        <div className='w3-col l3' style={{ paddingTop: '5px', paddingLeft: '3px', paddingRight: '3px' }}>
                            <Input
                                fluid
                                size='mini'
                                value={this.state.startDate}
                                label="From"
                                type="date"
                                error={this.state.isFromDateError}
                                onChange={this.onDateChange.bind(this, "startDate", "isFromDateError")} />
                        </div>
                        <div className='w3-col l3' style={{ paddingTop: '5px', paddingLeft: '3px', paddingRight: '3px' }}>
                            <Input
                                fluid
                                size='mini'
                                value={this.state.endDate}
                                label="To"
                                type="date"
                                error={this.state.isToDateError}
                                onChange={this.onDateChange.bind(this, "endDate", "isToDateError")} />
                        </div>
                        <div className='w3-col l5'>
                            <div className='w3-col m6 l5' style={{ paddingTop: '5px', paddingLeft: '3px', paddingRight: '3px' }}>

                            <ReactSelect
                                placeholder="Select Device"
                                onChange={this.onDeviceSelected.bind(this)}
                                options={mapDevices(this.state.searchDeviceList)}
                                onInputChange={this.onDeviceInput.bind(this)}
                                onMenuOpen={this.onSearchLoad.bind(this, this.state.search)}
                                styles={customStyles}
                                isLoading={this.state.loading}
                                value={this.state.selectDevice}
                            />

                            </div>
                            <div className='w3-col m6 l7' style={{ paddingTop: '5px', paddingLeft: '3px', paddingRight: '3px' }}>
                                <div className='w3-col l1' >
                                    <Button style={{ width: '100%', 'max-height': '36px', height: '36px' }} size="mini" icon="filter"
                                        onClick={this.handleFilterClick.bind(this, true)}
                                    />
                                </div>
                                <div className='w3-col l11' >
                                    <SelectEmployees
                                        Ids={this.state.selectedEmployees}
                                        update={(Ids) => this.setState({ selectedEmployees: Ids !== null ? Ids : [] },
                                            // () => { this.handleClick() }
                                        )}
                                        style={{ minWidth: '50px', maxWidth: '50%', width: '50%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='w3-col l1' style={{ paddingTop: '5px', paddingLeft: '3px', paddingRight: '3px' }}>
                            <Button fluid style={{ 'max-height': '36px', height: '36px' }} size='mini' loading={this.state.loading} onClick={
                                () => {
                                    this.setState({ activePage: 1 },
                                        () => this.handleClick()
                                    )
                                }
                            }>
                                <Icon name="search" />View
                            </Button>
                        </div>
                    </div>
                </div>
                <DataGrid
                    columns={this.state.columns}
                    rows={this.state.list}
                    rowsCount={this.state.list.length}
                    minHeight={13 * 35}
                />
                {this.state.isError && <MessageBoxOkOnly title='Time Entries' caption='Invalid date/range. Previously valid inputted date range will be restored' onClick={() => this.setState({ isError: false })} />}
            </div>
            <Modal size="mini" open={this.state.openCivilSetting}>
                        <Modal.Header> Civil Service Form Header and Footer </Modal.Header>
                        <Modal.Content>
                            <div style={this.csFormFieldStyle}>
                                <label style={{ width: '100px' }}>Title</label>
                                <Input style={{ width: '100%' }} value={this.state.csFormSettings.FormTitle} name="FormTitle" onChange={this.onCSFormTextChange.bind(this)} />
                            </div>
                            <div style={this.csFormFieldStyle}>
                                <label style={{ width: '100px' }}>Verified By</label>
                                <Input style={{ width: '100%' }} value={this.state.csFormSettings.VerifiedBy} name="VerifiedBy" onChange={this.onCSFormTextChange.bind(this)} />
                            </div>
                            <div style={this.csFormFieldStyle}>
                                <label style={{ width: '100px' }}>In Charge</label>
                                <Input style={{ width: '100%' }} value={this.state.csFormSettings.InCharge} name="InCharge" onChange={this.onCSFormTextChange.bind(this)} />
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button primary={true} content="OK" onClick={this.csFormActionOk.bind(this)} />
                            <Button content="Cancel" onClick={() => { this.setState({ openCivilSetting: false }) }} />
                        </Modal.Actions>
                    </Modal>
        <GroupFilterModal
            advFilter={this.state.advanceFilter}
            hideGroups={["COMPANY"]}
            open={this.state.isFilterActive}
            onCancel={this.handleFilterClick.bind(this, false)}
            callback={this.advanceFilterCallBack.bind(this)}
        />
    </>
    }
}

DeviceLogList.getViewID = () => { return "DeviceLogModule" };
DeviceLogList.getCaption = () => { return "Time Entries" };
DeviceLogList.getViewAccessKey = () => { return isTaskAvailable("DEVICELOG_VIEW") };
DeviceLogList.getIconSrc = (size) => {
    return '/Icons/Time_Entries.png';
}
DeviceLogList.getIcon = () => {
    return <Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Time_Entries.png' avatar />
}

export default DeviceLogList;