import React, {Component} from 'react';
import {Image} from 'semantic-ui-react';
import AllowanceTypeList from './AllowanceTypeList';
import AllowanceTypeForm from './AllowanceTypeForm';
import {isTaskAvailable} from '../../utils';

class AllowanceTypeModule extends Component {
    state = {
        mode:"search",
        searchState:{
            initialLoad:true,
            selectedItem:null,
            list : [],
            search:'',         
            page:1,
            rowPerPage:10,
            totalPages:1,
            total:0,
        },
        selectedItem:null
    }
    componentWillMount(){
        if( this.props.prevState != null )
        {
            this.state = this.props.prevState;
            this.state.searchState.initialLoad =false;
        }
        
    }
    componentWillUnmount = () =>
        {
        this.props.unmountCallback(this.state,"AllowanceTypes");
        }
        onUpdateState = (searchState) =>{
            this.setState({searchState: searchState});
        }
        onSelect =(item) =>{
            this.setState({selectedItem:item});
        }
        onAdd =(searchState) =>
        {
            //set blank model for selectedItem
            this.setState({selectedItem:null});
            this.setState({searchState});
            this.setState({mode:"add"});
        }
        onEdit =(searchState,selectedSchedule) =>
        {
            this.setState({searchState: searchState});
            this.setState({selectedItem:selectedSchedule});
            this.setState({mode:"edit"});
        }
        onCloseForm =(schedule) =>{
            this.setState({mode:"search"});        
        }
        onUpdateComplete =(model) =>{
            var list = this.state.searchState.list;
            var searchState = this.state.searchState;
    
            if(this.state.mode ==="add")
            {
                if(list.length > 0)
                {     
                    list.push(model);           
                    searchState.list = list;
                }
                else{
                    searchState.list = [model];
                }
                searchState.selectedItem = model;
                searchState.page =1;
            }else{
                var itemIndex = list.indexOf(this.state.selectedItem);
                if(itemIndex >=0)
                    list[itemIndex] = model;
            }
            this.setState({searchState:this.state.searchState});
            this.setState({mode:"search"});
        }
    render(){
        return this.state.mode ==="search" ?
        <AllowanceTypeList 
            searchState ={this.state.searchState}
            onSelect={this.onSelect.bind(this)}
            onAdd={this.onAdd.bind(this)}
            onEdit={this.onEdit.bind(this)}
            onUpdateState={this.onUpdateState.bind(this)}/>
        :       
        <AllowanceTypeForm selectedItem={JSON.parse(JSON.stringify(this.state.selectedItem))}
            onCloseForm={this.onCloseForm.bind(this)}
            onUpdateComplete={this.onUpdateComplete.bind(this)} />
    }
}
AllowanceTypeModule.getViewID = () => {return "AllowanceTypes"};
AllowanceTypeModule.getCaption =() =>{return "Allowance Type"}
AllowanceTypeModule.getViewAccessKey =() => { 
    return isTaskAvailable("ALLOWANCE_TYPE_VIEW") || isTaskAvailable("ALLOWANCE_TYPE_CREATE")
     || isTaskAvailable("ALLOWANCE_TYPE_MODIFY") || isTaskAvailable("ALLOWANCE_TYPE_DELETE")
      ? true : false
    }
    AllowanceTypeModule.getIcon = () =>
{
return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Payroll_Adjustment.png' avatar />);
}
AllowanceTypeModule.getIconSrc = (size) => //size values: small (50x50), medium(150x150), large(300x300)
{
    return '/Icons/Payroll_Adjustment.png'
}
export default AllowanceTypeModule