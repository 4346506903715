import React, { Component } from 'react';
import { Button, Checkbox, Input, Table, Header, Modal, Loader, Menu, Message, Popup, Icon, Reveal, RevealContent } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import ReactDataGrid from 'react-data-grid';
import Update from 'immutability-helper';
import { ajaxPost } from '../../ajax';
import { empty_id, checkInt32 } from '../../utils';
import './allowance_type.css';

class AllowanceTypeForm extends Component {
    state = {
        ItemData: {
            _id: empty_id,
            Description: "",
            Code: "",
            IsTaxable: false,
            IsDeminimis: false,

            IncludeRestdayWork: false,
            IncludeLate: false,
            IncludeUndertime: false,
            IncludeAbsent: false,
            IncludeHDWork: false,
            IncludeSDWork: false,
        },
        saving: false,
        dialog: false,
        dialogContent: null,
        dialogTitle: '',
        conflict: [],
        showConflict: false,
        showRequiredField: false,
        showDeminimisPopup: false,
        showTaxablePopup: false,
    }

    constructor(props, context) {
        super(props, context);
        this.state.ItemData = props.selectedItem == null ? this.state.ItemData : props.selectedItem;
    }

    getPopupMessage = (key) => {
        const messages = {
            restday_work: <div>
                    <div>For monthly allowance in cutoff plus (restday work count * daily allowance rate)</div>
                    <div>Any holiday that falls on a rest day is included in this policy.</div>
                </div>,
            late: <div>
                <div>** Applicable only for <b>Monthly</b> & <b>Daily</b> rate **</div>
                <div>**The late will deduct on allowance total in cutoff</div>
                <div>Late will not affect Hourly rate employee (excluded)</div>
                <div>Daily Rate Computation = (allowance / hour per day / 60 min) * late min</div>
                <div>Montly Rate Computation = (allowance / Days per month / Hour per day / 60 min) * late min</div>
            </div>,
            undertime: <div>
                <div>** Applicable only for <b>Monthly</b> & <b>Daily</b> rate **</div>
                <div>The late will deduct on allowance total in cutoff</div>
                <div>Late will not affect Hourly rate employee (excluded)</div>
                <div>Daily Rate Computation = (allowance / hour per day / 60 min) * UT min</div>
                <div>Montly Rate Computation = (allowance / Days per month / Hour per day / 60 min) * UT min</div>
            </div>,
            absent: <div>For monthly allowance in cutoff deducted by (Absent count * daily allowance rate)</div>,
            deminimis: <div>
                <div>De minimis can't be enabled while taxable is active.</div>
            </div>,
            taxable: <div>
                <div>taxable can't be enabled while de minimis is active.</div>
            </div>,
            holiday_work: <div>
                <div>Any holiday fall in restday is not include on this policy</div>
                <div>---</div>
                <div><b>Disabled:</b></div>
                <div>Allowance will not be paid on Holiday.</div>
                <div>Notes: Monthly rate deducted by number of holidays:</div>
                <div><b>---</b></div>
                <div><b>Enabled:</b></div>
                <div><b>For Hourly:</b> Based on hours worked. The maximum hours that can be worked are based on the employee's scheduled hours.</div>
                <div><b>For Daily:</b> Holiday will count as wholeday if present. If undertime is on, the deduction will be computed as <b>allowance daily rate - (undertime * minute rate).</b></div>
                <div><b>For Monthly:</b> Absent on holiday (minus 1 day on allowance). If you are present on holiday or eligible on HD pay, there will be no deduction.</div>
            </div>,
            sp_holiday_work: <div>
                <div>Any holiday fall in restday is not include on this policy</div>
                <div>---</div>
                <div><b>Disabled:</b></div>
                <div>Allowance will not be paid on Holiday.</div>
                <div>Notes: Monthly rate deducted by number of holidays:</div>
                <div><b>---</b></div>
                <div><b>Enabled:</b></div>
                <div><b>For Hourly:</b> Based on hours worked. The maximum hours that can be worked are based on the employee's scheduled hours.</div>
                <div><b>For Daily:</b> Holiday will count as wholeday if present. If undertime is on, the deduction will be computed as <b>daily rate - (undertime * minute rate)</b></div>
                <div><b>For Monthly:</b> Absent on holiday (minus 1 day on allowance). If you are present on holiday or eligible on HD pay, there will be no deduction.</div>
            </div>
        }

        return messages[key];
    }


    save = (callback, model) => {
        ajaxPost({
            url: 'api/AllowanceType/save',
            data: model,
            onSuccess: data => {
                callback(data);
            },
            onError: function (err) {
                alert(JSON.stringify(err));
            },
            finally: () => { this.setState({ saving: false }); }
        })
    }
    handleSave = (e, data) => {
        this.setState({ saving: true });
        var model = this.state.ItemData;
        var isSaving = true;

        if (model.Description === undefined || model.Description === null || model.Description.trim() === "") {
            this.setState({
                showRequiredField: true,
                saving: false, showConflict: true,
                conflict: ["Invalid description"]
            });
            isSaving = false;
        }

        if (model.Code === undefined || model.Code === null || model.Code.trim() === "") {
            this.setState({
                showRequiredField: true,
                saving: false, showConflict: true,
                conflict: ["Invalid code"]
            });
            isSaving = false;
        }

        if (isSaving) {

            this.save(data => {
                this.setState({ saving: false });
                this.setState({ showConflict: false });
                if (data.content != null) {
                    model = data.content;

                    this.setState({ ItemData: model });
                    this.dialogOnSuccess();
                }
                if (data.errors != null) {
                    if (data.errors.length > 0) {
                        this.setState({ showConflict: true });
                        this.setState({ conflict: data.errors });
                    }
                }

            }, model);
        }
    }
    handleChange = name => event => {
        var current = this.state.ItemData;
        current[name] = event.target.value;
        this.setState({ ItemData: current });
    };
    handleCheckChange = name => (trans, e) => {
        var current = this.state.ItemData;
        if (current[name] == undefined) {
            var transactions = current.Transactions;
            var index = transactions.indexOf(trans);
            var transaction = transactions[index];
            transaction[name] = transaction[name] === false ? true : false;
            transactions[index] = transaction;
            current.Transactions = transactions;
        } else {
            current[name] = current[name] === false ? true : false;
        }
        this.setState({ ItemData: current });
    }

    handleTaxableCheckChange = (trans, e) => {
        var current = this.state.ItemData;

        if (current.IsDeminimis && (current.IsTaxable === undefined || current.IsTaxable === false)) {
            this.setState({showTaxablePopup: true})
            return;
        }

        if (current.IsTaxable == undefined) {
            var transactions = current.Transactions;
            var index = transactions.indexOf(trans);
            var transaction = transactions[index];
            transaction.IsTaxable = transaction.IsTaxable === false ? true : false;
            transactions[index] = transaction;
            current.Transactions = transactions;
        } else {
            current.IsTaxable = current.IsTaxable === false ? true : false;
        }
        this.setState({ ItemData: current });
    }

    handleDeminimisCheckChange = (trans, e) => {
        var current = this.state.ItemData;

        if (current.IsTaxable && (current.IsDeminimis === undefined || current.IsDeminimis === false)) {
            this.setState({showDeminimisPopup: true})
            return;
        }

        if (current.IsDeminimis == undefined) {
            var transactions = current.Transactions;
            var index = transactions.indexOf(trans);
            var transaction = transactions[index];
            transaction.IsDeminimis = transaction.IsDeminimis === false ? true : false;
            transactions.IsDeminimis = transaction;
            current.Transactions = transactions;
        } else {
            current.IsDeminimis = current.IsDeminimis === false ? true : false;
        }
        this.setState({ ItemData: current });
    }

    onCloseDialog = () => {
        this.setState({ dialog: false });
        this.props.onUpdateComplete(this.state.ItemData);
    }
    dialogOnSuccess = () => {
        this.setState({ dialogTitle: "Save" });
        this.setState({
            dialogContent: (
                <div>
                    <p>Allowance Type has been updated successfully</p>
                </div>
            )
        });
        this.setState({ dialog: true });
    }
    render() {
        return (
            <div>
                <div style={{ overflow: "hidden" }}>
                    <h2 style={{ color: "#606060", marginTop: '1%', float: "left" }}>Allowance Type</h2>
                    <Menu size="mini" secondary stackable>
                        <Menu.Menu position="right" >
                            <Button.Group>
                                <Button content="Save" size="mini" icon="save"
                                    onClick={this.handleSave.bind(this)} style={{ margin: "0px 3px 0px 0px" }} />
                                <Button content="Cancel" size="mini" icon="cancel"
                                    onClick={this.props.onCloseForm.bind(this)} />
                            </Button.Group>
                        </Menu.Menu>
                    </Menu>
                </div>
                <Loader active={this.state.saving} />
                {this.state.showConflict &&
                    <Message negative>
                        <Message.Header>Conflict !</Message.Header>
                        <Message.Content>{this.state.conflict.map(n => {
                            return (<p>{n}</p>)
                        })}</Message.Content>
                    </Message>}
                <Table style={{ 'border': '0px !important' }}>
                    <Table.Row>
                        <Table.Cell colSpan='2' width='4'>
                            <label>Description</label>
                            <Input fluid placeholder='Allowance Type'
                                value={this.state.ItemData.Description}
                                maxLength="100"
                                onChange={this.handleChange("Description").bind(this)} />
                        </Table.Cell>
                        <Table.Cell width='3' textAlign='right' />
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colSpan='2' width='4'>
                            <label>Code</label>
                            <Input fluid placeholder='Code'
                                value={this.state.ItemData.Code}
                                maxLength="100"
                                onChange={this.handleChange("Code").bind(this)} />
                        </Table.Cell>
                        <Table.Cell width='3' textAlign='right' />
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width='3'>
                            <label>Taxable</label>
                        </Table.Cell>
                        <Table.Cell width='1' textAlign='right'>
                        <Popup style={{borderColor: "red"}} open={this.state.showTaxablePopup} onClose={() => this.setState({showTaxablePopup: false})} content={this.getPopupMessage('taxable')} trigger={
                            <Checkbox toggle
                                checked={this.state.ItemData.IsTaxable}
                                onChange={this.handleTaxableCheckChange.bind(this)} />
                            } closeOnPortalMouseLeave closeOnTriggerMouseLeave/>
                        </Table.Cell>
                        <Table.Cell width='3' />
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width='3'>
                            <label>De Minimis</label>
                        </Table.Cell>
                        <Table.Cell width='1' textAlign='right'>
                            <Popup style={{borderColor: "red"}} open={this.state.showDeminimisPopup} onClose={() => this.setState({showDeminimisPopup: false})} content={this.getPopupMessage('deminimis')} trigger={
                                <Checkbox toggle
                                    checked={this.state.ItemData.IsDeminimis}
                                    onChange={this.handleDeminimisCheckChange.bind(this)} />
                            } closeOnPortalMouseLeave closeOnTriggerMouseLeave/>
                            
                        </Table.Cell>
                        <Table.Cell width='3' />
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width='3'>
                            <label>Include on Restday Work &nbsp;</label><Popup wide='very' content={this.getPopupMessage('restday_work')} trigger={<Icon name='question circle outline' />}></Popup>
                        </Table.Cell>
                        <Table.Cell width='1' textAlign='right'>
                            <Checkbox toggle
                                checked={this.state.ItemData.IncludeRestdayWork}
                                onChange={this.handleCheckChange("IncludeRestdayWork").bind(this)} />
                        </Table.Cell>
                        <Table.Cell width='3' />
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width='3'>
                            <label>Include on late &nbsp;</label><Popup wide='very' content={this.getPopupMessage('late')} trigger={<Icon name='question circle outline' />}></Popup>
                        </Table.Cell>
                        <Table.Cell width='1' textAlign='right'>
                            <Checkbox toggle
                                checked={this.state.ItemData.IncludeLate}
                                onChange={this.handleCheckChange("IncludeLate").bind(this)} />
                        </Table.Cell>
                        <Table.Cell width='3' />
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width='3'>
                            <label>Include on undertime &nbsp;</label><Popup wide='very' content={this.getPopupMessage('undertime')} trigger={<Icon name='question circle outline' />}></Popup>
                        </Table.Cell>
                        <Table.Cell width='1' textAlign='right'>
                            <Checkbox toggle
                                checked={this.state.ItemData.IncludeUndertime}
                                onChange={this.handleCheckChange("IncludeUndertime").bind(this)} />
                        </Table.Cell>
                        <Table.Cell width='3' />
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width='3'>
                            <label>Include on absent &nbsp;</label><Popup wide='very' content={this.getPopupMessage('absent')} trigger={<Icon name='question circle outline' />}></Popup>
                        </Table.Cell>
                        <Table.Cell width='1' textAlign='right'>
                            <Checkbox toggle
                                checked={this.state.ItemData.IncludeAbsent}
                                onChange={this.handleCheckChange("IncludeAbsent").bind(this)} />
                        </Table.Cell>
                        <Table.Cell width='3' />
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width='3'>
                            <label>Include on holiday work &nbsp;</label><Popup wide='very' content={this.getPopupMessage('holiday_work')} trigger={<Icon name='question circle outline' />}></Popup>
                        </Table.Cell>
                        <Table.Cell width='1' textAlign='right'>
                            <Checkbox toggle
                                checked={this.state.ItemData.IncludeHDWork}
                                onChange={this.handleCheckChange("IncludeHDWork").bind(this)} />
                        </Table.Cell>
                        <Table.Cell width='3' />
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width='3'>
                            <label>Include on special holiday work &nbsp;</label><Popup wide='very' content={this.getPopupMessage('sp_holiday_work')} trigger={<Icon name='question circle outline' />}></Popup>
                        </Table.Cell>
                        <Table.Cell width='1' textAlign='right'>
                            <Checkbox toggle
                                checked={this.state.ItemData.IncludeSDWork}
                                onChange={this.handleCheckChange("IncludeSDWork").bind(this)} />
                        </Table.Cell>
                        <Table.Cell width='3' />
                    </Table.Row>
                </Table>
                {
                    <Modal size="mini" open={this.state.dialog} onClose={this.close} >
                        <Header content={this.state.dialogTitle} />
                        <Modal.Content>
                            {this.state.dialogContent}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button basic icon="check" content="Yes"
                                onClick={this.onCloseDialog.bind(this)} />
                        </Modal.Actions>
                    </Modal>
                }
            </div >
        );
    }
}
export default AllowanceTypeForm