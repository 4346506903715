import React, { Component } from 'react';
import { Button, Modal, Checkbox } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import Select from 'react-select';
import { ajaxPost } from '../../ajax';

var { fnSearchEmployeeFilter } = require('../../utils');

function employeeSearchlist(list) {
    var employees = [];
    if (list != null) {
        list.map(x => {
            var fullname = x.LastName + ", " + x.FirstName + " " + x.MiddleName;

            return (
                employees.push({ label: fullname, value: x._id })
            )
        })
    }
    return employees;
}

function groupMap(groupList, groupType, ids) {
    if (groupType == "CITY")
        ids = ids != null ? ids.map(x => x.CityId) : null;
    if (groupType == "DEPARTMENT")
        ids = ids != null ? ids.map(x => x.DepartmentID) : null;
    if (groupType == "DIVISION")
        ids = ids != null ? ids.map(x => x.DivisionID) : null;
    if (groupType == "POSITION")
        ids = ids != null ? ids.map(x => x.PositionID) : null;
    if (groupType == "COST_CENTER")
        ids = ids != null ? ids.map(x => x.CostCenterID) : null;
    if(groupType === "COMPANY")
        ids = ids != null ? ids.map(x => x.CompanyID) :null;
    var list = [];
    if (groupList != null) {
        if (groupType !== "SALARY_GRADE") {
            groupList.map(x => {
                return (
                    (x.Type === groupType) ?
                        ((ids != null ? ids.includes(x._id) : true) &&
                            list.push({ label: x.Name == "" ? "Name Not Set" : x.Name, value: x._id })) : [])
            })
        } else {
            groupList.map(x => {
                return (
                    (x.Type === groupType) ?
                        list.push({ label: x.Name + " (" + x.OtherDetails.Amount + ")", value: x._id }) : [])
            })
        }

    }
    return list;
}

class SelectEmployeeForDevice extends Component {
    state = {
        busy: false,
        error: "",
        groupList: [],
        employees: [],
        selectAll: false,
        Employeelist: [],
        EmployeeIds:[],
        searchQuery: '',
        page: 1,
        sLoading: false, 
        DivisionIds: [],
        searcDivisionQuery: '',
        DepartmentIds: [],
        searcDepartmentQuery: '',
        PositionIds: [],
        searchPositionQuery: '',
        LocationIds: [],
        searcLocationsQuery: '',
        CostCenterIds: [],
        searchCostCenterQuery: '',
        page: 1,
    }

    componentWillMount(){
        this.setState({ isLoading: true });
        this.loadGroups(); 
        fnSearchEmployeeFilter("", data => {
            this.setState({ Employeelist: data, isLoading: false });
            if(this.state.loadedIds !== undefined && this.state.loadedIds !== null && this.state.loadedIds.length > 0){
                this.setState({EmployeeIds: employeeSearchlist(data)});
            }
        }, 0, 20 , false, null, this.state.loadedIds)

        if(this.state.loadedIds !== undefined && this.state.loadedIds !== null && this.state.loadedIds.length > 0){
            this.doSearch("");
        }
        this.searchTimer = null;
    }

    handleSearchChange = (text) => {
        clearTimeout(this.searchTimer);

        if(this.state.searchQuery !== text){
            this.setState({ searchQuery: text})
            //this.searchTimer = setTimeout(this.doSearch, 400, text);
        }
    }
    doSearch = (str) => {
        const { EmployeeIds, Employeelist } = this.state;
        var selectedEmpIds = [];
        if (EmployeeIds !== null && EmployeeIds !== undefined && Employeelist !== undefined)
            selectedEmpIds = Employeelist.filter(x => EmployeeIds.map(x => x.value).includes(x._id));

        this.setState({ searchQuery: str, isLoading: true },
            () => fnSearchEmployeeFilter(str, data => {
                selectedEmpIds.forEach(x => { if (!data.includes(x["_id"])) data.push(x) })
                this.setState({ Employeelist: data, isLoading: false });
            }
                , 0, 20, false, EmployeeIds.map(x => x.value))
        )
    }
    handleSearchEmployeeChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        this.setState({ EmployeeIds: value, searchQuery: "" });
        //this.props.update(ids);
    }

    handleClose = () => {
        this.props.onClose(false);
    }

    handleAction = () => {
        this.setState({ busy: true });
        if (this.state.selectAll)
            this.props.onAction([]);
        else
            this.props.onAction(this.state.Employeelist.filter(x => this.state.EmployeeIds.map(i=>i.value).includes(x._id)).map(x=>{return x.AccessNumber}));
    }

    handleChange = (value) => {
        this.setState({ employees: value });
    }

    loadEmployees = () => {
        this.setState({ isEmployeeLoading: true});
        ajaxPost({
            url: "api/Employee/searchFilteredEmployee",
            data: {
                "Search": "",
                "includeDeleted": false,
                "startingIndex": 0,
                "itemCount": this.state.page * 20,
                "employeeIds": this.state.employeeIds,
                "DivisionIDs": this.state.DivisionIds,
                "DepartmentIDs": this.state.DepartmentIds,
                "PositionIDs": this.state.PositionIds,
                "CityIDs": this.state.LocationIds,
                "CostCenterIDs": this.state.CostCenterIds,
            },
            onSuccess: data => {
                if(this.state.employeeIds !== undefined && this.state.employeeIds.length > 0) {
                    this.setState(prevState => ({
                    Employeelist: [...prevState.Employeelist, ...data],
                    isLoading: false,
                }));
                }
                else {
                    this.setState({ Employeelist: data.content, isEmployeeLoading: false});
                }
            },
            finally: () => { }
        })
    }

    loadGroups = () => {
            this.setState({ isDepartmentLoading: true });
            ajaxPost({
                url: "api/Group/search",
                data: {
                    "Search": "",
                    "includeDeleted": this.state.includeDeletedEmployee,
                    "startingIndex": 0,
                    "itemCount": 1000
                },
                onSuccess: data => {
                    this.setState({ groupList: data.content, isDepartmentLoading: false });
                },
                finally: () => { }
            })
        }

    handleScrollToBottom = () => {
        // Check if we're already loading new data or if all employees are already loaded
        if (!this.state.isLoading) {
            this.state.page = this.state.page + 1;
            this.loadEmployees(); 
        }
    }

    handleDivisionChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        this.setState({ DivisionIds: ids, searcDivisionQuery: '', selectedEmployees: [], page: 1 },() =>{ this.loadEmployees() } ) ;
    }
    handleDepartmentChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        this.setState({ DepartmentIds: ids, searcDepartmentQuery: '', selectedEmployees: [], page: 1 },() =>{ this.loadEmployees() } );
    }
    handleLocationChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        this.setState({ LocationIds: ids, searcLocationQuery: '', selectedEmployees: [], page: 1 }, () => { this.loadEmployees() });
    }
    handlePositionChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        this.setState({ PositionIds: ids, searchPositionQuery: '', selectedEmployees: [], page: 1 },() =>{ this.loadEmployees() } );
    }
    handleCostCenterChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        this.setState({ CostCenterIds: ids, searchCostCenterQuery: '', selectedEmployees: [], page: 1 },() =>{ this.loadEmployees() } );
    }

    // getData = (input, callback) => {
    //     if(input.includes('\\'))
    //         input = input.split('\\').join("");

    //     fnSearchEmployee(input, data => {
    //         var emps = [];
    //         data.content.map(item => {
    //             emps.push({
    //                 value: item._id,
    //                 label: item.LastName + ', ' + item.FirstName + ' ' + item.MiddleName, accessNumber: item.AccessNumber
    //             });
    //         })
    //         this.setState({empSelectList:emps});
    //         // callback(null, {
    //         //     options: emps
    //         // });
    //     }, 0, 10, false);
    // }

    onCheckboxChange = (event, ctr) => {
        this.setState({ selectAll: ctr.checked });
    }

    render = () => {
        return (
            <Modal open size='tiny'>
                <Modal.Header>{this.props.title}</Modal.Header>
                <Modal.Content>
                    {this.props.allowSelectAll &&
                        <Checkbox toggle
                            onChange={this.onCheckboxChange}
                            checked={this.state.selectAll}
                            label="Select all employees"
                        />
                    }
                    <br />
                    <br />
                    <Modal.Description>{this.props.caption}</Modal.Description>
                    <br />
                    <br />
                    Division
                    <Select disabled={this.state.started || this.state.selectAll} placeholder='Division'
                        onChange={this.handleDivisionChange.bind(this)}
                        value={groupMap(this.state.groupList, "DIVISION", null).filter(x => this.state.DivisionIds.includes(x.value))}
                        options={groupMap(this.state.groupList, "DIVISION", null)}
                        isMulti
                    />
                    <br />
                    Department
                    <Select disabled={this.state.started || this.state.selectAll} placeholder='Department'
                        onChange={this.handleDepartmentChange.bind(this)}
                        value={groupMap(this.state.groupList, "DEPARTMENT", null).filter(x => this.state.DepartmentIds.includes(x.value))}
                        options={groupMap(this.state.groupList, "DEPARTMENT", null)}
                        isMulti
                    />
                    <br />
                    Position
                    <Select disabled={this.state.started || this.state.selectAll} placeholder='Position'
                        onChange={this.handlePositionChange.bind(this)}
                        value={groupMap(this.state.groupList, "POSITION", null).filter(x => this.state.PositionIds.includes(x.value))}
                        options={groupMap(this.state.groupList, "POSITION", null)}
                        isMulti
                    />
                    <br />
                    Location
                    <Select disabled={this.state.started || this.state.selectAll} placeholder='Location'
                        onChange={this.handleLocationChange.bind(this)}
                        value={groupMap(this.state.groupList, "CITY", null).filter(x => this.state.LocationIds.includes(x.value))}
                        options={groupMap(this.state.groupList, "CITY", null)}
                        isMulti
                    />
                    <br />
                    Cost Center
                    <Select disabled={this.state.started || this.state.selectAll} placeholder='Cost Center'
                        onChange={this.handleCostCenterChange.bind(this)}
                        value={groupMap(this.state.groupList, "COST_CENTER", null).filter(x => this.state.CostCenterIds.includes(x.value))}
                        options={groupMap(this.state.groupList, "COST_CENTER", null)}
                        isMulti
                    />
                    <br />
                    Employee
                    <Select disabled={this.state.started || this.state.selectAll} placeholder='Employee'
                        value={this.state.EmployeeIds}
                        onChange={this.handleSearchEmployeeChange.bind(this)}
                        options={employeeSearchlist(this.state.Employeelist)}
                        onInputChange={this.handleSearchChange.bind(this)}
                        isMulti
                        onMenuScrollToBottom={this.handleScrollToBottom}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon='check' disabled={this.state.busy || (this.state.EmployeeIds.length == 0 && !this.state.selectAll)}
                        content={this.props.action} oading={this.state.busy} onClick={this.handleAction.bind(this)}>
                    </Button>
                    <Button basic icon='cancel' content='Cancel' onClick={this.handleClose} disabled={this.state.busy}></Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

export default SelectEmployeeForDevice