import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Button, Table, Modal, Tab, Menu, Input, Icon, ButtonGroup, Pagination, Image, Label, Card, Grid, Loader, Visibility } from 'semantic-ui-react';
import { ajaxPost } from '../../ajax';
import { isTaskAvailable, handleNumeric, empty_id, customStyles } from '../../utils';
import Select from 'react-select';
import modStyles from './GroupList.module.css';
import GroupImage from "../../Commons/GroupImage";
import ErrorMessage from '../../Commons/ErrorMessage';

function mapGroupList(groupList) {
    var list = [];
    if (groupList !== undefined) {
        groupList.map(x => {
            return (
                list.push({ label: x.Name, value: x._id }))
        })
    }
    return list;
}

var newGroup = {
    _id: empty_id,
    Name: "",
    Type: "",
    Deleted: false,
    OtherDetails: [],
}

const companyDetails = {
    Code: "",
    TelephoneNo: "",
    MobileNo: "",
    Email: "",
    Website: "",
    UnitNo: "",
    BlockNo: "",
    BuildingName: "",
    StreetName: "",
    District: "",
    Subdivision: "",
    City: "",
    Province: "",
    ZipCode: "",
    TINNumber: "",
    SSSNumber: "",
    HDMFNumber: "",
    GSISNumber:"",
    PhilhealthNumber: "",
    SSSBranchCode: "",
    HDMFBranchCode: "",
    PhilhealthBranchCode: "",
    GSISBranchCode:"",
    RDOCode: "",
    ContactNumber: "",
    BankID: empty_id,
    AccountNumber: "",
    LineOfBusiness: "",
    Image:"",
    file:""
}

const jobDetails = {
    Code: "",
    Qualifications: "",
    JobDescription: "",
    WorkLocation: ""
}

const positionDetails = {
    Code: "",
}

const salaryGradeDetails = {
    Code: "",
    Level: "",
    Amount: ""
}

const defaultDetails = {
    Code: ""
}

class GroupList extends Component {

    state = {
        groupTypes: {
            COMPANY: "COMPANY",
            DEPARTMENT: "DEPARTMENT",
            BRANCH: "BRANCH",
            DIVISION: "DIVISION",
            POSITION: "POSITION",
            CITY: "CITY",
            COST_CENTER: "COST CENTER",
            //SALARY_GRADE: "SALARY GRADE",
        },
        isLoading: false,
        isFirstLoad: true,
        isSaving: false,
        isNameError: false,
        isCodeError: false,
        groups: [],
        modalModifyOpen: false,
        modalDeleteOpen: false,
        size: "mini",
        displayGroup: "",
        displayAction: "",
        type: "COMPANY",
        selectedGroup: {
            Name: "",
            Type: "",
            Code: "",
            Deleted: false,
            OtherDetails: []
        },
        isImageHover: false,
        isBankLoading: false,
        bankList: [],
        showErrorMessage: false,
        errorMessage: '',
        employeeList: [],
        employeePanelTitle: '',
        showEmployeePanel: false
    }

    componentWillMount() {
        if (this.props.prevState != null)
            this.state = this.props.prevState;

        this.getInitialData();

        this.setState({
            canAdd: isTaskAvailable("GROUP_ADD") || isTaskAvailable("HR_ROLE"),
            canEdit: isTaskAvailable("GROUP_EDIT") || isTaskAvailable("HR_ROLE"),
            canDelete: isTaskAvailable("GROUP_DELETE") || isTaskAvailable("HR_ROLE")
        });

        this.searchTimer = null;
    }

    componentDidUpdate() {
        this.getInitialData();
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, "GroupModule");
    }

    toTitleCase(str) {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    transformToTitle(titleField) {
        var abbrvString = "";
        if (titleField.startsWith("TIN", 0) || titleField.startsWith("SSS", 0)) {
            abbrvString = titleField.substring(0, 3) + " ";
            titleField = titleField.substring(3, titleField.length)
        }

        if (titleField.startsWith("HDMF", 0) || titleField.startsWith("GSIS", 0)) {
            abbrvString = titleField.substring(0, 4) + " ";
            titleField = titleField.substring(4, titleField.length)
        }


        titleField = titleField.split("_").join(" ");
        titleField = titleField.replace(/([a-z])([A-Z])/g, '$1 $2');
        titleField = this.toTitleCase(titleField);

        return abbrvString + titleField;
    }

    loadBanks() {
        this.setState({ isBankLoading: true })
        ajaxPost({
            url: 'api/PayrollBank/payrollBankNames',
            data: {},
            onSuccess: (data) => {
                this.setState({ bankList: data.map(x => { return ({ label: x.Description, value: x.id }) }) });
            },
            finally: () => { this.setState({ isBankLoading: false }) }
        })
    }

    getInitialData() {
        if (this.state.isFirstLoad === true) {
            //creating a state for pagination active page

            Object.keys(this.state.groupTypes).forEach(element => {
                this.setState({
                    ["selectedFilter_" + element]: [],
                    ["filterGroup_" + element]: [],
                    ["activePage_" + element]: 1
                })
            }, () => this.searchFilter("", ""));

            this.setState({ isFirstLoad: false });
            this.setState({ isLoading: true });
            ajaxPost({
                url: "api/Group/search",
                data: {
                    "Search": "",
                    "includeDeleted": false,
                    "startingIndex": 0,
                    "itemCount": 1000
                },
                onSuccess: data => {
                    this.setState({ groups: data.content, isLoading: false });
                },
                finally: () => { }
            })

            this.loadBanks();
        }
    }

    onCreateNewGroupClick = (groupType) => {
        var grp = newGroup;
        if (groupType === "COMPANY") {
            grp["OtherDetails"] = companyDetails;
            this.loadBanks();
        }
        else if (groupType === "POSITION") {
            grp["OtherDetails"] = positionDetails;
        }
        else if (groupType === "SALARY_GRADE") {
            grp["OtherDetails"] = salaryGradeDetails;
        }
        else {
            grp["OtherDetails"] = defaultDetails;
        }

        this.setState({
            displayGroup: groupType,
            displayAction: "ADD NEW",
            selectedGroup: JSON.parse(JSON.stringify(grp)),
            selectedPrevState: null,
            modalModifyOpen: true,
            isCodeError: false,
            isNameError: false
        })
    }

    onModifyGroupClick = (group) => {
        this.setState({
            displayGroup: group.Type,
            displayAction: "EDIT",
            modalModifyOpen: true,
            selectedPrevState: JSON.parse(JSON.stringify(group)),
            selectedGroup: JSON.parse(JSON.stringify(group)), 
            showErrorMessage: false
        })
    }

    onDeleteGroupClick = (groupType) => {
        this.setState({
            displayGroup: groupType,
            displayAction: "DELETE",
            modalDeleteOpen: true, 
            showErrorMessage: false
        })
    }

    handleChange = name => event => {
        if (name === "Name" && event.target.value === "") {
            this.setState({ isNameError: true });
        }
        else if (name === "Name") {
            this.setState({ isNameError: false });
        }

        var selectedGroup = this.state.selectedGroup;
        selectedGroup[name] = event.target.value;
        this.setState({ selectedGroup });
    }

    handleDetailsChange = (name, isNumberOnly) => event => {
        var selectedGroup = this.state.selectedGroup;
        selectedGroup.OtherDetails[name] = isNumberOnly ? handleNumeric(event) : event.target.value;
        this.setState({ selectedGroup });
    }

    handleBankChange = (value) => {
        var selectedGroup = this.state.selectedGroup;
        value != null ? selectedGroup.OtherDetails["BankID"] = value.value : selectedGroup.OtherDetails["BankID"] = empty_id
        this.setState({ selectedGroup });
    }

    cancelClick = () => {
        if (this.state.selectedPrevState != null) {
            this.setState({ selectedGroup: JSON.parse(JSON.stringify(this.state.selectedPrevState)) })
        }
        this.setState({ modalModifyOpen: false })
    }

    onSearch = (type, text) => {
        if (this.state["search_" + type] !== text) {
            clearTimeout(this.searchTimer);
            this.setState({ ["search_" + type]: text, type }, () => {
                this.searchTimer = setTimeout(this.searchFilter, 400, text, type)
            });
        }
    }

    searchFilter = (str, selectedType) => {
        this.setState({ isSearchLoading: true });
        // var selectedType = this.state.type;
        var types = [];
        types.push(selectedType);

        // var str = this.state["search_" + selectedType];

        ajaxPost({
            url: "api/Group/search",
            data: {
                "Search": str,
                "includeDeleted": false,
                "startingIndex": 0,
                "itemCount": 10,
                "Types": types,
            },
            onSuccess: data => {
                //var selectedValue = this.state["selectedFilter_" + selectedType]
                //var oldValue = this.state["filterGroup_" + selectedType].filter(x=> selectedValue.includes(x._id))
                // oldValue.forEach(x => {
                //     data.content.push(x)
                // });
                this.setState({
                    ["filterGroup_" + selectedType]: data.content,
                    isSearchLoading: false,
                });
            },
            finally: () => { }
        })
    }

    onSelectGroups = (value) => {
        this.setState({ ["selectedFilter_" + this.state.type]: value }, () => {
            // this.doSearch()
            this.searchFilter("", this.state.type)
        })
    }

    doSearch = () => {
        this.setState({ isLoading: true });
        var selectedType = this.state.type;
        var grpIds = this.state["selectedFilter_" + selectedType].map(x => x.value);
        var types = [];
        types.push(selectedType);
        ajaxPost({
            url: "api/Group/search",
            data: {
                "Search": "",
                "includeDeleted": false,
                "startingIndex": 0,
                "itemCount": 200,
                "Types": types,
                "GroupIds": grpIds,
            },
            onSuccess: data => {
                var newGroup = this.state.groups
                newGroup = newGroup.filter((a) => a.Type !== selectedType)

                newGroup = newGroup.concat(data.content);
                this.setState({
                    groups: newGroup,
                    isLoading: false,
                });
            },
            finally: () => { }
        })
    }

    viewClick = (group) => {
        this.setState({ isLoading: true });
        var selectedType = this.state.type;
        var grpIds = this.state["selectedFilter_" + selectedType].map(x => x.value);
        var types = [];
        types.push(selectedType);
        ajaxPost({
            url: "api/Group/viewTaggedEmployee",
            data: {
                "_id": group._id,
            },
            onSuccess: data => {
                this.setState({
                    employeeList: data, 
                    isLoading: false, 
                    showEmployeePanel: true,
                    employeePanelTitle: group.Name
                })
            },
            finally: () => { }
        })
    }

    saveClick = () => {
        if (this.state.isSaving === false) {
            var newGroupList = this.state.groups;
            var groupModify = this.state.selectedGroup;
            groupModify.Type = this.state.displayGroup;

            if (groupModify.Name === "") {
                alert("Please fill up required fields.")
                this.setState({ isNameError: true });
                return;
            }

            if (groupModify.OtherDetails.Code === "") {
                alert("Please fill up required fields.")
                this.setState({ isCodeError: true });
                return;
            }

            this.setState({ isSaving: true });
            var index = -1;
            var isSaveSuccess = true;
            ajaxPost({
                url: 'api/Group/save',
                data: {
                    group: groupModify
                },
                onSuccess: data => {
                    if ("errors" in data && data.errors.length > 0) {
                        alert(data.errors[0])
                        this.setState({ isSaving: false });
                        isSaveSuccess = false;
                        return;
                    }
                    groupModify = data.content;
                },
                finally: () => {
                    if (isSaveSuccess) {
                        index = newGroupList.map(grp => grp._id).indexOf(groupModify._id);
                        if (index === -1) {
                            newGroupList.push(groupModify);
                            this.setState({ groups: newGroupList });
                        }
                        else {
                            newGroupList[index] = groupModify
                        }
                        this.setState({
                            modalModifyOpen: false,
                            isSaving: false
                        })
                    }
                }
            })
        }
    }

    deleteClick = (group) => {
        var { groups } = this.state;
        ajaxPost({
            url: "api/Group/delete",
            data: {
                "_id": group._id,
            },
            onSuccess: data => {
                if(data === "") {
                    groups.splice(groups.map(grp => grp._id).indexOf(group._id), 1);
                    this.setState({
                        modalDeleteOpen: false,
                        groups,
                        isSaving: false
                    })
                }
                else {
                    this.setState({
                        modalDeleteOpen: false,
                        showErrorMessage: true, 
                        errorMessage: data
                    })
                }
            },
            finally: () => { }
        })
    }

    getMenuName = (lbl) => {
        if (lbl === "DEPARTMENT") {
            if (window.outerWidth < 500)
                return "DEPT";
            else
                return "DEPARTMENT";
        }
        else
            return lbl;
    }

    onClickSearch = (group) => {
        this.setState({ type: group, showErrorMessage: false }, () => this.doSearch());
    }

    displaySpecialCases(name, detail) {
        if (name === "BankID") {
            return (
                <Grid.Column>
                    <span style={{ 'font-weight': 'bold', fontSize: '16px' }}>Bank Name: </span>
                    <span>{this.state.bankList.find(x => x.value === detail[name]) != undefined ? this.state.bankList.find(x => x.value === detail[name]).label : ""}</span>
                </Grid.Column>
            )
        }
        else if(name === "Image" || name === "File"){
            return;
        }

        return (
            <Grid.Column>
                <span style={{ 'font-weight': 'bold', fontSize: '16px' }}>{this.transformToTitle(name)}: </span>
                <span>{detail[name]}</span>
            </Grid.Column>
        )
    }
    uploadHandler = () => {
        document.getElementById("myFile").click();
      };
      onMouseEnter() {
        this.setState({ isImageHover: true });
      }
     onDeleteLogo(){
        var current = this.state.selectedGroup;
        current.OtherDetails["file"] = undefined;
        current.OtherDetails["Image"] = undefined;
        this.setState({ selectedGroup: current }); 
     }
      onMouseLeave() {
        this.setState({ isImageHover: false });
      }
      fileChangedHandler = (event) => {
        var img = event.target.files[0];
        if (img === undefined) return;
        if(!img.type.match(/image-*/)){
            alert('Invalid format');
            return;
        }
        if (img.size > 1572864) {
          alert("Image too large. Image file must be below 1.5MB");
          return;
        }
        if(img.size === 0){
            alert("Image too small. Image file must be not 0MB");
            return;
        }
        this.readDataUrl(img, this.onload.bind(this));
      };
    
      readDataUrl(img, callback) {
        var reader = new FileReader();
        reader.onload = function () {
          var dataURL = reader.result;
          callback(dataURL);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
        reader.readAsDataURL(img);
      }
    
      onload(result) {
        var current = this.state.selectedGroup;
        current.OtherDetails["file"] = result;
        current.OtherDetails["Image"] = result;
        this.setState({ selectedGroup: current });
      }

      onTabChange = () => {
        this.setState({showErrorMessage: false})
      }

    render() {
        const { groups, groupTypes, selectedGroup } = this.state;
        const { OtherDetails } = this.state.selectedGroup;
        const { modalModifyOpen, modalDeleteOpen, size } = this.state;
        const { bankList, isBankLoading } = this.state;

        var panes = []
        //delete groupTypes["COMPANY"];
        Object.keys(groupTypes).map(item => {
            return (panes.push({

                menuItem: this.getMenuName(groupTypes[item]), render: () => <Tab.Pane style={{ border: 'none' }}>
                    <label style={{ fontWeight: 'bold', fontSize: '1.3em', color: "#606060" }}>{groupTypes[item]}</label>
                    <div>
                        <Pagination
                            className='w3-hide-small'
                            floated='right'
                            defaultActivePage={1}
                            activePage={this.state["activePage_" + item]}
                            pointing
                            secondary
                            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }}
                            size='mini'
                            siblingRange={2}
                            boundaryRange={0}
                            totalPages={Math.ceil(groups.filter((e) => e.Type === item).length / 10)}
                            onPageChange={(e, { activePage }) => this.setState({ ["activePage_" + item]: activePage, showErrorMessage: false })}
                        />
                        <Pagination
                            className='w3-hide-medium w3-hide-large'
                            floated='right'
                            defaultActivePage={1}
                            activePage={this.state["activePage_" + item]}
                            pointing
                            secondary
                            firstItem={null}
                            lastItem={null}
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }}
                            size='mini'
                            siblingRange={1}
                            boundaryRange={0}
                            totalPages={Math.ceil(groups.filter((e) => e.Type === item).length / 10)}
                            onPageChange={(e, { activePage }) => this.setState({ ["activePage_" + item]: activePage, showErrorMessage: false })}
                        />
                    </div>
                    {window.outerWidth >= 700 && <div>
                        <Menu secondary size='mini' style={{ verticalAlign: 'middle', padding: 'auto' }}>
                            <Menu.Menu position='left'>
                                <Menu.Item fitted="horizontally">
                                    <Button floated='left' size='mini' onClick={this.onCreateNewGroupClick.bind(this, item)} disabled={!this.state.canAdd}
                                        style={{ 'max-height': '31px' }}> <Icon name='plus' />New</Button>
                                    {this.state.isLoading && <Button floated='left' style={{ border: 'none', 'background-color': 'transparent', cursor: 'arrow', 'max-height': '31px' }}
                                        size='tiny' loading></Button>}
                                </Menu.Item>
                            </Menu.Menu>
                            <Menu.Menu position='right'>
                                <Menu.Item fitted="horizontally">
                                    <div style={{ width: 300 }}>
                                        <Select
                                            placeholder='Search'
                                            value={this.state["selectedFilter_" + item]}
                                            options={mapGroupList(this.state["filterGroup_" + item])}
                                            onChange={this.onSelectGroups.bind(this)}
                                            onInputChange={this.onSearch.bind(this, item)}
                                            isMulti
                                            styles={customStyles}
                                            isLoading={this.state.isSearchLoading}
                                        />
                                    </div>

                                    <Button floated='right' size='mini' type='submit' onClick={this.onClickSearch.bind(this, item)} style={{ 'max-height': '31px', marginLeft: '10px' }}>Search</Button>
                                </Menu.Item>
                            </Menu.Menu>
                        </Menu>
                        <ErrorMessage open={this.state.showErrorMessage} size="mini" title="Invalid" caption={this.state.errorMessage}/>
                    </div>}
                    {window.outerWidth < 700 && <div>
                        <div style={{ width: '100%', marginTop: '10px' }}>
                            <Button fluid size='mini' onClick={this.onCreateNewGroupClick.bind(this, item)} disabled={!this.state.canAdd}
                                style={{ 'max-height': '31px' }}> <Icon name='plus' />New</Button>
                        </div>
                        <div style={{ width: '100%', marginTop: '10px' }}>
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <td style={{ width: '90%' }}>
                                        <Select
                                            placeholder='Search'
                                            value={this.state["selectedFilter_" + item]}
                                            options={mapGroupList(this.state["filterGroup_" + item])}
                                            onChange={this.onSelectGroups.bind(this)}
                                            onInputChange={this.onSearch.bind(this, item)}
                                            isMulti
                                            styles={customStyles}
                                            isLoading={this.state.isSearchLoading}
                                        />
                                    </td>
                                    <td style={{ width: '10%' }}>
                                        <Button fluid size='mini' type='submit' onClick={this.doSearch.bind(this)}
                                            style={{ 'max-height': '31px' }} loading={this.state.isLoading} icon='search' />
                                    </td>
                                </tr>
                            </table>

                        </div>
                    </div>}
                    <hr style={{ color: "#606060", borderWidth: '1px', width:"100%" }} />
                    <Card.Group style={{overflow:"auto", margin:"0 0 2em 0"}}>
                        {groups != null && groups.length > 0 && groups.find((e) => e.Type === item) != null &&
                            groups.filter((e) => e.Type === item).slice((this.state["activePage_" + item] * 10) - 10, (this.state["activePage_" + item] * 10))
                                .map(det => {
                                    return (
                                        <Card fluid>
                                            <Card.Content>
                                                <Grid columns={5} doubling stackable style={{ width: '100%' }}>
                                                    <Grid.Column>
                                                        <p>
                                                            <span style={{ 'font-weight': 'bold', fontSize: '16px' }}>Group Name: </span>
                                                            <span>{det.Name}</span>
                                                        </p>
                                                    </Grid.Column>


                                                    {Object.keys(det.OtherDetails).map(otherDet => {
                                                        return (this.displaySpecialCases(otherDet, det.OtherDetails))
                                                    })}

                                                </Grid>
                                            </Card.Content>
                                            <Card.Description>
                                                <ButtonGroup fluid={window.innerWidth < 800} size='mini' floated='right' horizontal style={{ marginBottom: '10px' }}>
                                                    <Button onClick={this.viewClick.bind(this, det)} content='View' style={{ margin: '2px', minWidth: '100px' }}/>
                                                    <Button onClick={this.onModifyGroupClick.bind(this, det)} disabled={!this.state.canEdit} icon='edit' content='Edit' style={{ margin: '2px', minWidth: '100px' }} />
                                                    <Button onClick={this.onDeleteGroupClick.bind(this, det)} disabled={!this.state.canDelete} icon='cancel' content='Delete' style={{ margin: '2px', minWidth: '100px' }} />
                                                </ButtonGroup>
                                            </Card.Description>
                                        </Card>
                                    )
                                })
                        }
                    </Card.Group>
                </Tab.Pane>
            }))
        })

        return <>
            <div className={modStyles.container}>
                <div style={{ color: "#606060", marginTop: '1%' }}>
                    <h2>Groups</h2>
                </div>
                
                <Tab panes={panes} size='mini' menu={{ text: true, vertical: window.outerWidth < 800 ? true : false, tabular: true, attached: true }} onTabChange={this.onTabChange}/>
                {this.state.displayGroup !== "COMPANY" && <Modal size={size} open={modalModifyOpen} onClose={this.close} style={{ 'width': '340px' }}>
                    <Modal.Header>Group</Modal.Header>
                    <Modal.Content>
                        {modalModifyOpen && <h3>{this.state.displayAction} {this.state.displayGroup.replace('_', ' ')}</h3>}
                        <Input labelPosition='left' type='text' placeholder='Group Name' error={this.state.isNameError}>
                            <Label style={{ width: '120px' }}>Name *</Label>
                            <input style={{ width: '180px' }} value={selectedGroup.Name} onChange={this.handleChange("Name").bind(this)} />
                        </Input>
                        {Object.keys(OtherDetails).map(det => {
                            return (
                                <Input labelPosition='left' type='text' placeholder={det} style={{ marginTop: '5px' }} error={det === "Code" && this.state.isCodeError}>
                                    <Label style={{ width: '120px' }}>{this.transformToTitle(det)}{det === "Code" && " *"}</Label>
                                    <input style={{ width: '180px' }} value={OtherDetails[det]} onChange={this.handleDetailsChange(det, det === "Amount" ? true : false).bind(this)} />
                                </Input>
                            )
                        })}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic onClick={this.saveClick.bind(this)} loading={this.state.isSaving} icon='save' content='Save' />
                        <Button basic onClick={this.cancelClick.bind(this)} icon='cancel' content='Cancel' />
                    </Modal.Actions>
                </Modal>}

                {this.state.displayGroup === "COMPANY" && <Modal size='small' open={modalModifyOpen} onClose={this.close}>
                    <Modal.Header>Group</Modal.Header>
                    <Modal.Content>
                        {modalModifyOpen && <h3>{this.state.displayAction} {this.state.displayGroup.replace('_', ' ')}</h3>}
                         
                            <input type="file" accept="image/png, image/jpeg" id="myFile" onChange={this.fileChangedHandler} style={{ display: "none" }} />
                            <GroupImage
                                id={selectedGroup._id}
                                fileName={selectedGroup.OtherDetails.Image}
                                runtime={selectedGroup.OtherDetails.file}
                                style={{ width: "75px", height: "75px", cursor: "pointer", margin: "1em" }}
                                onClick={this.uploadHandler}
                                onMouseEnter={this.onMouseEnter.bind(this)}
                                onMouseOut={this.onMouseLeave.bind(this)}
                                onDelete={this.onDeleteLogo.bind(this)}
                                avatar
                            /> 
                                <Input fluid style={{ marginTop: '5px' }} labelPosition='left' type='text' placeholder='Name' error={this.state.isNameError}>
                                <Label style={{ width: '120px' }}>Name *</Label>
                                <input style={{ width: '100%' }} value={selectedGroup.Name} onChange={this.handleChange("Name").bind(this)} />
                                </Input>
                                <Input fluid style={{ marginTop: '5px' }} labelPosition='left' type='text' placeholder='Code' error={this.state.isCodeError}>
                                    <Label style={{ width: '120px' }}>Code *</Label>
                                    <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.Code} onChange={this.handleDetailsChange("Code").bind(this)} />
                                </Input> 
                        
                       
                        <div style={{ border: '1px solid lightgray', marginTop: '8px' }}>
                            <Input fluid labelPosition='left' type='text' placeholder='Telephone No.'>
                                <Label style={{ width: '120px' }}>Telephone No.</Label>
                                <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.TelephoneNo} onChange={this.handleDetailsChange("TelephoneNo").bind(this)} />
                            </Input>
                            <Input fluid labelPosition='left' type='text' placeholder='Line of Business'>
                                <Label style={{ width: '120px' }}>Line of Business</Label>
                                <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.LineOfBusiness} onChange={this.handleDetailsChange("LineOfBusiness").bind(this)} />
                            </Input>
                            <Input fluid style={{ marginTop: '5px' }} labelPosition='left' type='text' placeholder='Mobile No.'>
                                <Label style={{ width: '120px' }}>Cellphone No.</Label>
                                <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.MobileNo} onChange={this.handleDetailsChange("MobileNo").bind(this)} />
                            </Input>
                            <Input fluid style={{ marginTop: '5px' }} labelPosition='left' type='text' placeholder='Email'>
                                <Label style={{ width: '120px' }}>Email</Label>
                                <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.Email} onChange={this.handleDetailsChange("Email").bind(this)} />
                            </Input>
                            <Input fluid style={{ marginTop: '5px' }} labelPosition='left' type='text' placeholder='Website'>
                                <Label style={{ width: '120px' }}>Website</Label>
                                <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.Website} onChange={this.handleDetailsChange("Website").bind(this)} />
                            </Input>

                            <div className='w3-row'>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Input fluid labelPosition='left' type='text' placeholder='SSS'>
                                        <Label style={{ width: '120px' }}>SSS No.</Label>
                                        <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.SSSNumber} onChange={this.handleDetailsChange("SSSNumber").bind(this)} />
                                    </Input>
                                </div>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Input fluid labelPosition='left' type='text' placeholder='Branch Code'>
                                        <Label style={{ width: '120px' }}>Branch Code</Label>
                                        <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.SSSBranchCode} onChange={this.handleDetailsChange("SSSBranchCode").bind(this)} />
                                    </Input>
                                </div>
                            </div>

                            <div className='w3-row'>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Input fluid labelPosition='left' type='text' placeholder='Philhealth No.'>
                                        <Label style={{ width: '120px' }}>Philhealth No.</Label>
                                        <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.PhilhealthNumber} onChange={this.handleDetailsChange("PhilhealthNumber").bind(this)} />
                                    </Input>
                                </div>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Input fluid labelPosition='left' type='text' placeholder='Branch Code'>
                                        <Label style={{ width: '120px' }}>Branch Code</Label>
                                        <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.PhilhealthBranchCode} onChange={this.handleDetailsChange("PhilhealthBranchCode").bind(this)} />
                                    </Input>
                                </div>
                            </div>

                            <div className='w3-row'>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Input fluid labelPosition='left' type='text' placeholder='HDMF No.'>
                                        <Label style={{ width: '120px' }}>HDMF No.</Label>
                                        <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.HDMFNumber} onChange={this.handleDetailsChange("HDMFNumber").bind(this)} />
                                    </Input>
                                </div>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Input fluid labelPosition='left' type='text' placeholder='Branch Code'>
                                        <Label style={{ width: '120px' }}>Branch Code</Label>
                                        <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.HDMFBranchCode} onChange={this.handleDetailsChange("HDMFBranchCode").bind(this)} />
                                    </Input>
                                </div>
                            </div>

                            <div className='w3-row'>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Input fluid labelPosition='left' type='text' placeholder='GSIS No.'>
                                        <Label style={{ width: '120px' }}>GSIS No.</Label>
                                        <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.GSISNumber} onChange={this.handleDetailsChange("GSISNumber").bind(this)} />
                                    </Input>
                                </div>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Input fluid labelPosition='left' type='text' placeholder='Branch Code'>
                                        <Label style={{ width: '120px' }}>Branch Code</Label>
                                        <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.GSISBranchCode} onChange={this.handleDetailsChange("GSISBranchCode").bind(this)} />
                                    </Input>
                                </div>
                            </div>

                            <div className='w3-row'>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Input fluid labelPosition='left' type='text' placeholder='TIN No.'>
                                        <Label style={{ width: '120px' }}>TIN No.</Label>
                                        <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.TINNumber} onChange={this.handleDetailsChange("TINNumber").bind(this)} />
                                    </Input>
                                </div>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Input fluid labelPosition='left' type='text' placeholder='Branch Code'>
                                        <Label style={{ width: '120px' }}>RDO Code</Label>
                                        <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.RDOCode} onChange={this.handleDetailsChange("RDOCode").bind(this)} />
                                    </Input>
                                </div>
                            </div>

                            <div className='w3-row'>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Input fluid labelPosition='left' type='text' placeholder='Bank'>
                                        <Label style={{ width: '120px' }}>Bank</Label>
                                        <div style={{ width: '100%' }}>
                                            <Select style={{ width: '100%' }} isLoading={isBankLoading} placeholder='Bank' options={bankList} value={bankList.find(x => x.value === selectedGroup.OtherDetails.BankID)} onChange={this.handleBankChange.bind(this)} isClearable />
                                        </div>
                                    </Input>
                                </div>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Input fluid labelPosition='left' type='text' placeholder='Account No.'>
                                        <Label style={{ width: '120px' }}>Account No.</Label>
                                        <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.AccountNumber} onChange={this.handleDetailsChange("AccountNumber").bind(this)} />
                                    </Input>
                                </div>
                            </div>
                        </div>

                        <div style={{ border: '1px solid lightgray', marginTop: '8px' }}>
                            <div className='w3-row'>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Input fluid labelPosition='left' type='text' placeholder='Unit No.'>
                                        <Label style={{ width: '120px' }}>Unit No.</Label>
                                        <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.UnitNo} onChange={this.handleDetailsChange("UnitNo").bind(this)} />
                                    </Input>
                                </div>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Input fluid labelPosition='left' type='text' placeholder='Building Name'>
                                        <Label style={{ width: '120px' }}>Building Name</Label>
                                        <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.BuildingName} onChange={this.handleDetailsChange("BuildingName").bind(this)} />
                                    </Input>
                                </div>
                            </div>

                            <div className='w3-row'>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Input fluid labelPosition='left' type='text' placeholder='Block No.'>
                                        <Label style={{ width: '120px' }}>House/Lot</Label>
                                        <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.BlockNo} onChange={this.handleDetailsChange("BlockNo").bind(this)} />
                                    </Input>
                                </div>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Input fluid labelPosition='left' type='text' placeholder='Street Name'>
                                        <Label style={{ width: '120px' }}>Street Name</Label>
                                        <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.StreetName} onChange={this.handleDetailsChange("StreetName").bind(this)} />
                                    </Input>
                                </div>
                            </div>

                            <Input fluid labelPosition='left' style={{ marginTop: '5px' }} type='text' placeholder='District'>
                                <Label style={{ width: '150px' }}>Barangay / District</Label>
                                <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.District} onChange={this.handleDetailsChange("District").bind(this)} />
                            </Input>
                            <Input fluid labelPosition='left' style={{ marginTop: '5px' }} type='text' placeholder='Subdivision'>
                                <Label style={{ width: '150px' }}>Subdivision</Label>
                                <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.Subdivision} onChange={this.handleDetailsChange("Subdivision").bind(this)} />
                            </Input>
                            <Input fluid labelPosition='left' style={{ marginTop: '5px' }} type='text' placeholder='City'>
                                <Label style={{ width: '150px' }}>City</Label>
                                <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.City} onChange={this.handleDetailsChange("City").bind(this)} />
                            </Input>
                            <Input fluid labelPosition='left' style={{ marginTop: '5px' }} type='text' placeholder='Province'>
                                <Label style={{ width: '150px' }}>Province</Label>
                                <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.Province} onChange={this.handleDetailsChange("Province").bind(this)} />
                            </Input>
                            <Input fluid labelPosition='left' style={{ marginTop: '5px' }} type='text' placeholder='Zip Code'>
                                <Label style={{ width: '150px' }}>Zip Code</Label>
                                <input style={{ width: '100%' }} value={selectedGroup.OtherDetails.ZipCode} onChange={this.handleDetailsChange("ZipCode").bind(this)} />
                            </Input>
                        </div>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic onClick={this.saveClick.bind(this)} loading={this.state.isSaving} icon='save' content='Save' />
                        <Button basic onClick={this.cancelClick.bind(this)} icon='cancel' content='Cancel' />
                    </Modal.Actions>
                </Modal>}
            </div>
            <Modal size={size} open={modalDeleteOpen} onClose={this.close}>
                <Modal.Header>Delete Group</Modal.Header>
                <Modal.Content>
                    <p>Are you sure you want to delete? </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic onClick={this.deleteClick.bind(this, this.state.displayGroup)} loading={this.state.isSaving} icon='check' content='Yes' />
                    <Button basic onClick={() => this.setState({ modalDeleteOpen: false })} icon='cancel' content='No' />
                </Modal.Actions>
            </Modal>

            <Modal size={size} open={this.state.showEmployeePanel}>
                <Modal.Header>{this.state.employeePanelTitle}</Modal.Header>
                <Modal.Content style={{ maxHeight: '200px', overflowY: 'auto' }}>
                        {this.state.isLoading && <Loader/>}
                        <div style={{color:"#888888"}}>{this.state.employeeList.length === 0? "No assigned employee.": ""}</div>
                        {this.state.employeeList.map(emp => <div>{emp}</div>)}
                </Modal.Content>
                <Modal.Actions>
                    <Button basic onClick={() => this.setState({ showEmployeePanel: false })} content='Close' />
                </Modal.Actions>
            </Modal>
        </>
    }
}

GroupList.getViewID = () => { return "GroupModule" };
GroupList.getCaption = () => { return "Group Management" }
GroupList.getViewAccessKey = () => { return isTaskAvailable("GROUP_VIEW") || isTaskAvailable("GROUP_ADD") }
GroupList.getIcon = () => {
    return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Group_Management.png' avatar />);
}
GroupList.getIconSrc = (size) => {
    return '/Icons/Group_Management.png';
}

export default GroupList;

